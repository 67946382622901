import React from "react";

const InputTextBox = ({ prefixText, isValid, errorMsg, isHtml, value, ...rest }) => {
  const inputClassName =
    " form-control " +
    rest?.className +
    (isValid === true ? " is-valid" : isValid === false ? " is-invalid" : "");

  const feedbackClassName =
    //" form-control " +
    isValid === true
      ? "valid-feedback"
      : isValid === false
      ? "invalid-feedback"
      : "valid-tooltip";

  const prefixClassName = "input-group-text ";

  //
  return (
    <>
      {prefixText && <div class={prefixClassName}>{prefixText}</div>}
      {isHtml ? (
        <div
          className={inputClassName}
          dangerouslySetInnerHTML={value}
        />
      ) : (
        <input
          type={`${rest?.type ? rest.type : "text"}`}
          {...rest}
          value={value}
          className={inputClassName}
        />
      )}
      {isValid === false && <div class={feedbackClassName}>{errorMsg}</div>}

    </>
  );
};

export default InputTextBox;
