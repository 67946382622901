import { useFetchGetRequestWithToken, useFetchPostRequestWithToken } from "./api";

export const GetAllCount = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-counts`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetAllOrganizer = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-organizers`;
  return useFetchGetRequestWithToken(apiURL, token);
};

// export const GetAllEvent = async () => {
//   const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-events`;
//   return useFetchGetRequestWithToken(apiURL, user?.token);
// };

export const GetAllRegisteredParticipants = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-registered-participants`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetAllPastEvents = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-published-past-events`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetTotalRevenue = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-published-past-events`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetOrganizerDetail = async (userId, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/get-profile/${userId}`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetEventByEventId = async (eventId, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-by-eventId/${eventId}`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetEventByDate = async (
  token,
 startDate,
 endDate,
) => {  
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/all-events-date-range`;  
  const reqBody = JSON.stringify({
    startDate: startDate,
    endDate: endDate,   
  });

  const eventData = useFetchPostRequestWithToken(apiURL, reqBody, token);
  return eventData;
};
