import useFetch, {
  useFetchGetRequest,
  useFetchGetRequestWithToken,
  useFetchMultipart,
  useFetchMultipartWithToken,
} from "./api";

export const GetEventDetailsData = (eventId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-by-eventId/${eventId}`;

  const eventDetailsByEventId = useFetchGetRequest(apiURL);
  return eventDetailsByEventId;
};

//Start-Event-Sale-Table
const Mode = "admin";
/**
 * Get all events of the Organizer to showcase Sales & participant Data.
 */
export const GetAllEventByUserId = async (token, refUserId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-all-events`;
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("refUserId", refUserId);

  const eventData = await useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};

/**
 * Get all events of the Organizer which includes draft, active, past, cancel
 * events.
 */
export const GetAllTotelEventByUserId = async (token, refUserId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-all-total-events`;
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("refUserId", refUserId);

  const eventData = await useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};

export const GetEventSalesByUserId = async (token, eventId, refUserId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-sales`;
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  formData.append("refUserId", refUserId);
  const eventData = useFetchMultipartWithToken(
    apiURL,
    formData,
    token,
    refUserId
  );
  return eventData;
};
export const GetAllParticipantsByEventId = async (
  token,
  eventId,
  refUserId
) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  formData.append("refUserId", refUserId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-participants`;
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetAllParticipantsDetailsByEventId = async (
  token,
  eventId,
  refUserId
) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  formData.append("refUserId", refUserId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-participants-details`;
  const eventData = useFetchMultipartWithToken(
    apiURL,
    formData,
    token,
    refUserId
  );
  return eventData;
};
export const GetParticipantsDataByID = async (
  token,
  participantId,
  refUserId
) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("participantId", participantId);
  formData.append("refUserId", refUserId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-participant-data`;
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetParticipantsPaymentByID = async (
  token,
  eventId,
  participantId,
  txnId,
  ocId,
  refUserId
) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("participantId", participantId);
  formData.append("eventId", eventId);
  formData.append("txnId", txnId);
  formData.append("ocId", ocId);
  formData.append("refUserId", refUserId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-participant-payment`;
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};

export const EventActiveOrDeactiveByUserId = async (
  token,
  eventId,
  refUserId
) => {
  debugger;
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/cancel-published-event`;
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  formData.append("userId", refUserId);

  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};

export const GetOragnizerList = (token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-all-organizer`;
  const eventData = useFetchGetRequestWithToken(apiURL, token);
  return eventData;
};
/**
 * Get all events of the Organizer to showcase Sales & participant Data.
 */
export const GetAllEventByAdmin = async (token, refUserId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-all-events-admin`;
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("refUserId", refUserId);

  const eventData = await useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
//End-Event-Sale-Table

export default GetAllEventByUserId;
