import { decryptData, encryptData } from "./cryptoJs";

export const GetWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const saveToLocal = (key, value) => {
  let inputValue = value;
  if (value !== undefined && value !== null && value !== "")
    inputValue = encryptData(value);

  try {
    localStorage.setItem(key, inputValue);
  } catch (exception) {
    console.log(exception);
  }
};

export const getLocalData = (key) => {
  let value = localStorage.getItem(key);
  let inputValue = value;
  if (value !== undefined && value !== null && value !== "") {
    inputValue = decryptData(value);
  }

  return inputValue;
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

export const clearLocalData = () => {
  localStorage.clear();
};

export const GetFileExtension = (fileName) => {
  // Check if the file name contains a dot, and return the extension after the last dot
  return fileName
    .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
    .toLowerCase();
};

export default GetWindowDimensions;
