import React, { useState, useEffect } from "react";

//
export const AccordionCard = ({ config }) => {
  const [activeIndex, setActiveIndex] = useState(0); //0

  useEffect(() => {
    setActiveIndex(() => config?.activeTabNo);
  }, [config?.activeTabNo]);
  //
  const handleClick = (nextIndex) => {
    setActiveIndex((prevIndex) => {
      if (prevIndex === nextIndex) {
        return prevIndex + 1;
      } else {
        return nextIndex;
      }
    });
  };

  //
  return (
    <>
      {config?.data?.map((item, index) => {
        const isActive = index === activeIndex;
        return (
          <>
            <div className="card mb-2">
              <div className="card-body p-1">
                <div class="accordion accordion-flush ">
                  <div
                    className={` accordion-item collapsed ${
                      isActive ? "collapsed" : ""
                    }  `}
                  >
                    <div
                      className={`accordion-header`}
                      onClick={() => handleClick(index)}
                      key={index}
                    >
                      <button
                        className={` accordion-button  
                ${
                  isActive ? "collapsed show border-bottom " : ""
                }                 
                 ${item?.dataEntered === true ? " bg-success" : ""}`}
                        type="button"
                      >
                        <div class="d-flex justify-content-center align-items-center accordion-detail">
                          {item?.title}
                        </div>
                      </button>
                    </div>
                    <div
                      className={` accordion-collapse p-2 accordion-detail collapse  ${
                        isActive ? "show" : ""
                      }`}
                    >
                      {item?.content}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default AccordionCard;
