import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../controls/table/Table";
// import {
//   GetAllEventByUserId,
//   EventActiveOrDeactiveByUserId,
//   GetOragnizerList,
// } from "../../../service/eventAPI.jsx";
import { GetStoreLoginData } from "../../../service/commonAPI";
// import { saveToLocal } from "../../../util/commonFun";
// import {
//   NO_IMAGE_PATH,
//   FOLDER_EVENT_PHOTO,
//   KEY_EVENT_ID,
// } from "../../../util/Constants";
// import Model from "../../controls/popup/Modal";
import Spinner from "../../controls/spinner/Spinner.jsx";
import { GetEventByDate } from "../../../service/dashboardAPI.js";
import InputTextBox from "../../controls/input/InputTextBox.jsx";
import Button from "../../controls/buttons/Button.jsx";
import ExportToCSV from "../../../util/ExportToCSV.jsx";
//
const EventTable = () => {
  const [allEvents, setAllEvents] = useState([]);
  // const [oragnizerList, setOragnizerList] = useState([]);
  // const [refUserId, setRefUserId] = useState("0");
  const userData = GetStoreLoginData();
  // const navigate = useNavigate();
  // let ImagePath = process.env.REACT_APP_EVENTS;
  //const [showData, setShowData] = useState(1);
  // const [showActivePopup, setShowActivePopup] = useState(false);
  const [loading, setLoading] = useState(false); //spinner
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // error message

  // useEffect(() => {
  //   const oragList = async () => {
  //     try {
  //       const allEventsResponse = await GetOragnizerList(userData?.token);
  //       if (allEventsResponse?.success === true) {
  //         setLoading(false);
  //         setOragnizerList(allEventsResponse?.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   };

  //   oragList();
  // }, []);

  // useEffect(() => {
  //   const getAllEvents = async () => {
  //     try {
  //       if (refUserId !== "0") {
  //         const allEventsResponse = await GetAllEventByUserId(
  //           userData?.token,
  //           refUserId
  //         );
  //         if (allEventsResponse?.success === true) {
  //           setAllEvents(allEventsResponse?.data);
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getAllEvents();
  // }, [refUserId]);

  const handleShowResults = async () => {
    if (!startDate || !endDate) {
      setErrorMessage("Please select the dates.");
      removeErrorMsg();
      return;
    }

    setLoading(true);
    try {
      const allEventsResponse = await GetEventByDate(
        userData?.token,
        startDate,
        endDate
      );
      if (
        allEventsResponse?.success === true &&
        allEventsResponse?.data?.events?.length > 0
      ) {
        setAllEvents(allEventsResponse?.data?.events);
      } else {
        setAllEvents([]);
        setErrorMessage("Selected date has no events available.");
        removeErrorMsg();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const downloadCSV = async () => {
    if (!startDate || !endDate) {
      setErrorMessage("Please select the dates to download the report.");
      removeErrorMsg();
      return;
    }

    try {
      setLoading(true);
      const allEventsResponse = await GetEventByDate(
        userData?.token,
        startDate,
        endDate
      );

      if (
        allEventsResponse?.success === true &&
        allEventsResponse?.data?.events?.length > 0
      ) {
        setLoading(false);

        // Filter out the fields to exclude
        const filteredEvents = allEventsResponse?.data?.events.map((event) => {
          const { userId, txnCount, ...rest } = event;
          return rest;
        });

        // Create headers based on filtered data
        let headers = {};
        for (let key in filteredEvents[0]) {
          headers[key] = key;
        }

        var fileTitle = "Report_From_" + startDate + "_to_" + endDate;
        ExportToCSV(headers, filteredEvents, fileTitle); // Call the ExportToCSV function with filtered data
      } else {
        alert("No record");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //configration
  const config = {
    tableTitle: "Events",
    data: allEvents,
    displayRecord: 10,
    columns: [
      // {
      //   title: "Photo",
      //   titleClass: "text-left",
      //   dataClass: "py-1 images align-middle",
      //   isImage: true,
      //   render: (item) => handleImagepath(item),
      // },
      {
        title: "Event Name ",
        titleClass: " ",
        dataClass: "",
        dataField: "eventName",
        isSort: false,
        sortByValue: (data) => data.eventName,
      },
      {
        title: "Event Id",
        titleClass: "",
        dataClass: "",
        dataField: "eventId",
        // isSort: false,
        // sortByValue: (data) => data.eventId,
      },
      {
        title: "Organizer Name",
        titleClass: "",
        dataClass: "",
        dataField: "businessName",
      },
      {
        title: "Organizer Email",
        titleClass: "",
        dataClass: "",
        dataField: "businessEmail",
      },
      {
        title: "Event Start Date",
        titleClass: "",
        dataClass: "text-left",
        dataField: "eventStartDate",
        isSort: false,
        sortByValue: (data) => data.eventStartDate,
      },
      // {
      //   title: "Total Participants",
      //   titleClass: "",
      //   dataClass: "text-left ",
      //   dataField: "ticketCount",
      //   isSort: false,
      //   sortByValue: (data) => data.ticketCount,
      // },
      // {
      //   title: "Sales",
      //   titleClass: "",
      //   dataClass: "text-left",
      //   dataField: "totalSales",
      //   isSort: false,
      //   isLink: true,
      //   sortByValue: (data) => data.totalSales,
      //   // render: (data) => Number(data.totalSales).toFixed(2),
      //   render: (data) => Number(data.totalSales).toLocaleString('en-US', {maximumFractionDigits: 2 }),
      // },
      {
        title: "Status",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "eventStatus",
        isSort: false,
        sortByValue: (data) => data.eventStatus,
      },
      {
        title: "Transaction amount for PG",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "txnAmountForPg",
        isSort: false,
        sortByValue: (data) => data.txnAmountForPg,
      },
      {
        title: "PG Outgo",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "pgOutgo",
        isSort: false,
        sortByValue: (data) => data.pgOutgo,
      },
      {
        title: "Payment from PG to Host",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "paymentFromPgToHost",
        isSort: false,
        sortByValue: (data) => data.paymentFromPgToHost,
      },
      {
        title: "Host Earning",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "hostEarning",
        isSort: false,
        sortByValue: (data) => data.hostEarning,
      },
      {
        title: "Host Total Tax Liability",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "hostTotalTaxLiab",
        isSort: false,
        sortByValue: (data) => data.hostTotalTaxLiab,
      },
      {
        title: "Payment For Organizer",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "paymentForOrg",
        isSort: false,
        sortByValue: (data) => data.paymentForOrg,
      },
      {
        title: "Applicable TDS",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "applicableTds",
        isSort: false,
        sortByValue: (data) => data.applicableTds,
      },
      {
        title: "Payout to Organizer",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "payoutToOrg",
        isSort: false,
        sortByValue: (data) => data.payoutToOrg,
      },
      {
        title: "Discount",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "discount",
        isSort: false,
        sortByValue: (data) => data.discount,
      },
      // {
      //   title: "Stage",
      //   titleClass: "",
      //   dataClass: "text-left align-middle text-success-btn",
      //   dataField: "eventStage",
      //   isSort: false,
      //   sortByValue: (data) => data.eventStage,
      // },
      // {
      //   title: "View",
      //   isLink: true,
      //   render: (item) => (
      //     <div class="text-left align-middle">
      //       <Link
      //         className="btn-link btn align-middle p-0"
      //         to={`/event-details/${item.eventId}`}
      //       >
      //         View
      //       </Link>
      //     </div>
      //   ),
      //   titleClass: "",
      //   dataClass: "text-left",
      // },
      // {
      //   title: "Edit",
      //   isLink: true,
      //   render: (item) => {
      //     if (item?.eventStatus === "act" && item?.eventStage === "ongoing") {
      //       return (
      //         <>
      //           <div class="text-left align-middle">
      //             <a
      //               class="btn-outline-dark btn align-middle p-2"
      //               href="#"
      //               onClick={() => handleEdit(item)}
      //             >
      //               Edit
      //             </a>
      //           </div>
      //         </>
      //       );
      //     }
      //   },
      //   titleClass: "",
      //   dataClass: "text-left",
      // },
      {
        title: "Total Sales",
        isLink: true,
        render: (item) => {
          return (
            <div class="text-left align-middle">
              <Link
                to={`/event-sales-details/${item?.eventId}/${item?.eventName}/${item?.userId}`}
                class="btn-link btn align-middle p-0"
              >
                View
              </Link>
            </div>
          );
        },
        titleClass: "",
        dataClass: "text-left",
      },
      // {
      //   title: "Action",
      //   isLink: true,
      //   render: (item) => {
      //     if (item?.eventStatus === "act" && item?.eventStage === "ongoing") {
      //       return (
      //         <>
      //           <div class="text-left align-middle">
      //             <a
      //               class="btn-link btn align-middle p-0"
      //               href="#"
      //               onClick={() => handleAction(item)}
      //             >
      //               {item?.eventStatus === "act"
      //                 ? "Cancel"
      //                 : item?.eventStatus === "iact"
      //                 ? "" // "Publish"
      //                 : ""}
      //             </a>
      //           </div>
      //           {showActivePopup === true && (
      //             <Model
      //               title={`Event-${item?.eventId}`}
      //               onClose={() => handleActionClose()}
      //               onSuccess={() => handleActionSuccess(item)}
      //               successText={
      //                 item?.eventStatus === "act"
      //                   ? "Cancel"
      //                   : item?.eventStatus === "iact"
      //                   ? "" // "Publish"
      //                   : ""
      //               }
      //             >
      //               <div class="alert alert-warning" role="alert">
      //                 Do you want to <b>{item?.eventName}</b>{" "}
      //                 {item?.eventStatus === "act"
      //                   ? "Cancel"
      //                   : item?.eventStatus === "iact"
      //                   ? "" // "Publish"
      //                   : ""}{" "}
      //                 ?
      //               </div>
      //             </Model>
      //           )}
      //         </>
      //       );
      //     }
      //   },
      //   titleClass: "",
      //   dataClass: "text-left",
      // },
    ],
  };

  // const handleViewMore = (item) => {
  //   alert("handleViewMore");
  // };
  // const handleSortBy = (item) => {
  //   alert("handleSortBy");
  // };
  // const handleEdit = (item) => {
  //   //alert("handleEdit");

  //   saveToLocal(KEY_EVENT_ID, item.eventId);
  //   navigate("/create-event/" + item.eventId + "/" + item.eventName);
  // };
  // const handleAction = (item) => {
  //   setShowActivePopup(() => true);
  // };
  // const handleActionClose = (item) => {
  //   setShowActivePopup(() => false);
  // };
  // const handleActionSuccess = (item) => {
  //   //api call for deactivation/ activation
  //   const getAllEvents = async () => {
  //     try {
  //       const allEventsResponse = await EventActiveOrDeactiveByUserId(
  //         userData?.token,
  //         item?.eventId,
  //         refUserId
  //       );

  //       if (allEventsResponse?.success === true) {
  //         //
  //         handleActionClose();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       handleActionClose();
  //       alert(error);
  //     }
  //   };
  //   getAllEvents();
  // };
  // const handleImagepath = (item) => {
  //   let ImageFullPath =
  //     ImagePath + item.eventId + FOLDER_EVENT_PHOTO + item?.eventPhotos;

  //   return (
  //     <img
  //       src={ImageFullPath}
  //       class="img-fluid rounded"
  //       style={{ maxWidth: "55px", maxHeight: "45px" }}
  //     />
  //   );
  // };

  // const handleOragnizer = (event) => {
  //   const { value } = event.target;
  //   setRefUserId(() => value);
  // };
  //
  return (
    <>
      {/* <div className="row">
        <div className="col-4 m-2 mt-2">&nbsp;</div>
        <div className="col-4 m-2 mt-2">
          <select
            class="form-select form-select-lg mb-3 custom-select-font"
            onChange={(event) => handleOragnizer(event)}
            value={refUserId}
          >
            <option value="0" className="custom-placeholder">
              Please Select Organizer
            </option>
            {oragnizerList &&
              oragnizerList?.map((item, index) => {
                return (
                  <option value={item?.userId}>{item?.businessName}</option>
                );
              })}
          </select>
        </div>
      </div> */}
      <>
        <div className="d-flex align-items-center">
          <div className="mb-3 p-2 col-3">
            <label for="startDate" className="form-label">
              Start Date
            </label>
            <InputTextBox
              type="date"
              className="form-control"
              id="startDate"
              name="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="mb-3 p-2 col-3">
            <label for="endDate" className="form-label">
              End Date
            </label>
            <InputTextBox
              type="date"
              className="form-control"
              id="endDate"
              name="endDate"
              value={endDate}
              min={startDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="mb-2 p-2 mt-4">
            <label className="form-label">{""}</label>
            <Button type="button" onClick={handleShowResults}>
              Show Result
            </Button>
          </div>
          <div className="mb-2 p-2 mt-4">
            <label className="form-label">{""}</label>
            <Button
              type="button"
              onClick={() => {
                downloadCSV();
              }}
            >
              Download CSV
            </Button>
          </div>
        </div>

        {errorMessage && (
          <div className="alert alert-warning" role="alert">
            <h5>{errorMessage}</h5>
          </div>
        )}
      </>

      {loading ? <Spinner /> : <Table config={config} />}
      {/*  {showData == 1 && <Table config={config} />}
      {showData == 2 && <ViewSalesDetails config={config} />} */}
    </>
  );
};

export default EventTable;
