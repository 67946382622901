import React from "react";

const InputTextArea = ({ prefixText, isValid, errorMsg, ...rest }) => {
  const inputClassName =
    " form-control " +
    rest?.className +
    (isValid === true ? " is-valid" : isValid === false ? " is-invalid" : "");

  const feedbackClassName =
    //" form-control " +
    isValid === true
      ? "valid-feedback"
      : isValid === false
      ? "invalid-feedback"
      : "valid-tooltip";

  const prefixClassName = "input-group-text ";

  //
  return (
    <>
      {prefixText && <div class={prefixClassName}>{prefixText}</div>}
      <textarea
        type={`${rest?.type ? rest.type : "text"}`}
        {...rest}
        className={inputClassName}
      ></textarea>
      {isValid === false && <div class={feedbackClassName}>{errorMsg}</div>}
    </>
  );
};

export default InputTextArea;
