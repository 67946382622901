import React, { useState } from "react";
import { useSelector } from "react-redux";
import GetStateData from "../../../service/commonAPI";
import StateDropdown from "./StateDropdown";
import { CreateCity } from "../../../service/cityAPI";
import * as validator from "../../../util/validations";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import Modal from "../../controls/popup/Modal";

//main function
const AddNewCity = ({ stateId, onStateChange, onCityAdded, onCityUpdate }) => {
  //declare start
  const [showModal, setShowModal] = useState(false);
  const userData = useSelector((state) => state.login.login.login);

  //load StateData
  const loadStateData = GetStateData();

  //create New city
  const [imageUrl, setImageUrl] = useState("");
  const [imageFileObject, setimageFileObject] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  //validition and input value
  let initialData = { cityName: "", errors: {}, isValid: {} };
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid } = inputValue;
  //declare end

  //function/method/event
  const submitCityForm = async (event) => {
    event.preventDefault();

    if (inputValue?.isValid?.cityName && stateId > 0 && imageFileObject) {
      const createCityResponse = await CreateCity(
        inputValue,
        imageFileObject,
        stateId,
        userData?.token
      );
      if (createCityResponse?.success) {
        onCityAdded(event);
        setShowModal(() => false);
        alert(createCityResponse?.message);
        resetCityFields();
      }
    } else {
      setResponseMsg("Please Enter valid input");
      removeErrorMsg();
    }
  };

  const resetCityFields = () => {
    setInputValue({ ...inputValue, cityName: "", errors: {}, isValid: {} });
    setImageUrl("");
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setResponseMsg("");
    }, 3000);
  };

  //checking validation
  const handleChange = (event) => {
    const { name, value } = event.target;
    //validation
    let result = IsValid(name, value);
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };
  const IsValid = (name, value) => {
    let status = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "cityName":
          status = validator.IsValidCharWithSpace(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
      }
    }

    return { status: status, message: msg };
  };

  const handleSelectImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setimageFileObject(() => event.target.files[0]);
    setImageUrl(() => imageUrl);
  };
  if (onCityUpdate) {
    setShowModal(() => true);
  }
  const handleAddCityClick = () => {
    // event.preventDefault();
    setShowModal(() => true);
  };

  const handleCloseModal = () => {
    // event.preventDefault();
    setShowModal(() => false);
  };

  //main return
  return (
    <>
      {/* {Modal} */}
      <div className="d-flex justify-content-end modal-footer">
        <Button
          type="button"
          dark
          onClick={handleAddCityClick}
          style={{
            marginTop: "-45px",
          }}
        >
          Add New City
        </Button>
      </div>

      {/* <div className="manage-category-modal">
          <
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Add City
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCloseModal}
                  />
                </div> */}
      {showModal && (
        <>
          <Modal
            title="Add City"
            onClose={handleCloseModal}
            onSuccess={submitCityForm}
            cancleText="Cancel"
            successText="Submit"
          >
            {responseMsg && (
              <div className="alert alert-danger" role="alert">
                <h6>{responseMsg}</h6>
              </div>
            )}
            <div className="modal-body manage-category-modal">
              <form action="" method="post">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    State Name
                  </label>
                  <div class="form-check form-switch" href="#" style={{marginLeft:"-40px "}}>
                    <StateDropdown
                      stateId={stateId}
                      onStateChange={onStateChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    City Name
                  </label>
                  <div class="form-check form-switch" href="#" style={{marginLeft:"-31px", width:"105%"}}>
                    <InputTextBox
                      id="cityName"
                      name="cityName"
                      placeholder="Please Enter City Name"
                      isValid={inputValue?.isValid?.cityName}
                      errorMsg={inputValue?.errors?.cityName}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={inputValue.cityName}
                      name="cityName"
                      onChange={(event) => handleChange(event)}
                    /> */}
                  {/* <div
                      id="emailHelp"
                      className="form-text danger text-danger"
                    >
                      {inputValue.errors?.cityName}
                    </div> */}
                </div>
              </form>
            </div>
            <div className="form-group file-area">
              <div className="my-2 message">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Upload City Image (size: 60px X 60px)
                </label>
              </div>
              <input
                type="file"
                name="images"
                id="images"
                required="required"
                multiple="multiple"
                onChange={(event) => handleSelectImage(event)}
              />
              <div className="upload-event-photo">
                <div className="success">
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Uploaded"
                      style={{ maxWidth: "380px" }}
                    />
                  )}
                </div>
                {/* <div className="default">
                  <p>
                    <i className="bi bi-image" />
                  </p>
                  Upload Image
                </div> */}
              </div>
            </div>

           
          </Modal>
        </>
      )}

      {/* <div className="modal-footer">
                  <Button
                    type="button"
                    // newClassName="btn btn-cancel"
                    // secondary outline
                    cancel
                    // data-bs-dismiss="modal"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    // className="btn btn-upload"
                    // success outline
                    onClick={submitCityForm}
                    //  onCityAdded={handleCityAdded}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

export default AddNewCity;
