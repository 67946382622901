import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as validator from "../../../util/validations";
import InputTextBox from "../../controls/input/InputTextBox";
import Modal from "../../controls/popup/Modal";
import { UpdateUser } from "../../../service/userAPI";

const initialData = {
  email: "",
  isDisabled: false,
  adminUserId: "",
  errors: {},
  isValid: {},
};
//main function
const UpdateUserPopUp = ({ user, onUserUpdate, setPopUpVisibility }) => {
  //declare start
  const [showModal, setShowModal] = useState(true);
  const [responseMsg, setResponseMsg] = useState("");
  const [inputValue, setInputValue] = useState(initialData);
  let { errors, isValid } = inputValue;
  const userData = useSelector((state) => state.login.login.login);

  //validition and input value
  if (user) {
    initialData.email = user.email;
    initialData.isValid.email = true;
    initialData.isDisabled = user.isDisabled;
    initialData.adminUserId = user.adminUserId;
  }
  //declare end

  //functions/events/methods
  const handleUpdate = async (event) => {
    if (event) event.preventDefault();
    if (!inputValue.isValid.email) {
      setResponseMsg("Please enter a valid email address.");
      removeErrorMsg();
      return;
    }

    if (userData?.token) {
      const updateUserResponse = await UpdateUser(inputValue, userData.token);
      if (updateUserResponse?.success) {
        setShowModal(false);
        setPopUpVisibility(false);
        onUserUpdate();
        alert(updateUserResponse.message);
      } else {
        setResponseMsg(updateUserResponse.message);
        removeErrorMsg();
      }
    }
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setResponseMsg("");
    }, 5000);
  };

  //checking validation
  const handleChange = (event) => {
    let { name, value, type } = event.target;
    //validation
    let result = IsValid(name, value);
    if (type === "checkbox") {
      value = event.target.checked;
    }
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };

  const IsValid = (name, value) => {
    let status = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "email":
          status = validator.IsValidEmail(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
      }
    }
    return { status: status, message: msg };
  };

  // const handleDelete = async () => {
  //   if (window.confirm(`Do you want to delete "${user.adminUserId}"?`)) {
  //     let deleteUserResponse = await DeleteUser(user.adminUserId);
  //      setShowModal(false);
  //      alert("User deleted successfully!!");
  //   }
  // };

  const handleClose = () => {
    setShowModal(false);
    setPopUpVisibility(false);
  };

  //main return
  return (
    <>
      {showModal && (
        <>
          <Modal
            title="Update Admin"
            onClose={handleClose}
            onSuccess={handleUpdate}
            cancleText="Cancel"
            successText="Update Admin"
          >
            <div className="modal-body manage-category-modal">
              {responseMsg && (
                <p className="alert alert-danger">{responseMsg}</p>
              )}
              <form action="" method="post">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email
                </label>
                <InputTextBox
                  id="email"
                  name="email"
                  value={inputValue.email}
                  placeholder="Please Enter Email Id"
                  isValid={inputValue?.isValid?.email}
                  errorMsg={inputValue?.errors?.email}
                  onChange={handleChange}
                  disabled
                />

                <div className="mb-3">
                  {/* <label htmlFor="exampleInputEmail1" className="form-label">
                      Password
                    </label>
                    <InputTextBox
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Please Enter password"
                        isValid={inputValue?.isValid?.password}
                        errorMsg={inputValue?.errors?.password}
                        onChange={handleChange}                     
                      /> */}
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Disable or Enable User:
                    </label>
                    <div className="form-check form-switch" href="#">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        defaultChecked={!inputValue.isDisabled}
                        name="isDisabled"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default UpdateUserPopUp;
