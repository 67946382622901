import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteBanner,
  UpdateBanner,
} from "../../../service/promotionBannerAPI";
import Modal from "../../controls/popup/Modal";
import InputTextBox from "../../controls/input/InputTextBox";
import * as validator from "../../../util/validations";

//main function
const UpdateBannerPopup = ({ banner, onBannerUpdate, setPopUpVisibility }) => {
  // let ImagePath = process.env.REACT_APP_PROMOTION_BANNERS;
  //declare start
  const [showModal, setShowModal] = useState(true);
  const [updateImageFileObject, setUpdateImageFileObject] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const userData = useSelector((state) => state.login.login.login);
  const [loading, setLoading] = useState(false);

  //validition and input value
  let ImagePullPath = "";
  let initialData = {
    isActive: true,
    bannerImage: ImagePullPath,
    errors: {},
    isValid: {},
  };

  if (banner) {
    initialData.isActive = banner.isActive;
    let ImagePath = process.env.REACT_APP_BANNERS;
    let ImagePullPath = ImagePath + banner.bannerUrl;
    // let ImagePullPath = banner.bannerUrl;
    initialData.bannerImage = ImagePullPath;
    initialData.eventReference = banner.eventReference;
  }

  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid, bannerImage, isActive, uploadBannerImage } =
    inputValue;
  //declare end

  //function/event/methods
  const handleUpdate = async (event) => {
    if (event) event.preventDefault();

    if (userData?.token) {
      setLoading(true);
      let updateBannerResponse = await UpdateBanner(
        banner,
        inputValue,
        updateImageFileObject,        
        userData?.token
      );
      if (updateBannerResponse?.success) {
        setLoading(false); 
        onBannerUpdate();
        setShowModal(false);
        setPopUpVisibility(false);
        alert(updateBannerResponse.message);
      }    
    else
    alert(updateBannerResponse?.errors);
    }
  };

  //checking validation
  const handleChange = (event) => {
    let { name, value, type } = event.target;
    //validation
    let result = IsValid(name, value);
    if (type === "checkbox") {
      value = event.target.checked;
    }
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };

  const IsValid = (name, value) => {
    let status = true;
    let msg = '';

    // No validation needed if the input is empty
    if (!validator.IsEmpty(value)) {        
        switch (name) {
            case "eventReference":
                // No additional validation required since all characters, numbers, and symbols are allowed
                break;            
        }
    }
    return { status: status, message: msg };
};

  const handleUpdatedImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const validImageTypes = ["image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif"];
    
    if (!validImageTypes.includes(file.type)) {
      setResponseMsg("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      removeErrorMsg();
      return;
    }

    if (file?.size > 7340032) {      // 1 MB = 1,024 × 1,024 B = 1,048,576 B , for 7mb{7340032) 7 MB = 7 * 1,048,576 B = 7,340,032 B
      setResponseMsg("File size exceeds 7 MB. Please choose a smaller file.");
      removeErrorMsg();
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setUpdateImageFileObject(event.target.files[0]);

    //setValue
    setInputValue((prev) => ({
      ...prev,
      bannerImage: imageUrl,
      errors: { ...errors, [bannerImage]: "" },
      isValid: { ...isValid, [bannerImage]: true },
    }));
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setResponseMsg("");
    }, 3000);
  };

  const handleDelete = async () => {
    if (window.confirm("Do you want to delete banner ?")) {
      if (userData?.token) {
        setLoading(true);
        let deleteBannerResponse = await DeleteBanner(
          banner.bannerId,
          userData?.token
        );
        if(deleteBannerResponse?.success){
        setLoading(false);
        setShowModal(false);
        setPopUpVisibility(false);
        onBannerUpdate();
        alert(deleteBannerResponse?.message);
      }
      else
      alert(deleteBannerResponse?.errors);
    }
    }
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setShowModal(() => false);
    setPopUpVisibility(false);
  }; 

  //main return
  return (
    <>
     
      {showModal && (
        <Modal
          title="Update Promotion Banner"
          onClose={handleCloseModal}
          onDelete={handleDelete}
          onSuccess={handleUpdate}
          cancleText="Cancel"
          // successText="Update"
        >
           {responseMsg && (
            <div className="alert alert-danger" role="alert">
              <h6>{responseMsg}</h6>
            </div>
          )}
          <div className="modal-body manage-category-modal">
            <form action method="post">
            <div className="">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                  Event Reference
                  </label>
                  <InputTextBox
                    id="eventReference"
                    name="eventReference"
                    placeholder="Please Enter Event Reference"
                    value={inputValue.eventReference}
                    isValid={inputValue?.isValid?.eventReference}
                    errorMsg={inputValue?.errors?.eventReference}
                    onChange={handleChange}                
                  />
                </div>
                <div className="mb-3" style={{fontSize:"12px", fontStyle:"italic"}}>(Hint: E20240000001/Example Event name)</div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label d-relative ">
                  Disable or Enable Promotion Banner:
                </label>
                <div className="form-check form-switch float-end" href="#" style={{marginTop:"2px",marginRight:"130px"}}>
                  <input
                    className="form-check-input d-flex"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    // value={inputValue.isActive}
                    defaultChecked={inputValue.isActive}
                    name="isActive"
                    onChange={(event) => handleChange(event)}
                  />
                </div>
              </div>
            </form>
            <div className="form-group file-area">
              <div className="my-2 message">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Upload Promotion Banner Image (size: 207px X 73px)
                </label>
              </div>
              <input
                type="file"
                name="bannerImage"
                id="images"
                required="required"
                multiple="multiple"
                onChange={(event) => handleUpdatedImage(event)}
              />
              <div
                className="upload-event-photo"
                // style={{ marginTop: "10px" }}
              >
                <div className="">
                  {inputValue.bannerImage && (
                    <img
                      src={inputValue.bannerImage}
                      alt="Uploaded"
                      style={{ maxWidth: "100%", maxHeight: "250px" }}
                    />
                  )}
                </div>
                <div className="default">
                  <p>
                    <i className="bi bi-image"></i>
                  </p>
                  {/* Upload Image */}
                </div>
              </div>
            </div>
            {loading && 
            <span
              className="loaderBreakup mt-5"
              style={{marginLeft:"210px"}}
            ></span>
          }
          </div>
        </Modal>
      )}      
    </>
  );
};

export default UpdateBannerPopup;
