import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserCard from "../../components/setting/user/UserCard";
import AddNewUser from "../../components/setting/user/AddNewUser";
import { GetAllUsers } from "../../service/userAPI";
import Spinner from "../../components/controls/spinner/Spinner";

//main function
const UserPage = () => {
  //declare start
  const navigate = useNavigate(); // add useNavigate
  const [loadUser, setLoadUser] = useState([]);
  const [loading, setLoading] = useState(true); // loading state
  const userData = useSelector((state) => state.login.login.login);
  //declare end

  //function/events/methods
  useEffect(() => {
    const GetUserList = async () => {
      try {
        if (userData?.token) {
          const response = await GetAllUsers(userData.token);
          if (response?.success) {
            const userResponse = response?.data;
            setLoadUser(userResponse);
            setLoading(false);
            //  setLoadUser((prev)=>({...prev, userResponse}));
          } else {
            setLoadUser(false);
            alert(response?.message);
          }
        }
      } catch (error) {
        console.error("Error fetching user list:", error);
      }
    };
    GetUserList();
  }, [userData]);

  const handleUserAdded = async (event) => {
    if (event) event.preventDefault();
    // Refresh the list of users after a new User added
    const response = await GetAllUsers(userData?.token);
    setLoadUser(response?.data);
  };

  const handleUserUpdate = async (event) => {
    if (event) event.preventDefault();
    // Refresh the list of users after a new User added
    const response = await GetAllUsers(userData?.token);
    setLoadUser(response?.data);
  };

  //main return
  return (
    <>
      <Link to="#"
       onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
       style={{ textDecoration: "none", color: "black" }}>
        <h3 className=" font-weight-semibold mx-2 my-3">
          <i className="bi bi-chevron-left me-2 fw-bold" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 -960 960 960"
            width="20"
          >
            <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
          </svg>
          Manage Admin
        </h3>
      </Link>

      <AddNewUser onUserAdded={handleUserAdded} setLoadUser={setLoadUser} />

      <div className="card py-2 mt-4">
        {loading ? (
          <Spinner />
        ) : (
          loadUser &&
          loadUser.map &&
          loadUser.map((item, i) => (
            <UserCard key={i} user={item} onUserUpdate={handleUserUpdate} />
          ))
        )}
      </div>
    </>
  );
};

export default UserPage;
