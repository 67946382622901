import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as validator from "../../../util/validations";
import { UpdateCategory, DeleteCategory } from "../../../service/categoryAPI";
import InputTextBox from "../../controls/input/InputTextBox";
import Modal from "../../controls/popup/Modal";

//main function
const UpdateCategoryPopup = ({
  category,
  onCategoryUpdate,
  setPopUpVisibility,
}) => {
  //declare start
  const [showModal, setShowModal] = useState(true);
  const [updatedCategoryName, setUpdatedCategoryName] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [updateImageFileObject, setUpdateImageFileObject] = useState("");
  const userData = useSelector((state) => state.login.login.login);

  let ImagePath = process.env.REACT_APP_CATEGORYS;
  let ImagePullPath = "";
  let initialData = {
    categoryName: "",
    isActive: true,
    categoryPic: ImagePullPath,
    uploadCategoryPic: "",
    errors: {},
    isValid: {},
  };

  //validition and input value
  if (category) {
    initialData.categoryName = category.categoryName;
    initialData.isValid.categoryName = true;
    initialData.isActive = category.isActive;
    ImagePullPath =
      ImagePath + category.categoryId + "/" + category.categoryPic;
    initialData.categoryPic = ImagePullPath;
  }

  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid, categoryPic, isActive, uploadCategoryPic } =
    inputValue;
  //declare end

  //function/events/methods
  const handleUpdate = async (event) => {
    if (event) event.preventDefault();
    if (inputValue.isValid?.categoryName) {
      let updateCategoryResponse = await UpdateCategory(
        category,
        inputValue,
        updateImageFileObject,
        userData?.token
      );
      if (updateCategoryResponse?.success) {
        onCategoryUpdate({
          ...category,
          categoryName: inputValue.categoryName,
          isActive: inputValue.isActive,
        });
        onCategoryUpdate();
        setShowModal(false);
        setPopUpVisibility(false);
        alert(updateCategoryResponse.message);
      }    
    else
    alert(updateCategoryResponse?.errors);
    }     
     else {
      alert("Please Enter valid input");
    }
  };

  //checking validation
  const handleChange = (event) => {
    let { name, value, type } = event.target;
    //validation
    let result = IsValid(name, value);
    if (type === "checkbox") {
      value = event.target.checked;
    }
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };

  const IsValid = (name, value) => {
    let status = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "categoryName":
          status = validator.IsValidCharWithSpace(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
      }
    }

    return { status: status, message: msg };
  };

  const handleUpdatedImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const validImageTypes = ["image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif"];
    
    if (!validImageTypes.includes(file.type)) {
      setResponseMsg("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      removeErrorMsg();
      return;
    }

    if (file.size > 1048576) {   // 1 MB = 1,024 × 1,024 B = 1,048,576 B
      setResponseMsg("File size exceeds 1 MB. Please choose a smaller file.");
      removeErrorMsg();
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setUpdateImageFileObject(event.target.files[0]);

    //setValue
    setInputValue((prev) => ({
      ...prev,
      categoryPic: imageUrl,
      errors: { ...errors, [categoryPic]: "" },
      isValid: { ...isValid, [categoryPic]: true },
    }));
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setResponseMsg("");
    }, 3000);
  };

  const handleDelete = async () => {
    if (window.confirm(`Do you want to delete "${category.categoryName}"?`)) {
      if (userData.token) {
        let deleteCategoryResponse = await DeleteCategory(
          category.categoryId,
          userData.token
        );
        if (deleteCategoryResponse?.success) {
          onCategoryUpdate();
          setShowModal(false);
          setPopUpVisibility(false);
          alert(deleteCategoryResponse.message);
        }
      }
    }
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setShowModal(() => false);
    setPopUpVisibility(false);
  };

  //main return
  return (
    <>
      {showModal && (
        <>
          <Modal
            title="Update Category"
            onClose={handleCloseModal}
            onDelete={handleDelete}
            onSuccess={handleUpdate}
            cancleText="Cancel"
            // successText="Update"
          >
            {responseMsg && (
              <div className="alert alert-danger" role="alert">
                <h6>{responseMsg}</h6>
              </div>
            )}
            <div className="modal-body manage-category-modal">
              <form action method="post">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Category Name
                  </label>
                  <InputTextBox
                    id="categoryName"
                    name="categoryName"
                    placeholder="Please Enter Category Name"
                    value={inputValue.categoryName}
                    isValid={inputValue?.isValid?.categoryName}
                    errorMsg={inputValue?.errors?.categoryName}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Disable or Enable Category:
                  </label>
                  <div className="form-check form-switch" href="#">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={inputValue.isActive}
                      name="isActive"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
              <div className="form-group file-area">
                <div className="my-2 message">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Upload Category Image (size: 207px X 73px)
                  </label>
                </div>
                <input
                  type="file"
                  name="images"
                  id="images"
                  required="required"
                  multiple="multiple"
                  onChange={(event) => handleUpdatedImage(event)}
                />
                <div
                  className="upload-event-photo"
                  style={{ marginTop: "10px" }}
                >
                  <div className="">
                    {inputValue.categoryPic && (
                      <img
                        src={inputValue.categoryPic}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "150px" }}
                      />
                    )}
                  </div>
                  <div className="default">
                    <p>
                      <i className="bi bi-image"></i>
                    </p>
                    {/* Upload Image */}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default UpdateCategoryPopup;
