import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetAllEventByAdmin } from "../../service/eventAPI";
import Table from "../../components/controls/table/Table";
import { FOLDER_EVENT_PHOTO } from "../../util/Constants";
import Spinner from "../controls/spinner/Spinner";
import { encryptData } from "../../util/cryptoJs.jsx";

//main function
const EventByAdmin = () => {
  //declare start
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true); // loading state
  const userData = useSelector((state) => state.login.login.login);
  //declare end

  //function/event/methods
  // Fetch event by admin from API
  useEffect(() => {
    let cleanUp = true;
    const fetchEvents = async () => {
      try {
        if (cleanUp && userData.token) {
          const response = await GetAllEventByAdmin(
            userData.token,
            userData?.userId
          );
          if (response && response.success) {
            setEventsData(response.data);
            setLoading(false);
          }
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching eventsData:", error);
      }
    };

    fetchEvents();
    // cleanup function
    return () => {
      cleanUp = false;
    };
  }, []);

  //table
  const config = {
    tableTitle: "Total Event By Admin",
    data: eventsData,
    displayRecord: 10,
    defaultSortBy: (data) => data?.businessName,
    columns: [
      {
        title: "Photo",
        titleClass: "",
        dataClass: "py-1 images ",
        isImage: true,
        render: (item) => (
          <img
            src={`${
              process.env.REACT_APP_EVENTS +
              item.eventId +
              FOLDER_EVENT_PHOTO +
              item.eventPhotos
            }`}
            className="img-fluid rounded"
            style={{ maxWidth: "70px", maxHeight: "100px" }}
            alt="Logo"
          />
        ),
      },
      {
        title: "Organizer Name ",
        titleClass: " ",
        dataClass: "",
        dataField: "organizerName",
        isSort: false,
        sortByValue: (data) => data.organizerName,
      },
      {
        title: "Event Name",
        titleClass: "",
        dataClass: "text-start",
        dataField: "eventName",
        isSort: false,
        sortByValue: (data) => data.eventName,
      },
      {
        title: "Event Id",
        titleClass: "",
        dataClass: "text-start",
        dataField: "eventId",
        isSort: false,
        sortByValue: (data) => data.eventId,
      },
      {
        title: "Start Date",
        titleClass: "",
        dataClass: "",
        dataField: "eventStartDate2",
        isSort: false,
        sortByValue: (data) => data.eventStartDate2,
      },
      {
        title: "Status",
        titleClass: "",
        dataClass: "text-start",
        dataField: "eventStatus2",
        isSort: false,
        sortByValue: (data) => data.eventStatus2,
      },
      {
        title: "Created By ",
        titleClass: " ",
        dataClass: "",
        dataField: "referenceName",
        isSort: false,
        sortByValue: (data) => data.referenceName,
      },
      {
        title: "View",
        isLink: true,
        render: (item) => (
          <div className="">
            <Link
              className="btn-link btn align-middle p-0"
              to={`/event-details/${item.eventId}`}
            >
              View
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "",
      },
      {
        title: "Action",
        isLink: true,
        render: (item) => (
          <div className="">
            <Link
              className="btn-link btn align-middle p-0"
              onClick={() =>
                editCreateEvent(item.eventId, item.userId, item.referenceId)
              }
            >
              Edit
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "",
      },
    ],
  };

  const editCreateEvent = (eventId, refUserId, referenceId) => {
    let redirectURL =
      process.env.REACT_APP_FRONT_END_USER_URL + "create-event-ad/";
    
    if (refUserId !== "0" && userData?.token !== "") {
      const date = new Date().toLocaleDateString("en-IN");
      let loggedInUserId = userData?.user.userId;
      let param =
        date +
        "~" +
        loggedInUserId +
        "~" +
        refUserId +
        "~" +
        userData?.token +
        "~" +
        eventId;
      param = encryptData(param);
      redirectURL = redirectURL + "?q=" + param;

      window.open(redirectURL, "_blank");
    }
  };
  //

  return (
    <>
      <div className="d-flex flex-column mb-3">
        {loading ? <Spinner /> : <Table config={config} />}
      </div>
    </>
  );
};

export default EventByAdmin;
