import { React, useState } from "react";
import Spinner from "../../components/controls/spinner/Spinner";
import EventByAdmin from "../../components/createEvent/EventByAdmin";
import CreateEventPopup from "../../components/createEvent/CreateEventPopup";

//
const CreateEvent = () => {
  const [loading, setLoading] = useState(false); // loading spinner

  return (
    <>
      <div className="col-12 main-contain">
        <div className="row mb-3 my-3 p-2">
          <div className="col-8 ml-3">
            <h3 className="font-weight-semibold">Create Event</h3>
          </div>
          <div className="col">
            <CreateEventPopup />
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <EventByAdmin />
          </>
        )}
      </div>
    </>
  );
};

export default CreateEvent;
