import {
  useFetchGetRequest,
  useFetchGetRequestWithToken,
  useFetchMultipartWithToken,
} from "./api";

export const GetBannerData = () => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/get-all-banner`;
  return useFetchGetRequest(apiURL);
};

export const CreateBanner = (eventRef, uploadImageUrl, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/create-banner`;

  const formData = new FormData();
  formData.append("eventReference",eventRef)
  formData.append("bannerImage", uploadImageUrl);
  formData.append("isActive", true);

  return useFetchMultipartWithToken(apiURL, formData, token);
};

export const UpdateBanner = async (
  banner,
  inputValue,
  updateImageFileObject, 
  token
) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/update-banner`;

  const formData = new FormData();
  formData.append("bannerId", banner.bannerId);
  formData.append("isActive", inputValue.isActive);
  formData.append("bannerImage", updateImageFileObject);
  formData.append("eventReference",inputValue.eventReference)

  return useFetchMultipartWithToken(apiURL, formData, token);
};

export const DeleteBanner = (bannerId, token) => {
  const apiURL =
    `${process.env.REACT_APP_USER_MS}config/delete-banner/` +
    parseInt(bannerId);
  return useFetchGetRequestWithToken(apiURL, token);
};

export default GetBannerData;
