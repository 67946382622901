//EVENT_DETAILS-start
export const FETCH_EVENT_DETAILS_REQUEST = "FETCH_EVENT_DETAILS_REQUEST";
export const FETCH_EVENT_DETAILS_SUCCESS = "FETCH_EVENT_DETAILS_SUCCESS";
export const ADD_EVENT_DETAILS_REQUEST = "ADD_EVENT_DETAILS_REQUEST";
export const ADD_EVENT_DETAILS_SUCCESS = "ADD_EVENT_DETAILS_SUCCESS";
export const DELETE_EVENT_DETAILS_REQUEST = "DELETE_EVENT_DETAILS_REQUEST";
export const DELETE_EVENT_DETAILS_SUCCESS = "DELETE_EVENT_DETAILS_SUCCESS";

export const fetchEventDetailsRequest = (eventId) => ({
  type: FETCH_EVENT_DETAILS_REQUEST,
  payload: eventId,
});
export const fetchEventDetailsSuccess = (eventDetails) => ({
  type: FETCH_EVENT_DETAILS_SUCCESS,
  payload: eventDetails,
});
export const saveEventDetailsRequest = (eventDetails) => ({
  type: ADD_EVENT_DETAILS_REQUEST,
  payload: eventDetails,
});
export const saveEventDetailsSuccess = (eventDetails) => ({
  type: ADD_EVENT_DETAILS_SUCCESS,
  payload: eventDetails,
});
export const deleteEventDetailsRequest = (eventId) => ({
  type: DELETE_EVENT_DETAILS_REQUEST,
  payload: eventId,
});
export const deleteEventDetailsSuccess = (eventId) => ({
  type: DELETE_EVENT_DETAILS_SUCCESS,
  payload: eventId,
});
//EVENT_DETAILS-end
/* 
//EVENT_LOCATION-start
export const FETCH_EVENT_LOCATION_REQUEST = "FETCH_EVENT_LOCATION_REQUEST";
export const FETCH_EVENT_LOCATION_SUCCESS = "FETCH_EVENT_LOCATION_SUCCESS";
export const ADD_EVENT_LOCATION_REQUEST = "ADD_EVENT_LOCATION_REQUEST";
export const ADD_EVENT_LOCATION_SUCCESS = "ADD_EVENT_LOCATION_SUCCESS";
export const DELETE_EVENT_LOCATION_REQUEST = "DELETE_EVENT_LOCATION_REQUEST";
export const DELETE_EVENT_LOCATION_SUCCESS = "DELETE_EVENT_LOCATION_SUCCESS";

export const fetchEventLocationRequest = (eventId) => ({
  type: FETCH_EVENT_LOCATION_REQUEST,
  payload: eventId,
});
export const fetchEventLocationSuccess = (eventLocation) => ({
  type: FETCH_EVENT_LOCATION_SUCCESS,
  payload: eventLocation,
});
export const saveEventLocationRequest = (eventLocation) => ({
  type: ADD_EVENT_LOCATION_REQUEST,
  payload: eventLocation,
});
export const saveEventLocationSuccess = (eventLocation) => ({
  type: ADD_EVENT_LOCATION_SUCCESS,
  payload: eventLocation,
});
export const deleteEventLocationRequest = (eventId) => ({
  type: DELETE_EVENT_LOCATION_REQUEST,
  payload: eventId,
});
export const deleteEventLocationSuccess = (eventId) => ({
  type: DELETE_EVENT_LOCATION_SUCCESS,
  payload: eventId,
});
//EVENT_LOCATION-end

//EVENT_PHOTO-start
export const FETCH_EVENT_PHOTOS_REQUEST = "FETCH_EVENT_PHOTO_REQUEST";
export const FETCH_EVENT_PHOTOS_SUCCESS = "FETCH_EVENT_PHOTO_SUCCESS";
export const ADD_EVENT_PHOTOS_REQUEST = "ADD_EVENT_PHOTO_REQUEST";
export const ADD_EVENT_PHOTOS_SUCCESS = "ADD_EVENT_PHOTO_SUCCESS";
export const DELETE_EVENT_PHOTOS_REQUEST = "DELETE_EVENT_PHOTO_REQUEST";
export const DELETE_EVENT_PHOTOS_SUCCESS = "DELETE_EVENT_PHOTO_SUCCESS";

export const fetchEventPhotosRequest = (eventId) => ({
  type: FETCH_EVENT_PHOTOS_REQUEST,
  payload: eventId,
});
export const fetchEventPhotosSuccess = (eventPhoto) => ({
  type: FETCH_EVENT_PHOTOS_SUCCESS,
  payload: eventPhoto,
});
export const saveEventPhotosRequest = (eventPhoto) => ({
  type: ADD_EVENT_PHOTOS_REQUEST,
  payload: eventPhoto,
});
export const saveEventPhotosSuccess = (eventPhoto) => ({
  type: ADD_EVENT_PHOTOS_SUCCESS,
  payload: eventPhoto,
});
export const deleteEventPhotosRequest = (eventId) => ({
  type: DELETE_EVENT_PHOTOS_REQUEST,
  payload: eventId,
});
export const deleteEventPhotosSuccess = (eventId) => ({
  type: DELETE_EVENT_PHOTOS_SUCCESS,
  payload: eventId,
});
//EVENT_PHOTO-end

//TICKET_DETAILS-start
export const FETCH_TICKET_DETAILS_REQUEST = "FETCH_TICKET_DETAILS_REQUEST";
export const FETCH_TICKET_DETAILS_SUCCESS = "FETCH_TICKET_DETAILS_SUCCESS";
export const ADD_TICKET_DETAILS_REQUEST = "ADD_TICKET_DETAILS_REQUEST";
export const ADD_TICKET_DETAILS_SUCCESS = "ADD_TICKET_DETAILS_SUCCESS";
export const DELETE_TICKET_DETAILS_REQUEST = "DELETE_TICKET_DETAILS_REQUEST";
export const DELETE_TICKET_DETAILS_SUCCESS = "DELETE_TICKET_DETAILS_SUCCESS";

export const fetchTicketDetailsRequest = (eventId) => ({
  type: FETCH_TICKET_DETAILS_REQUEST,
  payload: eventId,
});
export const fetchTicketDetailsSuccess = (ticketDetails) => ({
  type: FETCH_TICKET_DETAILS_SUCCESS,
  payload: ticketDetails,
});
export const saveTicketDetailsRequest = (ticketDetails) => ({
  type: ADD_TICKET_DETAILS_REQUEST,
  payload: ticketDetails,
});
export const saveTicketDetailsSuccess = (ticketDetails) => ({
  type: ADD_TICKET_DETAILS_SUCCESS,
  payload: ticketDetails,
});
export const deleteTicketDetailsRequest = (eventId) => ({
  type: DELETE_TICKET_DETAILS_REQUEST,
  payload: eventId,
});
export const deleteTicketDetailsSuccess = (eventId) => ({
  type: DELETE_TICKET_DETAILS_SUCCESS,
  payload: eventId,
});
//TICKET_DETAILS-end */
