import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../controls/icons/Icon";

//main function
const Sidebar = ({ items }) => {
  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array

  //function/event/methods

  let currentActiveInde = items?.findIndex((item, index) => {
    if (item.url === pathname) return index;
  });

  const [activeIndex, setActiveIndex] = useState(currentActiveInde || 0);

  const handleClick = (index) => {
    // alert(nextIndex);
    setActiveIndex(index);
  };

  const renderItems = items.map((item, index) => {
    // const isActive = index === activeIndex;
    // alert(isActive);
    // console.log(isActive);
    return (
      <>
        <Link
          to={item.url}
          className={`m-1 ${index === activeIndex ? "active" : ""}`}
          // className={` m-1 ${isActive ? "active" : ""}`}
          onClick={() => handleClick(index)}
          key={index}
        >
          <div className="d-flex flex-row p-0 m-0">
            <div className="sidemenuicon p-0 m-0 mr-2">
              <Icon iconName={item.icon} />
            </div>
            <div className="text-start p-0 m-0">{item.title}</div>
          </div>
        </Link>
      </>
    );
  });

  //main retrun
  return (
    <>
      <div className="sidebar">{renderItems}</div>

      {/* {activeIndex == item.length ? createPortal(<Logout />, document.body) : } */}
    </>
  );
};
export default Sidebar;
