import { initializeApp } from "firebase/app";
import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";
import { SUCCESS } from "../util/Constants";
// import firebase from "firebase/compat/app";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
};

// firebase.initializeApp(firebaseConfig);
// const auth = getAuth();

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const signInUsingIDP = async (email, password) => {
  try {
    const signInResponse = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    console.log("Email password token", signInResponse);
    return signInResponse;
  } catch (error) {
    // console.log(error);
    return error;
  }
};

// export const handleLogout = () => {
//   firebase
//     .auth()
//     .signOut()
//     .then(() => {
//       // Sign-out successful.
//       return true;
//     })
//     .catch((error) => {
//       // An error happened.
//       return false;
//     });
// };

export const SendPasswordResetLink = async (resetEmail) => {
  try {
    await sendPasswordResetEmail(auth, resetEmail);
    return SUCCESS;
  } catch (error) {
    return error.message;
  }
};

// export const checkTokenExpiration = async () => {
//   const user = auth.currentUser;
//   if (user) {
//     try {
//       const idTokenResult = await user.getIdTokenResult();
//       const expirationTime = idTokenResult.expirationTime;
//       const currentTime = Date.now();
//       const expirationThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds
//       const timeUntilExpiration = expirationTime - currentTime;

//       if (timeUntilExpiration < expirationThreshold) {
//         // Token is close to expiring or already expired, refresh it
//         const refreshedToken = await user.getIdToken(/* forceRefresh */ true);
//         // Send the refreshed token to your server
//         // sendTokenToServer(refreshedToken);
//       } else {
//         // Schedule the next token check before 5 minutes of expiration
//         const nextCheckTime = timeUntilExpiration - expirationThreshold;
//         // setTokenCheckTimer(setTimeout(checkTokenExpiration, nextCheckTime));
//       }
//     } catch (error) {
//       console.error("Error refreshing token:", error);
//     }
//   }
// };

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, check if token needs refreshing
    user.getIdTokenResult().then((idTokenResult) => {
      const tokenExpirationTime = idTokenResult.expirationTime;
      const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
      const timeUntilExpiry =
        Math.floor(new Date(tokenExpirationTime).getTime() / 1000) -
        currentTime;

      // If token will expire in less than 5 minutes, refresh it
      if (timeUntilExpiry < 300) {
        user
          .getIdToken(/* forceRefresh= */ true)
          .then((refreshedToken) => {
            alert("Refresh token", refreshedToken);
            // Token refreshed successfully, you can use the new token for requests
            console.log("Token refreshed:", refreshedToken);
            alert("Token Refresh", refreshedToken);
          })
          .catch((error) => {
            // Handle token refresh error
            console.error("Error refreshing token:", error);
          });
      } else {
        // alert("");
      }
    });
  } else {
    // User is signed out
    // ...
    alert("User logged out.");
  }
});

export const testToken = async () => {
  auth.currentUser
    .getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      // Send token to your backend via HTTPS
      // ...
      console.log("ID Token :-", idToken);
      return idToken;
    })
    .catch(function (error) {
      // Handle error
      console.log("ID Token Error:-", error);
      return error;
    });
};
