import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PromotionBannerCard from "../../components/setting/promotionBanner/PromotionBannerCard";
import AddNewBanner from "../../components/setting/promotionBanner/AddNewBanner";
import { GetBannerData } from "../../service/promotionBannerAPI";
import Spinner from "../../components/controls/spinner/Spinner";

//main function
const PromotionBannerPage = () => {
  // declare start
  const navigate = useNavigate(); // add useNavigate
  const [loadBanner, setLoadBanner] = useState([]);
  const [loading, setLoading] = useState(true); // loading state

  // declare end

  // function/event/method
  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await GetBannerData();
        setLoadBanner(response?.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    fetchBanner();
  }, []);

  const handleBannerAdded = async () => {   
    // Refresh the list of banners after a new banner added
    const response = await GetBannerData();
    setLoadBanner(response?.data || []);
    window.location.reload();
  };

  const handleBannerUpdate = async () => {
    const response = await GetBannerData();
    setLoadBanner(response?.data || []);
    window.location.reload();
  };

  //main return
  return (
    <>
    <div className="col-4">
      <Link to="#"
       onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
      style={{ textDecoration: "none", color: "black" }}>
        <h3 className=" font-weight-semibold mx-2 my-3">
          <i className="bi bi-chevron-left fw-bold" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 -960 960 960"
            width="20"
          >
            <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
          </svg>
          Manage Promotion Banner
        </h3>
      </Link>
      </div>
      <AddNewBanner
        onBannerAdded={handleBannerAdded}
        setLoadBanner={setLoadBanner}
      />

      {/* <PromotionBannerCard /> */}
      <div className="card py-2 mt-4">
        {loading ? (
          <Spinner />
        ) : (
          loadBanner &&
          loadBanner.map((item, i) => (
            <PromotionBannerCard
              key={i}
              banner={item}
              onBannerUpdate={handleBannerUpdate}
            />
          ))
        )}
      </div>
    </>
  );
};
export default PromotionBannerPage;
