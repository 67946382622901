import React from "react";
import { useState } from "react";
import UpdateBannerPopup from "../promotionBanner/UpdateBannerPopup";

//main function
const PromotionBannerCard = ({ banner, onBannerUpdate }) => {
  let ImagePath = process.env.REACT_APP_BANNERS;
  let ImagePullPath = ImagePath + banner.bannerUrl;
  // https://storage.googleapis.com/fitfundabucket/banners/13/1.jpg
  
  //declare start
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(banner.isActive);
  //declare end

  //function/events/methods
  const showUpdateBannerModal = (event, banner) => {
    setShowModal(!showModal);
  };

  //main return
  return (
    <>
      <div className="p-2 border-bottom">
        <div
          className="row align-items-start m-2 justify-content-center align-items-center"
          onClick={(event) => showUpdateBannerModal(event, banner)}
        >
          <div className="col">
            <img
              src={ImagePullPath}
              alt={banner.bannerId}
              className=""
              style={{ maxWidth: "70%"}}
            />
          </div>
          <div className="col text-left">
          <div className="text-left">
           {"Event Reference: " + (banner.eventReference || "--")}
           </div>

          </div>
          <div className="col">
            <div className="form-check form-switch  float-end" href="#">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                value={banner.bannerId}
                checked={banner.isActive === true}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <UpdateBannerPopup
          banner={banner}
          onBannerUpdate={onBannerUpdate}
          setPopUpVisibility={(value) => setShowModal(value)}
        />
      )}
    </>
  );
};

export default PromotionBannerCard;
