import React from 'react';
import InputTextBox from '../../controls/input/InputTextBox';
import { convertDateTimeToISTForUI } from '../../../util/DateTimeUtility';


const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

const TicketDetails = ({event }) => {
    // if (!event || !event.ticketDetails) return <div>Loading...</div>;

    const renderStatus = (status)=>{
      return status === "act" ? "Active" :status === "iact" ? "Cancelled" : "";
    }
    
    //main retrun
  return (
    <>   
       <div className="card-body p-1">
      <div className="accordion accordion-flush m-0" id="accordionFlushExample">
        
      {event && 
      event?.ticketDetails?.map(ticket => (
        <div className="accordion-item">
          <h2 className="accordion-header">
            {/* <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#participant-collapseTwo"
              aria-expanded="false"
              aria-controls="participant-collapseTwo"
            > */}
              <div className="alert alert-info">
                <h1 >Ticket Details of Ticket ID:{ticket.ticketId}</h1>
              </div>
            {/* </button> */}
          </h2>
          <div
            id="participant-collapseTwo"
            className="accordion-collapse collapse accordion-detail px-4 show"
            data-bs-parent="#accordionFlushExample"
          >
            <form className="row g-3 pb-3">
              <div className="col-md-6 mb-3">
                {/* <h2> Ticket No: {ticket.ticketId}</h2> */}
                <label htmlFor="inputEmail4" className="form-label">
                  Ticket ID
                </label>
                <InputTextBox
                  id="ticketId"
                  name="ticketId"
                  type="text"
                  value={ticket.ticketId}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                    <label htmlFor="inputEmail4" className="form-label">
                    Ticket Name
                    </label>
                    <InputTextBox
                      id="ticketName"
                      name="ticketName"
                      value={ticket.ticketName}
                      disabled
                    />
                  </div>
           
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Ticket Type
                </label>
                <InputTextBox
                  id="ticketType"
                  name="ticketType"
                  type="text"
                  value={ticket.ticketType}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Total Quantity
                </label>
                <InputTextBox
                  id="totalQuantity"
                  name="totalQuantity"
                  type="text"
                  value={ticket.totalQuantity}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Ticket Price
                </label>
                <InputTextBox
                  id="ticketPrice"
                  name="ticketPrice"
                  type="text"
                  value={Number(ticket.ticketPrice).toLocaleString('en-US', {maximumFractionDigits: 2 })}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Ticket Sale Start Date
                </label>
                <InputTextBox
                  id="ticketSaleStartDate"
                  name="ticketSaleStartDate"
                  type="text"
                  value={convertDateTimeToISTForUI(ticket.ticketSaleStartDate)}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Ticket Sale End Date
                </label>
                <InputTextBox
                  id="ticketSaleEndDate"
                  name="ticketSaleEndDate"
                  type="text"
                  value={convertDateTimeToISTForUI(ticket.ticketSaleEndDate)}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Ticket Description
                </label>
                <InputTextBox
                  id="messageToAttendees"
                  name="messageToAttendees"
                  type="text"
                  value={stripHtmlTags(ticket.ticketDescription) || "--"}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Message To Attendees
                </label>
                <InputTextBox
                  id="messageToAttendees"
                  name="messageToAttendees"
                  type="text"
                  value={stripHtmlTags(ticket.messageToAttendees) || "--"}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Available Tickets
                </label>
                <InputTextBox
                  id="avlbTickets"
                  name="avlbTickets"
                  type="text"
                  value={ticket.avlbTickets}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
              Ticket Status
                </label>
                <InputTextBox
                  id="ticketStatus"
                  name="ticketStatus"
                  type="text"
                  value={renderStatus(ticket.ticketStatus)}
                  disabled
                />
              </div>      
           
            </form>
          </div>
        </div>
          ))}
          
      </div>
     
    </div>
       
    </>
  );
};

export default TicketDetails;
