import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from "../../components/controls/table/Table";
import { GetAllOrganizer } from "../../service/kycAPI";
import Spinner from "../../components/controls/spinner/Spinner";

//main function
const KycPage = () => {
  //declare start
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true); // loading state
  const userData = useSelector((state) => state.login.login.login);
  //declare end

  //function/events/methods
  useEffect(() => {
    async function fetchOrganizers() {
      if (userData?.token) {
        const organizersResponse = await GetAllOrganizer(userData.token);
        setOrganizers(organizersResponse?.data); // the response data contains the list of organizers
        setLoading(false); // Set loading to false after data is loaded
      }
    }
    fetchOrganizers();
  }, [userData]);

  const renderStatus = (status) => {
    // return status === "act" ? "Approved" : status === "rej" ? "Rejected" :
    // status === "inserting" ? "Event in creation" : status === "inserted" ? "Event created" : "";

    switch (status) {
      case "act":
        return "Approved";
      case "rej":
        return "Rejected";
      case "inserting":
        return "Profile creation in progress";
      case "inserted":
        return "Profile created";
      default:
        return "";
    }
  };

  //table
  const config = {
    tableTitle: "KYC",
    data: organizers,
    displayRecord: 10,
    columns: [
      {
        title: "Organizer Id",
        titleClass: "",
        dataClass: "",
        dataField: "organizerId",
        isSort: false,
        sortByValue: (data) => data.organizerId,
      },
      {
        title: "Business Name",
        titleClass: "",
        dataClass: "",
        dataField: "businessName",
        isSort: true,
        sortByValue: (data) => data.businessName,
      },
      {
        title: "Business Email",
        titleClass: " ",
        dataClass: "",
        dataField: "businessEmail",
        isSort: false,
        sortByValue: (data) => data.businessEmail,
      },
      {
        title: "Mobile",
        titleClass: " ",
        dataClass: "",
        dataField: "businessMobile",
        isSort: false,
        sortByValue: (data) => data.businessMobile,
      },
      {
        title: "KYC Status",
        titleClass: "  align-middle text-success-btn",
        dataClass: "",
        dataField: "orgStatus",
        isSort: true,
        isLink: true,
        sortByValue: (data) => data.orgStatus,
        render: (data) => renderStatus(data.orgStatus),
      },
      {
        title: "Action",
        titleClass: "  text-center ",
        isLink: true,
        render: (item) => (
          <div class="">
            <Link
              class="btn-link btn align-middle p-0"
              to={`/kyc-details/${item.userId}`}
            >
              View More
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "",
      },
    ],
  };

  //main return
  return (
    <>
      {/* <h3 className="my-3">
        <i className="bi bi-chevron-left me-2 fw-bold"></i>
        KYC
      </h3> */}
      {/* <select
        className="year-select float-end"
        style={{ width: 100, marginTop: "-40px", marginRight: "13px" }}
      >
        <option>All</option>
        <option value={1}>2023</option>
        <option value={2}>2024</option>
        <option value={3}>2025</option>
      </select> */}
      {loading ? <Spinner /> : <Table config={config} />}
    </>
  );
};

export default KycPage;
