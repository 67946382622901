import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import StateCard from '../../components/setting/state/StateCard';
import { GetStateData, CreateState } from '../../service/commonAPI';
import Spinner from '../../components/controls/spinner/Spinner';

//main function
const StatePage = (stateId) => {
  //declare start
  const navigate = useNavigate(); // add useNavigate
  const [state, setState] = useState([]);
  const [stateName, setStateName] = useState("");
  const [disableState, setDisableState] = useState(true);
  const [enable, setEnable] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [updatedStateName, setUpdatedStateName] = useState("");
  const [updatedStateEnabled, setUpdatedStateEnabled] = useState(true);
  const [loading, setLoading] = useState(true); // loading state
//declare end

//function/events/methods
  useEffect(() => {
    const GetState = async () => {
      const result = await GetStateData();
      setState(result.data);
      setLoading(false);
    }
    GetState();
  }, []);

  const handleStateClick = (event, item) => {
    event.preventDefault();
    setStateName(item.Name);
    setUpdatedStateName(item.Name);
    setUpdatedStateEnabled(item.Enabled);
    setShowModal(true);
  }
  
  const handleUpdateAndDelete = async (event, isDelete) => {
    event.preventDefault();

    try {
      const updatedState = await CreateState({
        name: updatedStateName,
        enabled: updatedStateEnabled,
        isDelete: isDelete,
      });

      // Update the state in StateList
      const updatedStateList = state.map(item => {
        if (item.Name === updatedStateName && isDelete) {
          // If it's a delete operation, don't include the state in the updated list
          return null;
        }
        return item;
      });

      if (!isDelete) {
        // Include the updated state in the list for update operation
        updatedStateList.push(updatedState);
      }

      setState(updatedStateList);
    } catch (error) {
      console.error("Error updating or deleting state:", error);
    }

    setShowModal(false);
  };

  const handleCheckbox = (event, value) => {
    event.preventDefault();
    setDisableState(value);
  }

  const handleCheckbox1 = async (event) => {
    setEnable(!enable);
    handleCheckbox(event, !enable);
  };

  // const handleUpdate = (event) => {
  //   // Perform the update operation using updatedStateName and updatedStateEnabled
  //   // Close the modal
  //   setShowModal(false);
  // };

  //main return
  return (
    <>
      <div className="col-12 main-contian manage-city manage-category">
      <Link to="#"
       onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
      style={{ textDecoration: "none", color: "black" }}>
    
        <h3 className="my-3">
          <i className="bi bi-chevron-left me-2 fw-bold" />
          <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
        </svg>
          Manage State
        </h3>
        </Link>
        <div className="card py-2 mt-4">
        {loading ? (
        <Spinner/>
        ) : (
          state && state.map((item, i) => {
              return <StateCard
                key={i}
                index={i}
                state={item}
                handleStateClick={(event) => handleStateClick(event, item)}
                check={disableState}
                handleCheckbox={handleCheckbox} />;
            })
        )}
        </div>
        {/* <div className="manage-category-modal">
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: showModal ? "block" : "none" }}
          >
            <div className="modal-dialog" >
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Update State
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  />
                </div>

                <div className="modal-body manage-category-modal">
                  <h5>{`Enter the updated name for ${stateName}:`}</h5>
                  <input
                    type="text"
                    value={updatedStateName}
                    placeholder='Update Name' 
                    onChange={(e) => setUpdatedStateName(e.target.value)}
                  />
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="enableCheckbox"
                      checked={updatedStateEnabled}
                      onChange={(e) => setUpdatedStateEnabled(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="enableCheckbox">
                      Enable State
                    </label>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-upload"
                    data-bs-dismiss="modal"
                    onClick={(event) => handleUpdateAndDelete(event, false)}
                  >
                    Update
                  </button>
                  <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={(event) => handleUpdateAndDelete(event, true)}
            >
              Delete
            </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default StatePage;
