import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import LogInPage from "./pages/adminLogIn/LogInPage";
import Master from "./components/admin/Master";
import * as Common from "../src/util/commonFun";
import { LOCAL_STORAGE_USER } from "../src/util/Constants";
import * as Call from "../src/util/validations";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";

//main function
function App() {
  //declare start
  const [isLoggIn, setIsLoggIn] = useState(false);
  const navigate = useNavigate();
  //declare end

  //function/events/methods
  useEffect(() => {
    if (!Call.IsEmpty(Common.getLocalData(LOCAL_STORAGE_USER))) {
      setIsLoggIn(true);
    } else {
      setIsLoggIn(false);
      // navigate("/");
    }
  });

  //main return
  return (
    <Routes>
      <Route index element={<LogInPage />} />
      <Route path="*" element={<Master />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}

export default App;
