import { useFetchGetRequest } from "./api";
import { useSelector } from "react-redux";

//
export const GetStateData = () => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/get-state`;
  return useFetchGetRequest(apiURL);
};

export const CreateState = async () => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/create-state`;
  return useFetchGetRequest(apiURL);
};

export const GetStoreLoginData = () => {
  return useSelector((state) => state.login.login.login);
};
export default GetStateData;
