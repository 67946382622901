import * as type from "../actions/createEvent";
import { combineReducers } from "redux";

const initialStateForCartEvents = {
  eventDetails: [],
  eventLocation: [],
  eventPhotos: [],
  ticketDetails: [],
};

function eventDetails(state = initialStateForCartEvents.eventDetails, action) {
  console.log("reducer call for cartEvents");
  console.log(action);
  switch (action.type) {
    case type.FETCH_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        eventDetails: action.payload,
      };
    case type.ADD_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        eventDetails: action.payload,
      };
    case type.DELETE_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        [createEvent.eventDetails]: state.createEvent.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    default:
      return state;
  }
}
/* 
function eventLocation(
  state = initialStateForCartEvents.eventLocation,
  action
) {
  console.log("reducer call for cartEvents");
  console.log(action);
  switch (action.type) {
    case type.FETCH_EVENT_LOCATION_SUCCESS:
      return {
        ...state,
        [createEvent.eventLocation]: action.payload,
      };
    case type.ADD_EVENT_LOCATION_SUCCESS:
      return {
        ...state,
        eventLocation: action.payload,
      };
    case type.DELETE_EVENT_LOCATION_SUCCESS:
      return {
        ...state,
        [createEvent.eventLocation]: state.createEvent.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    default:
      return state;
  }
}

function eventPhotos(state = initialStateForCartEvents.eventPhotos, action) {
  console.log("reducer call for cartEvents");
  console.log(action);

  switch (action.type) {
    case type.FETCH_EVENT_PHOTOS_SUCCESS:
      return {
        ...state,
        [createEvent.eventPhotos]: action.payload,
      };
    case type.ADD_EVENT_PHOTOS_SUCCESS:
      return {
        ...state,
        eventPhotos: action.payload,
      };
    case type.DELETE_EVENT_PHOTOS_SUCCESS:
      return {
        ...state,
        [createEvent.eventPhotos]: state.createEvent.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    default:
      return state;
  }
}

function ticketDetails(
  state = initialStateForCartEvents.ticketDetails,
  action
) {
  console.log("reducer call for cartEvents");
  console.log(action);

  switch (action.type) {
    case type.FETCH_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        [createEvent.ticketDetails]: action.payload,
      };
    case type.ADD_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        ticketDetails: action.payload,
      };
    case type.DELETE_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        [createEvent.ticketDetails]: state.createEvent.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    default:
      return state;
  }
} */

const createEvent = combineReducers({
  eventDetails,
  /*  eventLocation,
  eventPhotos,
  ticketDetails, */
});
export default createEvent;
