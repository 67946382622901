import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GetMyNotifications } from "../../service/notificationAPI";
import Spinner from "../controls/spinner/Spinner";
import { convertDateTimeToISTForUI } from "../../util/DateTimeUtility";

//main function
const NotificationCard = () => {
  //declare start
  const userData = useSelector((state) => state.login.login.login);
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(true); // loading state
  //declare end

  //function/events/methods
  useEffect(() => {
    const GetNotificationData = async () => {
      try {
        if (userData.token) {
          const notificationResult = await GetMyNotifications(userData.token);
          if (notificationResult?.success === true) {
            setNotificationData(notificationResult?.data);
            setLoading(false);
          } else {
            setLoading(false);
            alert(notificationResult?.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetNotificationData();
  }, []);

  const config = {
    tableTitle: "Notifications",
    data: notificationData,
    displayRecord: 10,
    defaultSortBy: (data) => data?.updatedAt,
    columns: [
      // {
      //   title: "Image",
      //   titleClass: "text-center",
      //   dataClass: "py-1 images align-middle",
      //   isImage: true,
      //   render: (item) => handleImagepath(item),
      // },
      {
        title: "Message ",
        titleClass: " ",
        dataClass: " text-start",
        dataField: "message",
        isSort: false,
        // sortByValue: (data) => data?.message,
        render: (item) => (
          <div class="text-start">
            {item.message}
          </div>
        ),
      },
      {
        title: "Date",
        isLink: true,
        // isSort: true,
        render: (item) => (
          <div class="">
            {convertDateTimeToISTForUI(item.updatedAt)}
          </div>
        ),
        titleClass: "",
        dataClass: "text-start",
        sortByValue: (data) => data?.updatedAt,
      },
    ],
  };

  // const handleImagepath = (item) => {
  //   // let ImageFullPath = ImagePath + item.eventId + FOLDER_EVENT_PHOTO + item?.eventPhotos;

  //   return (
  //     <img
  //       src="./images/city.jpg"
  //       class="img-fluid rounded"
  //       style={{ maxWidth: "50px" }}
  //     />
  //   );
  // };

  //main return
  return <>{loading ? <Spinner /> : <Table config={config} />}</>;
};




const Table = ({ config }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const displayRecord = config?.displayRecord;
  const totalRecord = config.data?.length;
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortData, setSortData] = useState(config?.data || []);
// debugger;
  //default data sorting
  useEffect(()=>{
    setSortBy(config?.defaultSortBy);
    setSortData(()=>config?.data);
  },[config?.data]);

  //data sorting
  useEffect(() => {
    let sortedData = config?.data;
    if (sortOrder && sortBy) {
      const { sortByValue } = config?.columns.find(
        (column) => column.dataField === sortBy
      );
      sortedData = [...config?.data].sort((a, b) => {
        const valueA = sortByValue(a);
        const valueB = sortByValue(b);

        const reverseOrder = sortOrder === "asc" ? 1 : -1;

        if (typeof valueA === "string") {
          return valueA.localeCompare(valueB) * reverseOrder;
        } else {
          return (valueA - valueB) * reverseOrder;
        }
      });
      setSortData(sortedData);
    }
  }, [sortBy, sortOrder]);

  if (sortBy && sortOrder) {
    const sortedData = config.data?.map((row, index) => {
      for (let key in row) {
        if (key === sortBy) {
          return row;
        }
      }
    });
  }

  const getSortByIcons = (column) => {
    if (column?.isSort) {
      if (sortBy === column?.dataField) {
        if (sortOrder === "desc") {
          return (
            <>
              <i
                class="arrow up"
                onClick={() => handleSortingClick(column, "asc")}
              ></i>
            </>
          );
        } else if (sortOrder === "asc") {
          return (
            <>
              <i
                class="arrow down"
                onClick={() => handleSortingClick(column, "desc")}
              ></i>
            </>
          );
        }
      }
      //default
      return (
        <>
          <i
            class="arrow up"
            onClick={() => handleSortingClick(column, "asc")}
          ></i>
          <i
            class="arrow down"
            onClick={() => handleSortingClick(column, "desc")}
            style={{ margin: "-9px" }}
          ></i>
        </>
      );
    }
  };

  const handleSortingClick = (column, type) => {
    //alert("handleSortingClick");
    if (column?.isSort) {
      setSortBy(() => column?.dataField);
      setSortOrder(() => type);
    }
  };
  //end
  //
  const thColumns = config.columns?.map((column, index) => {
    return (
      <>
        {index === 0 && (
          <th className="text-start" key={"thsn" + index}>
            Sr. No.
          </th>
        )}
        <th className={`text-start ${column?.titleClass}`} key={"td" + index}>
          {column?.title}
          {getSortByIcons(column)}
        </th>
      </>
    );
  });

  let totalRecord1 = totalRecord;
  totalRecord1 = totalRecord1 - displayRecord;
  const trRows = sortData?.map((row, index) => {
    //config.data?.map((row, index) => {
    //pagination logic
    if (displayRecord > 0) {
      if (currentPageIndex === 1) {
        if (index >= displayRecord) return;
      } else if (currentPageIndex > 1) {
        if (
          index >= displayRecord * (currentPageIndex - 1) &&
          index < displayRecord * currentPageIndex
        ) {
          /* if (index >= 5 && index < 10) {
          } else return; */
          //show record
        } else return;
      }
    }
    //end

    const tdColumns = config.columns?.map((column, index2) => {
      if (column?.isLink === true || column?.isImage === true) {
        return (
          <td
            className={`text-start ${column?.dataClass}`}
            key={"td" + index2}
          >
            {column.render(row)}
          </td>
        );
      } else {
        for (let key in row) {
          if (key === column?.dataField) {
            return (
              <td
                className={`text-start ${column?.dataClass}`}
                key={"td" + index2}
              >
                {row[key]}
              </td>
            );
          }
        }
      }
    });

    return (
      <tr className="text-start">
        <td key={"tdsn" + index}>{index + 1}</td>
        {tdColumns}
      </tr>
    );
  });

  const renderPagination = () => {
    let renderPageIndex = [];
    let pageIndex = 0;
    let totalRecord1 = totalRecord;
    while (totalRecord1 > 0) {
      pageIndex++;
      totalRecord1 = totalRecord1 - displayRecord;
      let pindex = pageIndex;
      renderPageIndex.push(
        <li class="page-item m-1">
          <a
            className={`page-link ${
              currentPageIndex === pageIndex ? "bg-primary" : ""
            } `}
            href="#"
            onClick={() => handlePagination(pindex)}
          >
            {pageIndex}
          </a>
        </li>
      );
    }

    return (
      <>
        <div class="d-flex justify-content-center">
          <nav aria-label="Page navigation example">
            <ul class="pagination bashboard-pagination pagination-link">
              <li class="page-item m-1">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">
                    <i class="bi bi-chevron-left"></i>
                  </span>
                </a>
              </li>

              {renderPageIndex}

              <li class="page-item m-1">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">
                    <i class="bi bi-chevron-right"></i>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </>
    );
  };

  const handlePagination = (nextPageIndex) => {
    //alert(nextPageIndex);
    setCurrentPageIndex(() => nextPageIndex);
  };
  //
  return (
    <>
      <div class="p-2">
        <div class="table-responsive small bashboard-table rounded">
          {config?.tableTitle && (
            <h1 class="p-3 text-start bg-secondary-subtle m-0 border-bottom border-secondary">
              {config?.tableTitle}
            </h1>
          )}
          <table class="table table-hover table-striped table-sm ">
            <thead class="thead-light">
              <tr>{thColumns}</tr>
            </thead>
            <tbody className="align-middle">{trRows}</tbody>
          </table>
          {renderPagination()}
        </div>
      </div>
    </>
  );
};



export default NotificationCard;
