import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CityCard from "../../components/setting/city/CityCard";
import StateDropdown from "../../components/setting/city/StateDropdown";
import { GetCityByState } from "../../service/cityAPI";
import AddNewCity from "../../components/setting/city/AddNewCity";
import Spinner from "../../components/controls/spinner/Spinner";

//main function
const CityPage = () => {
  //declare start
  const navigate = useNavigate(); // add useNavigate
  const [loadCity, setLoadCity] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(true); // loading state
  //declare end

  //function/events/methods
  useEffect(() => {
    const fetchCity = async () => {
      const response = await GetCityByState(stateId);
      setLoadCity(response?.data || []);
      setLoading(false);
    };
    fetchCity();
  }, [stateId]);

  const handleStateChange = (selectedStateId) => {
    setStateId(selectedStateId);
    setSelectedCity(null); // Reset selected city when state changes
  };

  const handleCityAdded = async (event) => {
    if (event) event.preventDefault();
    // Refresh the list of cities after a new city added
    const response = await GetCityByState(stateId);
    setLoadCity(response?.data || []);
    setLoading(false);
  };

  // let content ;
  //     const [showModal, setShowModal] = useState(false);
  // const [showContent, setShowContent] = useState('');

  const handleUpdateCity = async (event) => {
    // Refresh the list of cities after a new city added
    const response = await GetCityByState(stateId);
    setLoadCity(response?.data || []);

    // handleCityAdded(event);
    // event.preventDefault();
    //alert("ok");
    // let data = [city];

    //  console.log("handleupdatecity",city);
    //  setShowModal(false);

    // //  content= <UpdateCityPopUp stateId={stateId} onStateChange={handleStateChange}/>
    // // content=<AddNewCity onCityUpdate={()=>true} city={()=>city} stateId={()=>stateId} onStateChange={()=>onStateChange} />;
    //  setShowContent(content);
    //  console.log("check",content);
    // content=city;
  };

  //main return
  return (
    <>
      <div className="col-12 main-contian manage-city manage-category ">
        {/* Page Name */}
        <div style={{ marginTop:"-18px"}}>
        <Link
          to="#"
          onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
          style={{ textDecoration: "none", color: "black" }}
        >
          <h3 className="my-3">
            <i className="bi bi-chevron-left me-2 fw-bold" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 -960 960 960"
              width="20"
            >
              <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
            </svg>
            Manage City
          </h3>
        </Link>
        </div>
        <StateDropdown onStateChange={handleStateChange} cameFrom="CityPage" />
        {/* Since State & Cities are added once only. This feature is removed. */}
        <AddNewCity
          stateId={stateId}
          onStateChange={handleStateChange}
          onCityAdded={handleCityAdded}
        />
        {/* {showModal && showContent  }  */}
        <div className="card py-2 mt-4">
          {loading ? (
            <Spinner />
          ) : (
            loadCity &&
            loadCity.map((item, i) => (
              <CityCard
                key={i}
                city={item}
                stateId={stateId}
                // onUpdate={handleUpdate}
                // onDelete={handleDelete}
                onStateChange={handleStateChange}
                onCityAdded={handleCityAdded}
                onCityUpdate={handleUpdateCity}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default CityPage;
