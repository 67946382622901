export const skipKey = [
  "errors",
  "IsValid",
  "isRequired",
  // "lat",
  // "long",
  "userId",
  "eventId",
  "tempDiscountPercentType", //temp
  "tempDiscountPercentValue",
];

export const EventDetails = {
  eventName: "",
  categoryId: 0,
  categoryName: "",
  categoryPic: "",
  eventStartDate: null, //"DD/MM/YYYY | Time",
  eventEndDate: null, //"DD/MM/YYYY | Time",
  supportEmail: "",
  supportMobile: "",
  eventDescription: "",
  faqs: [{ faqId: 1, question: "", answer: "" }], //,{qid:2, que:"",ans:""}],
  terms: "",
  eventPermissionDocument: "",
  errors: {},
  IsValid: {},
  isRequired: {
    eventName: true,
    categoryId: true,
    eventStartDate: true,
    eventEndDate: true,
    supportEmail: true,
    supportMobile: true,
  },
};

export const EventLocation = {
  address: "",
  area: "",
  cityId: "",
  stateId: "",
  pincode: "",
  lat: 20.5937,
  long: 78.9629,
  eventId: "",
  userId: "",
  errors: {},
  IsValid: {},
  isRequired: {
    address: true,
    area: true,
    cityId: true,
    stateId: true,
    pincode: true,
  },
};

// Form initial Data
export const EventPhotos = {
  files: [],
  errors: {},
  IsValid: {},
  eventId: "",
  userId: "",
  isRequired: {
    files: true,
  },
};

export const TicketDetails = {
  ticketId: 0,
  ticketName: "",
  ticketType: "",
  totalQuantity: 0,
  ticketPrice: 0,
  ticketDescription: "",
  messageToAttendees: "",
  ticketSaleStartDate: null, //"DD/MM/YYYY | Time",
  ticketSaleEndDate: null, //"DD/MM/YYYY | Time",
  // eventId: "",
  // tickets: [],
  // userId: "",
  errors: {},
  IsValid: {},
  isRequired: {
    ticketName: true,
    ticketType: true,
    totalQuantity: true,
    ticketSaleStartDate: true,
    ticketSaleEndDate: true,
  },
};

export const DiscountInfo = {
  //userId: "",
  discountType: "",
  discountName: "",
  discountPercent: 0,
  discountAmount: 0,
  numOfDiscount: 0,
  minTickets: 2,
  discountStartDate: "", //today,
  discountEndDate: "", //tomorrow,
  isCouponActive: false,
  tempDiscountPercentType: "discountPercent", //temp
  tempDiscountPercentValue: "", //temp
  eventId: "",
  errors: {},
  IsValid: {},
  isRequired: {
    discountType: false,
    discountName: false,
    discountPercent: false,
    discountAmount: false,
    numOfDiscount: false,
    minTickets: false,
    discountStartDate: false,
    discountEndDate: false,
    isCouponActive: false,
  },
};
export const EventSettings = {
  isGstFromAttendees: false,
  hostingFeesPayer: "organizer",
  pgFeesPayer: "organizer",
  embeddCode: "",
  isRedirect: false,
  userId: "",
  eventId: "",
  isRequired: {},
};

export const EventDetailsPreview = {
  data: {
    eventId: null,
    eventName: null,
    eventStartDate: null,
    eventEndDate: null,
    eventDescription: null,
    terms: null,
    address: null,
    area: null,
    pincode: null,
    lat: null,
    long: null,
    eventStatus: null,
    lastModifiedBy: null,
    categoryId: null,
    categoryName: null,
    categoryPic: null,
    stateId: null,
    stateName: null,
    cityId: null,
    cityName: null,
    cityPic: null,
    permission: null,
    faqs: [{ faqId: 1, question: "", answer: "" }],
    eventPhotos: [],
    ticketDetails: [],
    defaultPrice: null,
    participantForm: null,
    discountCoupon: null,
    eventSetting: null,
  },
};

export const OrganizerProfile = {
  businessName: "",
  businessEmail: "",
  businessMobile: "",
  businessWebsite: "",
  businessLogo: "",
  businessAddress: "",
  businessPincode: "",
  businessCountry: "",
  businessState: "",
  businessCity: "",
  userId: "",
  errors: {},
  IsValid: {},
};

export const OrganizerKYCInfo = {
  businessEntityType: "",
  businessPanNo: "",
  businessPanDoc: "",
  isTicketInvolveGst: false,
  businessGstNo: "",
  businessGstStateNo: "",
  businessHsnCode: "",
  businessGstPercent: "",
  businessGstDoc: "",
  businessAddressProofDoc: "",
  signatoryName: "",
  signatoryPanNo: "",
  signatoryPanDoc: "",
  signatoryAadharNo: "",
  signatoryAadharDoc: "",
  userId: "",
  errors: {},
  IsValid: {},
};

export const OrganizerBankDetails = {
  accountHolderName: "",
  ifsc: "",
  bankName: "",
  branchName: "",
  accountType: "",
  accountNumber: "",
  cnfAccountNumber: "",
  bankProofDoc: "",
  userId: "",
  eventId: "",
  errors: {},
  IsValid: {},
};

export const Search = {
  searchText: "",
  cityId: 0,
  stateId: 0,
  categoryId: 0,
  sDate: "",
  eDate: "",
  minPrice: 0,
  maxPrice: 0,
  errors: {},
  IsValid: {},
};
