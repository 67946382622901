import {
  useFetchGetRequest,
  useFetchGetRequestWithToken,
  useFetchMultipartWithToken,
} from "./api";

export const GetCategoryData = () => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/get-all-categories`;
  return useFetchGetRequest(apiURL);
};

export const CreateCategory = (inputValue, uploadImageUrl, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/create-category`;

  const formData = new FormData();
  formData.append("categoryName", inputValue.categoryName);
  formData.append("categoryPic", uploadImageUrl);
  formData.append("isActive", true);

  return useFetchMultipartWithToken(apiURL, formData, token);
};

export const UpdateCategory = async (
  category,
  inputValue,
  updateImageFileObject,
  token
) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/update-category`;

  const formData = new FormData();
  formData.append("categoryId", category.categoryId);
  formData.append("categoryName", inputValue.categoryName);
  formData.append("isActive", inputValue.isActive);
  formData.append("categoryPic", updateImageFileObject);

  return useFetchMultipartWithToken(apiURL, formData, token);
};

export const DeleteCategory = async (categoryId, token) => {
  const apiURL =
    `${process.env.REACT_APP_USER_MS}config/delete-category/` +
    parseInt(categoryId);
  return useFetchGetRequestWithToken(apiURL, token);
};
