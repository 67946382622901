import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

const TinyEditor = ({
  handleInput,
  initialValue,
  maxTextLength,
  titleEditor,
  isMandatory,
  prop,
}) => {
  //const initialText = 'Event Description....';
  const [text, setText] = useState(initialValue);
  const sizeLimit = maxTextLength ?? 2000;
  const [length, setLength] = React.useState(0);

  const handleChange = (newText) => {
    const lines = newText.split("\n").length;
    if (newText.length <= sizeLimit) {
      setText(newText);
      handleInput(newText);
      setLength(newText.length);
    }
  };

  const handleKeyPress = (event) => {
    // Prevent further typing if the maximum length is reached
    if (text.length >= sizeLimit && event.charCode !== 8) {
      event.preventDefault();
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],

        ["link"],
        ["clean"], // remove formatting button
      ],
    },
  };

  const editorStyle = {
    height: `${13 * 24}px`, // Assuming each line height is 24px
    overflowY: "auto", // Add scrollbar when content exceeds the height
  };

  return (
    <>
      <div>
        <label for="exampleInputEmail1" class="form-label">
          {titleEditor}
          {isMandatory ? <span className="text-red-600"></span> : <></>}
        </label>
        <label for="exampleInputEmail1" class="form-label float-end">
          {/* {length} / {maxTextLength} */}
        </label>
      </div>
      <div className="editor-container">
        <ReactQuill
          style={editorStyle}
          modules={modules}
          theme="snow"
          value={initialValue}
          lines={"20"}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          {...prop}
        />
      </div>
    </>
  );
};

export default TinyEditor;
