import React from "react";
import { convertDateTimeToISTForUI } from "../../util/DateTimeUtility";

const AnnouncementItem = ({ announcementId, subject, audience, emailBody, createdAt }) => {
  return (
    <div className="col-6 mb-4 btn text-start">
      <div
        className="card total-v-card"
        style={{
          height: '180px', // Fixed card height
          maxHeight: '180px', // Ensures the card does not exceed this height
          overflow: 'hidden', // Hides any overflow content
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div className="card-body p-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '20px' }}>
          <div className="d-flex align-items-center">
            <div className="me-2">
              <img src="../images/note01.svg" alt="" />
            </div>
            <div className="flex-fill">
              <h3>{subject}</h3>
              <h5> Audience: {audience}</h5>
            </div>
            <div>
              <h5>{convertDateTimeToISTForUI(createdAt)}</h5>
            </div>
          </div>
          <div
            className="p-3"
            style={{
              height: '100px', // Fixed height for content area to allow scrolling
              overflowY: 'auto', // Adds vertical scrolling
              position: 'relative'
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: emailBody }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementItem;
