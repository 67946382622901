import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetCategoryData } from "../../service/categoryAPI";
import CategoryCard from "../../components/setting/category/CategoryCard";
import AddNewCategory from "../../components/setting/category/AddNewCategory";
import Spinner from "../../components/controls/spinner/Spinner";

//main function
const CategoryPage = () => {
  // declare start
  const navigate = useNavigate(); // add useNavigate
  const [loadCategory, setLoadCategory] = useState([]);
  const [loading, setLoading] = useState(true); // loading state

  //declare end

  //function/event/method
  useEffect(() => {
    const GetCategoryList = async () => {
      const respone = await GetCategoryData();
      setLoadCategory(respone?.data || []);
      setLoading(false);
    };
    GetCategoryList();
  }, []);

  const handleCategoryCreated = () => {
    // Refresh category list after a new category is created
    GetCategoryData()
      .then((response) => {
        setLoadCategory(response?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  // const handleCategoryUpdate = async () => {
  //   const response = await GetCategoryData();
  //   setLoadCategory(response.data || []);
  // };

  const handleCategoryUpdate = async () => {
    GetCategoryData()
      .then((response) => {
        setLoadCategory(response?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  //main return
  return (
    <>
    <div className="col-4">
      <Link
        to="#"
        onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
        style={{ textDecoration: "none", color: "black", marginTop: "50px" }}
      >
        <h3 className="font-weight-semibold mx-2 my-2">
          <i className="bi bi-chevron-left fw-bold" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 -960 960 960"
            width="20"
          >
            <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
          </svg>
          Manage Category
        </h3>
      </Link>
      </div>
      <AddNewCategory onCategoryCreated={handleCategoryCreated} />

      <div className="card py-2 mt-4">
        {loading ? (
          <Spinner />
        ) : (
          loadCategory &&
          loadCategory.map((item, i) => (
            <CategoryCard
              key={i}
              category={item}
              onCategoryUpdate={handleCategoryUpdate}
            />
          ))
        )}
      </div>
    </>
  );
};

export default CategoryPage;
