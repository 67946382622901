import React from 'react';

const ParticipantDetails = ({event}) => {
  
  //main retrun
  return (
    <>
      <div className="card-body p-1">
      <div className="accordion accordion-flush m-0" id="accordionFlushExample">
        <div >
          {/* <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#participant-collapseThree"
              aria-expanded="false"
              aria-controls="participant-collapseThree"
            >
              <div className="d-flex justify-content-center align-items-center accordion-detail">
                <h1>Participant Details</h1>
              </div>
            </button>
          </h2> */}
          <div
            id="participant-collapseThree"
            className="accordion-collapse collapse accordion-detail px-4 show"
            data-bs-parent="#accordionFlushExample"
          >
           No Participants are available in {event.eventName}.
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ParticipantDetails;
