import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CreateBanner } from "../../../service/promotionBannerAPI";
import Button from "../../controls/buttons/Button";
import Modal from "../../controls/popup/Modal";
import InputTextBox from "../../controls/input/InputTextBox";

//main function
const AddNewBanner = ({onBannerAdded}) => {
  //declare start
  const [showModal, setShowModal] = useState(false);
  //create New banner
  const [imageUrl, setImageUrl] = useState("");
  const [uploadImageUrl, setUploadImageUrl] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const userData = useSelector((state) => state.login.login.login);
  const [eventRef, setEventRef] = useState("");
  const [loading, setLoading] = useState(false); 
  //declare end

  //function/events/methods
  const submitBannerForm = async (event) => {
    event.preventDefault();

    if (!uploadImageUrl) {
      setResponseMsg("Please Enter valid input");
      removeErrorMsg();
    } else {
      if (userData?.token) {
        setLoading(true);
        const createBannerResponse = await CreateBanner(
          eventRef,
          uploadImageUrl,
          userData?.token
        );
        if (createBannerResponse?.success) { 
          setLoading(false);         
          onBannerAdded();         
          setShowModal(() => false);
          alert(createBannerResponse?.message);          
        }
        else 
        alert(createBannerResponse?.error);
      }
    }
  };

  //event reference input change
  const handleEventRefChange = (event) => {
    setEventRef(event.target.value);
  };

  const handleSelectImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const validImageTypes = ["image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif"];
    
    if (!validImageTypes.includes(file?.type)) {
      setResponseMsg("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      removeErrorMsg();
      return;
    }

    if (file?.size > 7340032) {      // 1 MB = 1,024 × 1,024 B = 1,048,576 B {7340032)  // 7 MB = 7 * 1,048,576 B = 7,340,032 B
      setResponseMsg("File size exceeds 7 MB. Please choose a smaller file.");
      removeErrorMsg();
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setUploadImageUrl(() => event.target.files[0]);
    setImageUrl(imageUrl);
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setResponseMsg("");
    }, 3000);
  };

  const handleAddBannerClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //main return
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          dark
          onClick={handleAddBannerClick}
          style={{
            marginTop: "-35px",
          }}
        >
          Add New Banner
        </Button>
      </div>
     
      {showModal && (
        <Modal
          title="Add Promotion Banner"
          onClose={handleCloseModal}
          onSuccess={submitBannerForm}
          cancleText="Cancel"
          successText="Submit"
        >
           {responseMsg && (
            <div className="alert alert-danger" role="alert">
              <h6>{responseMsg}</h6>
            </div>
          )}
          <div className="modal-body manage-category-modal">
            <form action="" method="post">
            <div className="mb-0">
                <label htmlFor="exampleInputEmail1" className="form-label">
                 Event Reference
                </label>
                <InputTextBox
                  id="eventRef"
                  name="eventRef"
                  placeholder="Please Enter Event Reference"                 
                  onChange={handleEventRefChange}
                />               
              </div>
              <div className="mb-3" style={{fontSize:"12px", fontStyle:"italic"}}>(Hint: E20240000001/Example Event name)</div>
              <div className="form-group file-area">
                <div className="my-2 message">
                  Promotion Banner Image (size: 207px X 73px)
                  <span className="text-danger">*</span>
                </div>
                <input
                  type="file"
                  name="images"
                  id="images"
                  required="required"
                  multiple="multiple"
                  onChange={(event) => handleSelectImage(event)}
                />
                <div
                  className="upload-event-photo"
                  style={{ marginTop: "10px" }}
                >
                  <div className="success">
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "150px" }}
                      />
                    )}
                  </div>
                  <div className="default">
                    <p>
                      <i className="bi bi-image" />
                    </p>
                    Upload Image
                  </div>
                </div>
              </div>
           {loading && 
            <span
              className="loaderBreakup mt-5"
              style={{marginLeft:"210px"}}
            ></span>
          }
            </form>
          </div>
        </Modal>
      )}     
    </>
  );
};

export default AddNewBanner;
