import { useFetchGetRequest, useFetchMultipartWithToken } from "./api";

export const GetCityByState = (stateId) => {
  const apiURL =
    `${process.env.REACT_APP_USER_MS}config/get-cities-by-state/` +
    parseInt(stateId);
  return useFetchGetRequest(apiURL);
};

export const CreateCity = (inputValue, imageFileObject, stateId, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/create-city`;

  const formData = new FormData();
  formData.append("cityName", inputValue.cityName);
  formData.append("cityPic", imageFileObject);
  formData.append("isActive", true);
  formData.append("stateId", stateId);

  return useFetchMultipartWithToken(apiURL, formData, token);
};

export const UpdateCity = async (
  city,
  inputValue,
  updateImageFileObject,
  token
) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/update-city`;

  const formData = new FormData();
  formData.append("cityId", city.cityId);
  formData.append("cityName", inputValue.cityName);
  // formData.append("isActive", inputValue.isActive);
  formData.append("stateId", inputValue.stateId);
  formData.append("cityPic", updateImageFileObject);

  return useFetchMultipartWithToken(apiURL, formData, token);
};

export default GetCityByState;
