import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  GetAllEventOfOrganizer,
  GetAllOrganizerName,
  GetCustomMOATConfig,
  GetDefaultMOATConfig,
  SetupDefaultMOATConfig,
  SetupMOATConfigOfEvent,
} from "../../service/moatAPI";
import InputTextBox from "../controls/input/InputTextBox";
import * as Call from "../../util/validations";
import Button from "../../components/controls/buttons/Button";
import Spinner from "../controls/spinner/Spinner";

const initialData = {
  moatConfigId: "",
  configType: "",
  gstOrgPercent: 0.0,
  gstPgPercent: 0.0,
  gstHostPercent: 0.0,
  pgFeesPercent: 0.0,
  hostCommission: 0.0,
  platformFeesType: "", // Amount/Percent
  platformFees: 0.0,
  eventId: "",
  userId: "",
  errors: {},
  IsValid: {},
};

//main function
const MoatConfigCard = () => {
  //declare start
  const [organizerList, setOrganizerList] = useState(null);
  const [eventList, setEventList] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, IsValid } = inputValue;
  const userData = useSelector((state) => state.login.login.login);
  const [loading, setLoading] = useState(false);  // Spinner state

  //declare end

  //function/event/methods
  useEffect(() => {
    const getAllOrganizer = async () => {
      try {
        if (userData?.token) {
          setLoading(true);
          const organizerData = await GetAllOrganizerName(userData?.token);
          setLoading(false);
          if (organizerData?.success) {
            setOrganizerList(organizerData.data || []);
          } else {
            alert("Failed to fetch organizer data.");
          }
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    getAllOrganizer();
  }, []);

  const handleOnOrganizerChange = (event) => {
    const userIdIs = event.target.value;
    setSelectedUserId(userIdIs);
    inputValue.userId = userIdIs;
    setSelectedEvent("");
    if (userIdIs !== "" && userIdIs !== "0") {
      getAllEventsOfOrganizer(userIdIs);
    } else if (userIdIs === "0") {
      getDefaultMOATConfig();
    }
  };

  const handleOnEventSelect = (event) => {
    const eventIdIs = event.target.value;
    setSelectedEvent(eventIdIs);
    inputValue.eventId = eventIdIs;
    // inputValue.gstOrgPercent=
    getCustomMOATConfig(eventIdIs);
  };

  const getAllEventsOfOrganizer = async (userId) => {
    if (userId !== "0") {
      if (userData?.token) {
        const eventList = await GetAllEventOfOrganizer(userId, userData?.token);
        if (eventList?.success) {
          setEventList(eventList.data || []);
        } else {
          alert("Failed to load event of selected organizer.");
        }
      }
    } else {
      setSelectedEvent("");
    }
  };

  const getCustomMOATConfig = async (eventIdIs) => {
    if (userData?.token) {
      setLoading(true);
      const response = await GetCustomMOATConfig(eventIdIs, userData.token);
      setLoading(false);
      if (response?.success) {
        setInputValue((prev) => ({ ...prev, ...response.data }));
      } else {
        alert(response?.message);
      }
    }
  };

  const getDefaultMOATConfig = async () => {
    if (userData?.token) {
      setLoading(true);
      const response = await GetDefaultMOATConfig(userData.token);
      setLoading(false);
      if (response?.success) {
        setInputValue((prev) => ({ ...prev, ...response.data }));
        setEventList([]);
      } else {
        alert(response?.message);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    //validation
    if (isValid(name, value, e)) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      //show error

      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const isValid = (name, value) => {
    let val = false;
    let msg = "";

    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "pgFeesPercent":
          if (Call.IsEmpty(value)) {
            msg = "Please enter event name";
          } else {
            val = Call.IsNumberOnlyInput(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "gstPgPercent":
          if (Call.IsEmpty(value)) {
            msg = "Please enter event name";
          } else {
            val = Call.IsNumberOnlyInput(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "hostCommission":
          if (Call.IsEmpty(value)) {
            msg = "Please enter event name";
          } else {
            val = Call.IsNumberOnlyInput(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "gstHostPercent":
          if (Call.IsEmpty(value)) {
            msg = "Please enter event name";
          } else {
            val = Call.IsNumberOnlyInput(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "platformFeesType":
          val = Call.IsValidPlatformFeesType(value);
          if (!val) msg = "Invalid " + value;
          break;

        case "platformFees":
          if (Call.IsEmpty(value)) {
            msg = "Please enter Platform Fees.";
          } else {
            val = Call.IsNumberOnlyInput(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "eventId":
          val = Call.IsEmpty(value);
          if (!val) msg = "Invalid " + value;
          break;

        default:
          break;
      }
    }

    setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));
    //}
    return val;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (
      inputValue.IsValid &&
      inputValue.pgFeesPercent !== "" &&
      inputValue.pgFeesPercent !== "" &&
      inputValue.hostCommission !== "" &&
      inputValue.gstHostPercent !== "" &&
      inputValue.platformFeesType !== "" &&
      inputValue.platformFees !== "" &&
      selectedUserId !== ""
    ) {
      if (selectedUserId === "0") {
        if (userData?.token) {
          const result = await SetupDefaultMOATConfig(
            inputValue,
            userData.token
          );
          if (result?.success) {
            alert("Default settings are updated.");
          } else {
            alert(result?.message);
          }
        }
      } else {
        if (inputValue.eventId !== "" && inputValue.userId !== "") {
          if (userData?.token) {
            setLoading(true);
            const result = await SetupMOATConfigOfEvent(
              inputValue,
              userData.token
            );
            setLoading(false);
            if (result?.success) {
              alert("Custom settings for selected event are updated.");
            } else {
              alert(result?.message);
            }
          }
        } else {
          alert("Please select event.");
        }
      }
    } else {
      alert("Enter all required fields");
    }
  };

  //main return
  return (
    <div className="col-12 main-contain">
      <div className="row mb-3">
        <div className="col">
          <h3 className="font-weight-semibold">MOAT Configuration</h3>
        </div>
      </div>
      <div className="grid gap-3">
        <div className="p-2 g-col-6">
          <select
            className="form-select "
            aria-label="Default select example"
            value={selectedUserId}
            onChange={handleOnOrganizerChange}
          >
            <option value="">Select Organizer</option>
            <option value="0">Default Settings</option>

            {organizerList?.map((currentValue) => (
              <option key={currentValue?.userId} value={currentValue?.userId}>
                {currentValue?.businessName}
              </option>
            ))}
          </select>
        </div>

        {/* Select Event of the Organizer  */}
        <div className="p-2 g-col-6">
          <select
            className="form-select"
            aria-label="Default select example"
            value={selectedEvent}
            onChange={handleOnEventSelect}
            name="eventId"
            id="eventId"
            defaultValue="Select Event"
          >
            <option value="Select Event">Select Event</option>
            {eventList?.map((currentValue) => (
              <option
                key={currentValue?.eventName}
                value={currentValue?.eventId}
              >
                {currentValue?.eventName}
              </option>
            ))}
          </select>
          <div />
        </div>
      </div>

      {/* Selected Most Config Details  */}
     
      {loading ? (
        <Spinner />
      ) : (
        <>
           <div className="card-body p-1 py-10">
        <div
          className="accordion accordion-flush m-0"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header">
              {/* <button
                className="accordion-button collapsed "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#participant-collapseOne"
                aria-expanded="false"
                aria-controls="participant-collapseOne"
              > */}
              <div className="d-flex justify-content-center align-items-center accordion-detail alert alert-secondary">
                <h1>MOAT Configurations</h1>
              </div>
              {/* </button> */}
            </h2>
            <div
              id="participant-collapseOne"
              className="accordion-collapse accordion-detail px-4 collapse show"
              data-bs-parent="#accordionFlushExample"
            >
              <form className="row g-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    GST Organizer
                  </label>

                  <InputTextBox
                    id="gstOrgPercent"
                    name="gstOrgPercent"
                    type="number"
                    value={selectedEvent.businessGstPercent}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Payment Gateway Fees %
                  </label>
                  <InputTextBox
                    id="pgFeesPercent"
                    name="pgFeesPercent"
                    type="number"
                    maxLength={2}
                    value={inputValue.pgFeesPercent}
                    onChange={handleChange}
                    isValid={inputValue?.IsValid?.pgFeesPercent}
                    errorMsg={inputValue?.errors?.pgFeesPercent}
                    disabled={selectedUserId === "0" ? false : true}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    GST On Payment Gateway %
                  </label>

                  <InputTextBox
                    id="gstPgPercent"
                    name="gstPgPercent"
                    type="number"
                    maxLength={2}
                    value={inputValue.gstPgPercent}
                    onChange={handleChange}
                    isValid={inputValue?.IsValid?.gstPgPercent}
                    errorMsg={inputValue?.errors?.gstPgPercent}
                    disabled={selectedUserId === "0" ? false : true}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Host Commission
                  </label>
                  <InputTextBox
                    id="hostCommission"
                    name="hostCommission"
                    type="number"
                    value={inputValue.hostCommission}
                    onChange={handleChange}
                    isValid={inputValue?.IsValid?.hostCommission}
                    errorMsg={inputValue?.errors?.hostCommission}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    GST Host (Platform) %
                  </label>
                  <InputTextBox
                    id="gstHostPercent"
                    name="gstHostPercent"
                    type="number"
                    maxLength={2}
                    value={inputValue.gstHostPercent}
                    onChange={handleChange}
                    isValid={inputValue?.IsValid?.gstHostPercent}
                    errorMsg={inputValue?.errors?.gstHostPercent}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Platform Fees Type<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select p-2"
                    aria-label="Default select example"
                    value={inputValue.platformFeesType}
                    onChange={handleChange}
                    name="platformFeesType"
                    defaultValue="Select Platform Fees Type"
                  >
                    <option value="Select Platform Fees Type">
                      Select Platform Fees Type
                    </option>
                    <option value="Amount">Amount</option>
                    <option value="Percent">Percent</option>
                  </select>
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Platform Fees
                  </label>
                  <InputTextBox
                    id="platformFees"
                    name="platformFees"
                    type="number"
                    placeholder="Platform fees (Amount/ Percent)"
                    value={inputValue.platformFees}
                    maxLength={5}
                    onChange={handleChange}
                    isValid={inputValue?.IsValid?.platformFees}
                    errorMsg={inputValue?.errors?.platformFees}
                  />
                </div>
              </form>

              <div className="d-flex mb-10">
                <Button type="button" dark onClick={handleUpdate}>
                  Update Details
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
      )}
    </div>
  );
};

export default MoatConfigCard;
