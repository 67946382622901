import {
  useFetchGetRequestWithToken,
  useFetchPostRequestWithToken,
} from "./api";

export const GetAllOrganizer = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-organizers`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetOrganizerDetail = async (userId, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/get-profile/${userId}`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const RejectOraganizer = async (organizerData, inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/reject-org`;

  const reqBody = JSON.stringify({
    userId: organizerData.userId,
    rejectionReason: inputValue.rejectionReason,
  });

  return useFetchPostRequestWithToken(apiURL, reqBody, token);
};

export const ApproveOrganizer = async (userId, tdsPercentage, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/approve-org`;
  const reqBody = JSON.stringify({
    userId: userId,
    tdsPercent: parseFloat(tdsPercentage),
  });
  return useFetchPostRequestWithToken(apiURL, reqBody, token);
};
