import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { signInUsingIDP } from "../../service/firebaseLogin";
import { IsEmpty } from "../../util/validations";
import * as Call from "../../util/validations";
import * as Common from "../../util/commonFun";
import { LOCAL_STORAGE_USER } from "../../util/Constants";
import InputTextBox from "../../../src/components/controls/input/InputTextBox";
import { addToLoginRequest } from "../../redux/actions/Login";
import { LogIn } from "../../service/userAPI";
import Spinner from "../../components/controls/spinner/Spinner";

//main function
const LogInPage = ({ login, saveLogin }) => {
  //declare start
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);//spinner
  //declare end

  //function/events/methods
  const checkEmail = (e) => {
    setUsername(e.target.value);
    if (Call.IsValidEmail(e.target.value)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const checkPassword = (e) => {
    setPassword(e.target.value);
    if (Call.IsEmpty(e.target.value)) {
      setIsPassword(false);
    } else {
      setIsPassword(true);
    }
  };

  useEffect(() => {
    if (!IsEmpty(Common.getLocalData(LOCAL_STORAGE_USER))) {
      navigate("/dashboard");
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isValidEmail && isPassword) {

      let response = await signInUsingIDP(username, password);
      setLoading(false);
      if (!IsEmpty(response?.user)) {
        const loginResponse = await LogIn(response?.user.accessToken);
        if (loginResponse.success) {
          const loginData = JSON.stringify(loginResponse.data);
          Common.saveToLocal(LOCAL_STORAGE_USER, loginData);
          saveLogin(loginData);
          navigate("/dashboard");
        } else {
          alert(loginResponse.message);
        }
      } else {
        alert(response?.message);
      }
    }
  };

  //main return
  return (
    <>
      <div className="row justify-content-center align-items-center">
        <div className="col p-0">
          <div className="login-hero">
            <div className="title-line">
              {/* <div className="logo">Logo Here</div> */}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="login-form">
              <h1 className="mb-5 text-center">Login</h1>
              <form
                className="row g-3"
                // onSubmit={handleSubmit}
              >
                <div className="col-12">
                  <label htmlFor="validationServer01" className="form-label">
                    User Name
                  </label>
                  <InputTextBox
                    id="text"
                    name="email"
                    placeholder="abc@xyz.com"
                    required=""
                    value={username}
                    maxLength={40}
                    // isValid={isValid?.cityName}
                    // errorMsg={errors?.cityName}
                    onChange={checkEmail}
                  />
                </div>
                <div className="col-12">
                  <label
                    htmlFor="validationServerUsername"
                    className="form-label"
                  >
                    Password
                  </label>
                  <div className="input-group has-validation">
                    <InputTextBox
                      name="password"
                      type="password"
                      //  className="form-control is-invalid"
                      id="validationServerUsername"
                      aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback"
                      required=""
                      placeholder="Password"
                      value={password}
                      onChange={checkPassword}
                    />
                  </div>
                </div>
                <div className="col-6">
                  {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="invalidCheck3"
                      aria-describedby="invalidCheck3Feedback"
                      required=""
                    />
                    <label className="form-check-label" htmlFor="invalidCheck3">
                      Remember Me
                    </label>
                  </div> */}
                </div>
                <div className="col-6">
                  <div className="form-check text-end">
                    <a href="/forgot-password">Forgot Password?</a>
                  </div>
                </div>
                <div className="col-12">
                  {isValidEmail && isPassword ? (
                    <button
                      className="btn btn-primary login-btn w-100"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={false}
                    >
                      Login
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary login-btn w-100"
                      type="submit"
                      disabled={true}
                    >
                      Login
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="copy-right w-50">
            Copyright © 2010-2024 company All rights reserved.
          </div>
        </div>
      </div>
      {loading && <Spinner/>}
    </>
  );
};

const mapStateToProps = (state) => ({
  login: state.login.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  saveLogin: (login) => dispatch(addToLoginRequest(login)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogInPage);
//export default LogInPage;
