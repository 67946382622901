import {
  useFetchPostRequestWithToken,
  useFetchGetRequestWithToken,
} from "./api";

export const ReSendInvoice = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/resend-invoice`;
  const body = JSON.stringify({
    // userData: {
    eventId: inputValue.eventId,
    orderConfirmationId: inputValue.orderConfirmationId,
    participantId: inputValue.participantId,
    fullName: inputValue.fullName,
    email: inputValue.email,
    mobile: inputValue.mobile,
    remark: inputValue.remark,
    // },
  });

  const result = await useFetchPostRequestWithToken(apiURL, body, token);
  return result;
};

export const GetMyNotifications = async (token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/get-notifications`;
  return await useFetchGetRequestWithToken(apiURL, token);
};
