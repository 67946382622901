import React from 'react';
import InputTextBox from '../../controls/input/InputTextBox';

//main function
const OrganizerBasicDetails = ({organizer}) => {

   //main retrun
  return (
    <>
      <div className="card-body p-1">
      <div className="accordion accordion-flush m-0" id="accordionFlushExample">
        <div className="accordion-item">
          {/* <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#participant-collapseOne"
              aria-expanded="false"
              aria-controls="participant-collapseOne"
            >
              <div className="d-flex justify-content-center align-items-center accordion-detail">
                <h1>Basic Profile</h1>
              </div>
            </button>
          </h2> */}
          <div
            id="participant-collapseOne"
            className="accordion-collapse accordion-detail px-4 collapse show"
            data-bs-parent="#accordionFlushExample"
          >
            <form className="row g-3">
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Business Email
                </label>

                <InputTextBox
                  id="eventName"
                  name="eventName"
                  value={organizer.businessEmail}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Business Mobile
                </label>

                <InputTextBox
                  id="eventName"
                  name="eventName"
                  value={organizer.businessMobile}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Business Address
                </label>
                <InputTextBox
                  id="businessAddress"
                  name="businessAddress"
                  value={organizer.businessAddress}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Business Country
                </label>
                <InputTextBox
                  id="businessCountry"
                  name="businessCountry"
                  value={organizer.businessCountry}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Business State
                </label>
                <InputTextBox
                  id="businessState"
                  name="businessState"
                  value={organizer.businessState}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Business City
                </label>
                <InputTextBox
                  id="businessCity"
                  name="businessCity"
                  value={organizer.businessCity}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Pin Code
                </label>
                <InputTextBox
                  id="businessPincode"
                  name="businessPincode"
                  value={organizer.businessPincode}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label"></label>

                <a href={organizer.businessWebsite} target="_blank">
                  {organizer.businessName}
                </a>
              </div>
            </form>
            {/* <div className="my-4 text-center">
              <button type="submit" className="btn btn-select w-100">
                Save &amp; Proceed
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default OrganizerBasicDetails;
