import React, { useState, useEffect } from "react";

//
/* const config1 = {
  isUserLogin: false,
  loginMessage: "Please login ",
  data: [
    {
      title: "Tab 1",
      tabBtnClass: "tab col btn org-btn-pro border",
      tabContentClass: "",
      tabContent: " tabContent 1",
    },
    {
      title: "Tab 2",
      tabBtnClass: "tab col btn org-btn-pro border",
      tabContentClass: "",
      tabContent: " tabContent 2",
      showLogin: true,
    },
    {
      title: "Tab 3",
      tabBtnClass: "tab col btn org-btn-pro border",
      tabContentClass: "",
      tabContent: " tabContent 3",
      showLogin: true,
    },
    {
      title: "Tab 4",
      tabBtnClass: "tab col btn org-btn-pro border",
      tabContentClass: "",
      tabContent: " tabContent 4",
      showLogin: true,
    },
  ],
  className: {
    mainContainer: "tabs-container row align-items-start  p-0 m-0",
    tabsContainer: "tabs btn-group",
    activeTabContainer: "tab-content card p-4",
  },
}; */
//
const Tabs = ({ config, ...rest }) => {
  let tabs = config?.data;
  //tabs = config1?.data;
  const [activeTab, setActiveTab] = useState(config?.stepCompleted || 0);
  useEffect(() => {
    if (rest?.index > 0) {
      handleTabClick(rest?.index);
    }
  }, [rest?.index]);
  //
  const handleTabClick = (index) => {
    setActiveTab(() => index);
  };
  //debugger;
  //
  return (
    <>
      <div className={config?.className?.mainContainer}>
        <div className={config?.className?.tabsContainer}>
          {tabs.map((tab, index) => (
            <Tab
              tabId={config?.tabId + index}
              key={index}
              title={tab.title}
              className={tab.tabBtnClass}
              showStep={tab?.showStep}
              index={index}
              stepCompleted={config?.stepCompleted}
              onClick={() => handleTabClick(index)}
              isActive={index === activeTab}
              style={index === activeTab ? { backgroundColor: 'red', color: 'white' } : {}}
            />
          ))}
        </div>

        <div className={config?.className?.activeTabContainer}>
          {tabs.map((tab, index) => {
            if (index === activeTab) {
              if (tab?.showLogin === true && config?.isUserLogin === false) {
                //show login msg
                return <div>{config?.loginMessage}</div>;
              }
              return (
                <TabContent
                  key={index}
                  title={tab.title}
                  className={tab.tabContentClass}
                  tabContent={tab.tabContent}
                  isActive={index === activeTab}
                />
              );
            } else {
              return undefined;
            }
          })}
        </div>
      </div>
    </>
  );
};

const Tab = ({
  tabId,
  title,
  showStep,
  index,
  className,
  onClick,
  isActive,
  stepCompleted,
}) => (
  <div
    id={tabId}
    className={` ${className} ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    {showStep && (
      <span
        class={`border me-2 ${
          index + 1 <= stepCompleted ? " completed " : ""
        } ${isActive ? "active green-b" : ""}`}
      >
        {index + 1}
      </span>
    )}
    {title}
  </div>
);

const TabContent = ({ tabContent, className, isActive }) => (
  <div className={` ${className}  ${isActive ? "active" : ""}`}>
    {tabContent}
  </div>
);

export default Tabs;
