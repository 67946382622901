import React from 'react';
import NotificationCard from '../../components/notification/NotificationCard';

//main function
const NotiFicationPage = () =>{
    //main return
    return (
        <>
        <NotificationCard/>
        </>
    );
};
export default NotiFicationPage;