import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Common from "../../util/commonFun";
import { LOCAL_STORAGE_USER } from "../../util/Constants";

const TopNavbar = () => {
  const navigate = useNavigate();
  let [notificationCount, setNotificationCount] = useState(0);
  // const [showNotification, setShowNotification] = useState(false);
  // let [notificationMsg, setNotificationMsg] = useState([]);

  const userData = JSON.parse(Common.getLocalData(LOCAL_STORAGE_USER));

  useEffect(() => {
    if (userData?.user) {
      const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/connect-sse`;
      const eventSource = new EventSource(
        `${apiURL}?token=Bearer ${userData?.token}`
      );

      eventSource.onmessage = (event) => {
        // const notification = JSON.parse(event.data);

        notificationCount = notificationCount + 1;
        // setNotificationCount(notificationCount);
        // setNotificationMsg((notificationMsg) => [
        //   ...notificationMsg,
        //   notification,
        // ]);
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
      };

      return () => {
        eventSource.close(); // Close the SSE connection when component unmounts
      };
    }
  }, [userData?.user]);

  // const displayNotificationList = () => {
  //   if (userData?.user) {
  //     setShowNotification(() => !showNotification);
  //   }
  // };

  // const markReadNotifications = () => {
  //   displayNotificationList();
  //   setNotificationMsg([]);
  //   setNotificationCount(0);
  // };

  const navigateToNotification = () => {
    navigate("/notification");
  };

  //main retrun
  return (
    <>
      <div className="col-12 topnavbar py-2">
        <div className="float-end">
          <div className="d-flex flex-row">
            <a href="#home" className="event">
              <div className="p-2">
                <div className="bell-icon-container" onClick={navigateToNotification}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 23 27"
                    fill="none"
                    className="topnavbar-icon"
                  >
                    <path
                      d="M15.1606 21.0587V21.9893C15.1606 22.9765 14.7684 23.9233 14.0703 24.6214C13.3722 25.3195 12.4254 25.7116 11.4382 25.7116C10.451 25.7116 9.5042 25.3195 8.80612 24.6214C8.10804 23.9233 7.71587 22.9765 7.71587 21.9893V21.0587M21.4234 19.1644C19.9298 17.3363 18.8754 16.4058 18.8754 11.366C18.8754 6.75091 16.5186 5.10668 14.579 4.30812C14.3213 4.20227 14.0788 3.95915 14.0002 3.69451C13.66 2.53651 12.7061 1.51636 11.4382 1.51636C10.1703 1.51636 9.21586 2.5371 8.8791 3.69568C8.80059 3.96322 8.55805 4.20227 8.3004 4.30812C6.35838 5.10784 4.00399 6.74626 4.00399 11.366C4.00108 16.4058 2.94661 17.3363 1.45302 19.1644C0.834176 19.9216 1.37624 21.0587 2.45863 21.0587H20.4236C21.5002 21.0587 22.0388 19.9181 21.4234 19.1644Z"
                      stroke="#454F5B"
                      strokeWidth="1.85938"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {notificationCount > 0 && (
                    <div className="notification-count">
                      {notificationCount}
                    </div>
                  )}

                  {/* {showNotification && notificationCount > 0 && (
                    <>
                      <div
                        className="custom-dropdown"
                        style={{
                          marginTop: "2px",
                          marginLeft: "-120px",
                        }}
                      >
                        <div class="dropdown-menu dropdown-menu-right show caret">
                          {notificationMsg &&
                            notificationMsg.map &&
                            notificationMsg.map((msgIs, msgIndex) => {
                              return (
                                <>
                                  <div
                                    className="sidemenuicon dropdown-item"
                                    key={msgIndex}
                                  >
                                    <Link
                                      to="/notification"
                                      onClick={() => markReadNotifications()}
                                    >
                                      {msgIs}
                                    </Link>
                                  </div>
                                  <div class="dropdown-divider" />
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </a>
            {/* <a href="#home" className="event">
              <div className="p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 31 32"
                  fill="none"
                  className="topnavbar-icon-bell"
                >
                  <g clipPath="url(#clip0_1_529)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.6604 3.19775C8.62492 3.19775 2.92188 8.9008 2.92188 15.9363C2.92188 22.9718 8.62492 28.6748 15.6604 28.6748C22.6959 28.6748 28.399 22.9718 28.399 15.9363C28.399 8.9008 22.6959 3.19775 15.6604 3.19775ZM11.2019 12.7517C11.2019 12.1662 11.3173 11.5864 11.5413 11.0455C11.7654 10.5045 12.0938 10.013 12.5078 9.59903C12.9218 9.18503 13.4133 8.85662 13.9542 8.63256C14.4952 8.40849 15.0749 8.29317 15.6604 8.29317C16.2459 8.29317 16.8257 8.40849 17.3666 8.63256C17.9075 8.85662 18.399 9.18503 18.8131 9.59903C19.2271 10.013 19.5555 10.5045 19.7795 11.0455C20.0036 11.5864 20.1189 12.1662 20.1189 12.7517C20.1189 13.9341 19.6492 15.0682 18.8131 15.9043C17.9769 16.7404 16.8429 17.2102 15.6604 17.2102C14.478 17.2102 13.3439 16.7404 12.5078 15.9043C11.6717 15.0682 11.2019 13.9341 11.2019 12.7517ZM23.6322 22.2852C22.6786 23.4847 21.4664 24.4532 20.086 25.1185C18.7056 25.7838 17.1928 26.1286 15.6604 26.1271C14.1281 26.1286 12.6152 25.7838 11.2348 25.1185C9.85444 24.4532 8.6422 23.4847 7.68864 22.2852C9.75356 20.8037 12.5713 19.7579 15.6604 19.7579C18.7495 19.7579 21.5673 20.8037 23.6322 22.2852Z"
                      fill="#454F5B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_529">
                      <rect
                        width="30.5725"
                        height="30.5725"
                        fill="white"
                        transform="translate(0.375 0.650879)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNavbar;
