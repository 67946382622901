import React, { useState, useEffect } from "react";
import {
  GetParticipantsDataByID,
  GetParticipantsPaymentByID,
} from "../../service/eventAPI.jsx";
import Tabs from "../controls/Tab/Tabs";
import Button from "../controls/buttons/Button";
import Model from "../controls/popup/Modal";
import InputTextBox from "../controls/input/InputTextBox";
import InputTextArea from "../controls/input/InputTextArea";
import * as initialState from "../../service/initialState";
import { API_RESONSE_FAILED } from "../../util/Messages";
import * as Call from "../../util/validations";
import { ReSendInvoice } from "../../service/notificationAPI";
import { convertDateTimeToISTForUI } from "../../util/DateTimeUtility.jsx";
import Spinner from "../controls/spinner/Spinner.jsx";

//main function
const ViewParticipantDetails = ({ item, showPList, userData, refUserId }) => {
  //configration
  const config = {
    data: [
      {
        title: "Participant Details",
        tabBtnClass: "tab col btn org-btn-pro border p-2 mr-2",
        tabContentClass: "",
        tabContent: (
          <RenderParticipantData
            item={item}
            userData={userData}
            refUserId={refUserId}
          />
        ),
      },
      {
        title: "Payment Details",
        tabBtnClass: "tab col btn org-btn-pro border p-2",
        tabContentClass: "",
        tabContent: (
          <RenderPaymentData
            item={item}
            userData={userData}
            refUserId={refUserId}
          />
        ),
      },
    ],
  };

  //main return
  return (
    <>
      <div>
        <a
          onClick={() => showPList()}
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        >
          <h1 class="my-3 font-weight-semibold">
            <i class="bi bi-chevron-left me-2 fw-bold"></i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="15"
              viewBox="0 -960 960 960"
              width="15"
            >
              <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"></path>
            </svg>
            {item?.fullName}
          </h1>
          {/* <h1 class="font-weight-semibold">&lt;</h1> */}
        </a>
      </div>
      <Tabs config={config} />
    </>
  );
};

const RenderParticipantData = ({ item, userData, refUserId }) => {
  //variables
  const [showPData, setShowPData] = useState([]);

  //
  useEffect(() => {
    const getPData = async () => {
      try {
        const allEventsResponse = await GetParticipantsDataByID(
          userData.login.token,
          item?.participantId,
          refUserId
        );

        if (allEventsResponse?.success === true) {
          setShowPData(allEventsResponse?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPData();
  }, []);

   // Open document in new window
   const handleViewClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

    return (
    <>
      <div className="mt-1">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>Event Id</td>
              <td className="font-weight-normal">{item?.eventId}</td>
            </tr>
            <tr>
              <td>Event Name</td>
              <td className="font-weight-normal">{item?.eventName}</td>
            </tr>
            <tr>
              <td>Full Name</td>
              <td className="font-weight-bold">{item?.fullName}</td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td className="font-weight-normal">{showPData?.mobile}</td>
            </tr>
            {showPData?.dob && (
              <tr>
                <td>DOB</td>
                <td className="font-weight-normal">{showPData?.dob}</td>
              </tr>
            )}
            {showPData?.gender && (
              <tr>
                <td>Gender</td>
                <td className="font-weight-normal">{showPData?.gender}</td>
              </tr>
            )}
            {showPData?.t_shirt_size && (
              <tr>
                <td>T shirt-size</td>
                <td className="font-weight-normal">
                  {showPData?.t_shirt_size}
                </td>
              </tr>
            )}
            {showPData?.club_name && (
              <tr>
                <td>Club Name</td>
                <td className="font-weight-normal">{showPData?.club_name}</td>
              </tr>
            )}
            {showPData?.state && (
              <tr>
                <td>State</td>
                <td className="font-weight-normal">{showPData?.state}</td>
              </tr>
            )}
            {showPData?.city && (
              <tr>
                <td>City</td>
                <td className="font-weight-normal">{showPData?.city}</td>
              </tr>
            )}
            {showPData?.address && (
              <tr>
                <td>Address</td>
                <td className="font-weight-normal">{showPData?.address}</td>
              </tr>
            )}
            {/* {showPData?.pincode && (
              <tr>
                <td>Pin code</td>
                <td className="font-weight-normal">{showPData?.pincode}</td>
              </tr>
            )} */}
            {showPData?.id_proof_type && (
              <tr>
                <td>ID proof type</td>
                <td className="font-weight-normal">
                  {showPData?.id_proof_type}
                </td>
              </tr>
            )}
            {showPData?.emergency_contact_name && (
              <tr>
                <td>Emergency contact name</td>
                <td className="font-weight-normal">
                  {showPData?.emergency_contact_name}
                </td>
              </tr>
            )}
            {showPData?.emergency_contact_number && (
              <tr>
                <td>Emergency contact number</td>
                <td className="font-weight-normal">
                  {showPData?.emergency_contact_number}
                </td>
              </tr>
            )}
            {showPData?.emergency_contact_relation && (
              <tr>
                <td>Emergency contact relation</td>
                <td className="font-weight-normal">
                  {showPData?.emergency_contact_relation}
                </td>
              </tr>
            )}
            {showPData?.blood_group && (
              <tr>
                <td>Blood group</td>
                <td className="font-weight-normal">{showPData?.blood_group}</td>
              </tr>
            )}
            {showPData?.participant_photo && (
              <tr>
                <td>Participant photo</td>
                <td className="font-weight-normal">           
                  <button className="btn-link btn p-0" style={{fontSize:"14px"}}
                   onClick={() => handleViewClick(showPData?.participant_photo)}>
                    View</button>
                </td>
              </tr>
            )}
            {showPData?.id_proof && (
              <tr>
                <td>Id proof</td>
                <td className="font-weight-normal">
                <button className="btn-link btn p-0" style={{fontSize:"14px"}}
                   onClick={() => handleViewClick(showPData?.id_proof)}>
                    View</button>
                    </td>
              </tr>
            )}
            {showPData?.timing_certificate && (
              <tr>
                <td>Timing certificate</td>
                <td className="font-weight-normal">                 
                  <button className="btn-link btn p-0" style={{fontSize:"14px"}}
                   onClick={() => handleViewClick(showPData?.timing_certificate)}>
                    View</button>
                </td>
              </tr>
            )}
            {showPData?.fitness_certificate && (
              <tr>
                <td>Fitness certificate</td>
                <td className="font-weight-normal">
                   <button className="btn-link btn p-0" style={{fontSize:"14px"}}
                   onClick={() => handleViewClick(showPData?.fitness_certificate)}>
                    View</button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const RenderPaymentData = ({ item, userData, refUserId }) => {
  //variables
  const initialData = {
    fullName: item?.fullName, //"",
    email: item?.email, //"",
    mobile: item?.mobile, //"",
    remark: "",
    errors: {},
    IsValid: { fullName: true, email: true, mobile: true },
  };
  const [showPData, setShowPData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [inputValue, setInputValue] = useState(initialData);
  const [validationSummary, setValidationSummary] = useState("");
  const [loading, setLoading] = useState(false); // Add spinner
  //
  useEffect(() => {
    const getPData = async () => {
      try {
        const allEventsResponse = await GetParticipantsPaymentByID(
          userData.login.token,
          item?.eventId,
          item?.participantId,
          item?.txnId,
          item?.order_confirmation_id,
          refUserId
        );

        if (allEventsResponse?.success === true) {
          setShowPData(allEventsResponse?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPData();
  }, []);
  const handlePopup = (value) => {
    setShowPopup(() => value);
  };

  const handleChange = (e) => {
    debugger;
    //get Value
    const { name, value } = e.target;
    //check validation
    let result = isValid(name, value, e);
    if (result.name2 !== "") {
      setInputValue((prev) => ({
        ...prev,
        [result.name]: result.value === "" ? "" : result.value,
        [result.name2]: result.value2 === "" ? "" : result.value2,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    } else {
      //setInputValue
      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    }
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    let name2 = "";
    let value2 = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter valid " + name;
    } else {
      switch (name) {
        case "fullName":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Please enter valid full name.";
          break;

        case "email":
          {
            if (Call.IsEmpty(value)) {
              msg = "Please enter email.";
            } else {
              val = Call.IsValidEmail(value);
              if (!val) msg = "Please enter valid email. " + value;
            }
          }
          break;

        case "mobile":
          {
            if (Call.IsEmpty(value)) {
              msg = "Please enter mobile.";
            } else {
              val = Call.IsValidMobileNo(value);
              if (!val) msg = "Please enter 10 digit mobile number. " + value;
            }
          }
          break;
        case "remark":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Please enter valid remark.";
          break;

        default:
          break;
      }
    }

    return {
      status: val,
      message: msg,
      name: name,
      value: value,
      name2: name2,
      value2: value2,
    };
  };

  const handleResendInvoice = async (e, item, showPData, userData) => {
    e.preventDefault();
    if (
      inputValue?.IsValid &&
      inputValue?.IsValid?.fullName &&
      inputValue?.IsValid?.mobile &&
      inputValue?.IsValid?.email &&
      inputValue?.IsValid?.remark
    ) {
      setLoading(true); // Start spinner
      //post data logic
      let postValue = inputValue;
      let skipKey = initialState.skipKey;
      for (let key in postValue) {
        try {
          if (skipKey.includes(key)) {
            delete postValue[key];
          } else {
            postValue[key] = inputValue[key];
          }
        } catch {}
      }
      postValue.eventId = item?.eventId;
      // postValue.txnId = showPData?.txn_id;
      // postValue.orderId = showPData?.order_confirmation_id;
      postValue.orderConfirmationId = item?.order_confirmation_id;
      postValue.participantId = item?.participantId;

      //send data to server
      let result;
      if (userData) {
        postValue.userId = userData?.login?.user?.userId;
        const token = userData?.login?.token;
        result = await ReSendInvoice(postValue, token);
      }
      setLoading(false); // Stop spinner
      //next step
      if (result) {
        if (result?.success) {
          alert(result?.message);
          handlePopup(false);
          resetField();
        } else {
          alert("Error:-" + result.message);
          setLoading(false); // Stop spinner
          resetField();
        }
      } else {
        alert(API_RESONSE_FAILED);
        setLoading(false); // Stop spinner
        resetField();
      }
    } else {
      let displaMsg = "";
      let mParam = { fullName: true, email: true, mobile: true, remark: true };
      for (let key in mParam) {
        if (!inputValue?.IsValid[key]) {
          displaMsg += "<li>" + key + "</li>";
        }
      }
      displaMsg =
        "<b>Please enter all required fields</b><ul>" + displaMsg + "</ul>";
      setValidationSummary(() => displaMsg);
    }
  };

  //reset field
    const resetField = () => {
      setInputValue((prev) => ({
        ...prev,
        remark: ""
      }));
    };

  //
  return (
    <>
      <div className="mt-1">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>Event Id</td>
              <td className="font-weight-normal">{item?.eventId}</td>
            </tr>
            <tr>
              <td>Event Name</td>
              <td className="font-weight-normal">{item?.eventName}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td className="font-weight-bold">
              {Number(showPData?.amount).toLocaleString("en-US", {                      
                      maximumFractionDigits: 2,
                    })}
                </td>
            </tr>
            <tr>
              <td>Payee Name</td>
              <td className="font-weight-normal">{showPData?.fullName}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td className="font-weight-bold">{showPData?.email}</td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td className="font-weight-normal">{showPData?.mobile}</td>
            </tr>
            <tr>
              <td>Payment Mode</td>
              <td className="font-weight-normal">{showPData?.paymentMode}</td>
            </tr>
            <tr>
              <td>Transaction Id</td>
              <td className="font-weight-normal">{showPData?.txnId}</td>
            </tr>
            <tr>
              <td>Payment Date</td>
              <td className="font-weight-normal">
                {convertDateTimeToISTForUI(showPData?.orderDate)}
              </td>
            </tr>
            <tr>
              <td>Payment Status</td>
              <td className="font-weight-normal">Success</td>
            </tr>
            <tr>
              <td>Ticket and Payment Reciept</td>
              <td className="font-weight-normal">
                <Button className=" btn btn-select w-100" style={{height:"50px"}} onClick={() => handlePopup(true)}>Re-Send</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/*pop up code */}
      {showPopup === true && (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Model
                onClose={() => handlePopup(false)}
                title={`Invoice-${showPData?.txnId}`}
              >
                <div class="alert alert-warning" role="alert">
                  Please re-enter correct information and add proper remark
                </div>
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>Event Name</td>
                      <td className="font-weight-normal">{item?.eventName}</td>
                    </tr>
                    <tr>
                      <td>Participant Name</td>
                      <td className="font-weight-normal">
                        {showPData?.fullName} <br />
                        <InputTextBox
                          id="fullName"
                          name="fullName"
                          placeholder="fullName"
                          type="text"
                          isValid={inputValue?.IsValid?.fullName}
                          errorMsg={inputValue?.errors?.fullName}
                          value={inputValue.fullName}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td className="font-weight-bold">
                        {showPData?.email}
                        <br />
                        <InputTextBox
                          id="email"
                          name="email"
                          placeholder="email"
                          type="text"
                          isValid={inputValue?.IsValid?.email}
                          errorMsg={inputValue?.errors?.email}
                          value={inputValue.email}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td className="font-weight-normal">
                        {showPData?.mobile}
                        <br />
                        <div class="input-group">
                          <InputTextBox
                            id="mobile"
                            name="mobile"
                            placeholder="mobile"
                            type="text"
                            className="numberWithoutArrowkey"
                            isValid={inputValue?.IsValid?.mobile}
                            errorMsg={inputValue?.errors?.mobile}
                            value={inputValue.mobile}
                            onChange={handleChange}
                            maxLenght={10}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Remark</td>
                      <td className="font-weight-normal">
                        <InputTextArea
                          id="remark"
                          name="remark"
                          placeholder="remark"
                          type="text"
                          isValid={inputValue?.IsValid?.remark}
                          errorMsg={inputValue?.errors?.remark}
                          value={inputValue.remark}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="font-weight-normal">
                        <Button className=" btn btn-select w-100" style={{height:"50px"}}
                          onClick={(e) =>
                            handleResendInvoice(e, item, showPData, userData)
                          }
                        >
                          Re-Send Invoice
                        </Button>
                        <br />
                        {validationSummary !== "" && (
                          <div
                            class="alert alert-danger"
                            role="alert"
                            dangerouslySetInnerHTML={{
                              __html: validationSummary,
                            }}
                          ></div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Model>
            </>
          )}
        </>
      )}
    </>
  );
};
export default ViewParticipantDetails;
