import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetEventByEventId } from "../../../service/dashboardAPI";
import EventBasicDetails from "./EventBasicDetails";
import DiscountDetails from "./DiscountDetails";
import { FOLDER_EVENT_PHOTO } from "../../../util/Constants";
import EventSetting from "./EventSetting";
import ParticipantFormDetails from "./ParticipantFormDetails";
import ParticipantDetails from "./ParticipantDetails";
import AccordionCard from "../../controls/accordion/AccordionCard";
import Spinner from "../../controls/spinner/Spinner";

const EventDetailsPage = () => {
  let ImagePath = process.env.REACT_APP_EVENTS;
  const { eventId, eventName } = useParams();
  const navigate = useNavigate(); // add useNavigate

  //declare start
  const [eventdata, setEventData] = useState([]);
  const [activeTabNo, setActiveTabNo] = useState(-1);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(true); // loading state
  const userData = useSelector((state) => state.login.login.login);
  //declare end

  //function/events/methods
  useEffect(() => {
    const fetchEventData = async () => {
      if (userData.token) {
        const response = await GetEventByEventId(eventId, userData.token);
        if (response.success) {
          setEventData(response.data);
          setLoading(false);
        } else {
          console.error("Failed to fetch Event data");
        }
      }
    };

    fetchEventData();
  }, []); // Re-fetch organizer data when orgId changes

  //for accordion
  const handleSave = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };
  const handleSkip = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };

  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Event Details</h1>
          </div>
        ),
        content: <EventBasicDetails event={eventdata} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Participant Form Details</h1>
          </div>
        ),
        content: <ParticipantFormDetails event={eventdata} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Discount Details</h1>
          </div>
        ),
        content: <DiscountDetails event={eventdata} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Event Setting Details</h1>
          </div>
        ),
        content: <EventSetting event={eventdata} />,
      },
      // {
      //   title: (
      //     <div className="d-flex justify-content-center align-items-center accordion-detail">
      //       <h1>Participant Details</h1>
      //     </div>
      //   ),
      //   content: <ParticipantDetails event={eventdata} />,
      // },
    ],
  };

  //slider
  const nextPhoto = () => {
    setCurrentPhotoIndex((prevIndex) =>
      prevIndex === eventdata.eventPhotos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevPhoto = () => {
    setCurrentPhotoIndex((prevIndex) =>
      prevIndex === 0 ? eventdata.eventPhotos.length - 1 : prevIndex - 1
    );
  };

  // Automatic photo slide logic
  useEffect(() => {
    if (eventdata.eventPhotos && eventdata.eventPhotos.length > 1) {
      const intervalId = setInterval(() => {
        setCurrentPhotoIndex((prevIndex) =>
          prevIndex === eventdata.eventPhotos.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000); // Change photo every 5 second

      return () => clearInterval(intervalId); // Clean up the interval when component unmounts
    }
  }, [eventdata.eventPhotos]); // Re-run effect if event photos change

  // main return
  return (
    <>
      {loading ? (
        <Spinner /> // Show spinner while loading
      ) : (
        <>
          <div className="col-4">
            <Link
              to="#"
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
            >
              <h3 className="my-3 ml-3 d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 -960 960 960"
                  width="20"
                >
                  <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
                </svg>
                <span className="font-weight-semibold">
                  {eventdata.eventName}
                </span>
              </h3>
            </Link>
          </div>

          <div className="col-12 main-contian org-kyc">
            {/* Page Name */}
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="my-3">
                <i className="bi bi-chevron-left fw-bold" />
              </h3>
              <div className="col-sm-12 col-md-8 col-xl-12 org-main-contain my-3 card mb-2">
                <div className="card p-0 rounded">
                  <div className="card-body p-0 rounded">
                    <div className="my-profile-banner rounded">
                      <div className="d-flex">
                        <div className="p-2">
                          {/* {(eventdata.eventPhotos != null && eventdata?.eventPhotos?.size > 0) ? ( 
                  eventdata?.eventPhotos[0]?.docName || [] */}

                          {/* {eventdata.eventPhotos != null ? (
                        <div>
                          <img
                            loading="lazy"
                            src={
                              // renderDoc(
                              ImagePath +
                                eventdata?.eventId +
                                FOLDER_EVENT_PHOTO +
                                eventdata?.eventPhotos[0].docName || []

                              //   )
                            }
                            width={100}
                            height={100}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            loading="lazy"
                            src={
                              "No"
                              //     // renderDoc(
                              //          ImagePath +
                              //  eventdata?.userId +
                              //  FOLDER_EVENT_PHOTO +
                              //  eventdata?.eventPhotos[0].docName || []

                              //     //   )
                            }
                            width={100}
                            height={100}
                          />
                        </div>
                      )} */}
                          <div
                            id="carouselExampleAutoplaying"
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-inner">
                              <div
                                className="carousel-item active "
                                data-bs-interval="1000"
                              >
                                {eventdata.eventPhotos != null &&
                                  eventdata.eventPhotos.length > 0 && (
                                    <img
                                      className="d-block w-100 rounded "
                                      loading="lazy"
                                      src={
                                        ImagePath +
                                        eventdata?.eventId +
                                        FOLDER_EVENT_PHOTO +
                                        eventdata.eventPhotos[currentPhotoIndex]
                                          .docName
                                      }
                                      width={100}
                                      height={100}
                                    />
                                  )}
                              </div>
                            </div>
                            <button
                              className="carousel-control-prev btn btn-link border pre-btn-bg pre-btn[object Object]"
                              type="button"
                              data-bs-target="#carouselExampleAutoplaying"
                              data-bs-slide="prev"
                              onClick={prevPhoto}
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next btn btn-link border pre-btn-bg pre-btn[object Object] "
                              type="button"
                              data-bs-target="#carouselExampleAutoplaying"
                              data-bs-slide="next"
                              onClick={nextPhoto}
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>

                          {/* <img
                          loading="lazy"
                          src={ImagePath +
                            eventdata?.eventId +
                            FOLDER_EVENT_PHOTO + eventdata.eventPhotos[currentPhotoIndex].docName}
                          width={100}
                          height={100}
                        /> */}
                        </div>
                        <div className="p-2 flex-grow-1 mt-3">
                          <h1>{eventdata.eventName}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AccordionCard config={config} />
          </div>
        </>
      )}
    </>
  );
};

export default EventDetailsPage;
