import React from "react";
import { Link, useParams } from "react-router-dom";

//main function
const PaymentBreakup = () => {
  const { eventId } = useParams();

  //main return
  return (
    <>
      <div className="col">
        <Link
          to="/totalTicketsSales"
          style={{ textDecoration: "none", color: "black" }}
        >
          <h3 className="my-3 ml-3 d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 -960 960 960"
              width="20"
            >
              <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
            </svg>
          <div>  <h3 className="my-3">
              <i className=" me-2 fw-bold"></i>Sales - Total Tickets
              Sales/EventId: {eventId}
            </h3>
            </div>
            {/* <Icon iconName={item.icon} />  */}
          </h3>
        </Link>
      </div>

      <div className="accordion" id="accordionExample">
        <div className="accordion-item my-3 border rounded-2">
          <h2 className="accordion-header">
            {/* <button
        className="accordion-button rounded-2 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      > */}
            <div className="d-flex justify-content align-items-center accordion-detail alert alert-secondary">
              <h1> Payment Details</h1>
            </div>

            {/* </button> */}
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="rounded-2 border mb-4">
                <table className="table p-0 m-0">
                  <tbody>
                    <tr>
                      <td className="col-4 border-end border-bottom p-3">
                        Breakup
                      </td>
                      <td className="col-8 border-bottom p-3">
                        <b>Sales(INR)</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-4 border-end border-bottom p-3">
                        Total Sales
                      </td>
                      <td className="col-8 border-bottom p-3">
                        <b>₹1000</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-4 border-end border-bottom p-3">
                        Payment Received from PG
                      </td>
                      <td className="col-8 border-bottom p-3">
                        <b>- 50.00</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-4 border-end border-bottom p-3">
                        Payment Due frpm PG
                      </td>
                      <td className="col-8 border-bottom p-3">
                        <b>- 35.00</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-4 border-end border-bottom p-3">
                        PG Tax
                      </td>
                      <td className="col-8 border-bottom p-3">
                        <b>- 15.00</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="col-4 border-end border-bottom p-3">
                        <b>Total Amount</b>
                      </td>
                      <td className="col-8 border-bottom p-3">
                        <b>₹900.00</b>
                      </td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentBreakup;
