import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as validator from "../../../util/validations";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import Modal from "../../controls/popup/Modal";
import { CreateNewUser } from "../../../service/userAPI";

//main function
const AddNewUser = ({ onUserAdded, setLoadUser }) => {
  //declare start
  const [showModal, setShowModal] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const userData = useSelector((state) => state.login.login.login);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    digit: false,
    lowercase: false,
    uppercase: false,
    special: false,
  });

  //validition and input value
  let initialData = { email: "", password: "", errors: {}, isValid: {} };
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid } = inputValue;

  //declare end

  //functions/events/methods
  const submitUserForm = async (event) => {
    event.preventDefault();

    if (!inputValue.isValid.email || !inputValue.isValid.password) {
      setResponseMsg("Please Enter required fields !!");
      removeErrorMsg();
      return;
    }
    try {
      if (userData?.token) {
        let addNewUserResponse = await CreateNewUser(
          inputValue,
          userData.token
        );
        if (addNewUserResponse?.success) {
          setShowModal(false);
          // Add the new user to the state directly
          setLoadUser((prevUsers) => [...prevUsers, addNewUserResponse?.data]);
          // Call the callback function passed from the parent component to inform the user addition
          if (typeof onUserAdded === "function") {
            onUserAdded();
          }
          alert(addNewUserResponse.message);
          // Reset the form fields
          resetUserFields();
          // onUserAdded(event);
        } else if (addNewUserResponse?.error) {
          resetUserFields();
          setResponseMsg(addNewUserResponse.message);
          removeErrorMsg();
        } else {
          setResponseMsg(addNewUserResponse.message);
          removeErrorMsg();
        }
      }
    } catch (error) {
      console.error("Error registration user", error);
    }
  };

  const resetUserFields = () => {
    setInputValue({
      email: "",
      password: "",
      errors: {},
      isValid: {},
    });
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setResponseMsg("");
    }, 5000);
  };

  //checking validation
  const handleChange = (event) => {
    const { name, value } = event.target;
    //validation
    let result = IsValid(name, value);
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));

    // Update password requirements
    updatePasswordRequirements(value);
  };

  const IsValid = (name, value) => {
    let status = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "email":
          status = validator.IsValidEmail(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
        case "password":
          status = validator.IsValidPassword(value);
          if (!status) {
            // msg = "Invalid " + value; //password will not shown
          }
          break;
        default:
      }
    }

    return { status: status, message: msg };
  };

  // Function to update password requirements
  const updatePasswordRequirements = (password) => {
    setPasswordRequirements({
      length: password.length >= 8,
      digit: /\d/.test(password),
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      special: /[@#$%&]/.test(password),
    });
  };

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  //main return
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          dark
          onClick={handleClick}
          style={{
            marginTop: "-35px",
          }}
        >
          Add New Admin
        </Button>
      </div>

      {showModal && (
        <>
          <Modal
            title="Add Admin"
            onClose={handleClose}
            onSuccess={submitUserForm}
            cancleText="Cancel"
            successText="Add Admin"
          >
            <div className="modal-body manage-category-modal">
              {responseMsg && (
                <p className="alert alert-danger">{responseMsg}</p>
              )}
              <form action="" method="post">
                {/* <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Type of User
                </label>
                <div class="form-check form-switch" href="#">
                <select
            className="form-select "
            aria-label="Default select example"
            // value={selectedUserId}
            // onChange={handleOnUserChange}
          >
            <option value="">Select Type</option>
            <option value="1">Super Admin</option>
            <option value="2">Admin</option>
            <option value="3">User</option>
            <option value="4">Organizer</option>
          </select>
             </div>
              </div> */}
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email Id
                  </label>
                  <div class="form-check form-switch" href="#">
                    <InputTextBox
                      id="email"
                      name="email"
                      placeholder="Please Enter Email Id"
                      isValid={inputValue?.isValid?.email}
                      errorMsg={inputValue?.errors?.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <div class="form-check form-switch" href="#">
                    <InputTextBox
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Please Enter password"
                      isValid={inputValue?.isValid?.password}
                      errorMsg={inputValue?.errors?.password}
                      onChange={handleChange}
                    />
                    <div className="password-requirements">
                      <p>
                        At least 8 characters long:{" "}
                        {passwordRequirements.length ? (
                          <span style={{ color: "green" }}>✓</span>
                        ) : (
                          <span style={{ color: "red" }}>✗</span>
                        )}
                      </p>
                      <p>
                        Contains at least one digit:{" "}
                        {passwordRequirements.digit ? (
                          <span style={{ color: "green" }}>✓</span>
                        ) : (
                          <span style={{ color: "red" }}>✗</span>
                        )}
                      </p>
                      <p>
                        Contains at least one lowercase letter:{" "}
                        {passwordRequirements.lowercase ? (
                          <span style={{ color: "green" }}>✓</span>
                        ) : (
                          <span style={{ color: "red" }}>✗</span>
                        )}
                      </p>
                      <p>
                        Contains at least one uppercase letter:{" "}
                        {passwordRequirements.uppercase ? (
                          <span style={{ color: "green" }}>✓</span>
                        ) : (
                          <span style={{ color: "red" }}>✗</span>
                        )}
                      </p>
                      <p>
                        Contains at least one special character from the set
                        @#$%&:{" "}
                        {passwordRequirements.special ? (
                          <span style={{ color: "green" }}>✓</span>
                        ) : (
                          <span style={{ color: "red" }}>✗</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddNewUser;
