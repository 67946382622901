import React, { useState } from "react";
import UpdateUserPopUp from "./UpdateUserPopUp";

//main function
const UserCard = ({ user, onUserUpdate }) => {
  //declare start
  const [showModal, setShowModal] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(user.isDisabled);
  //declare end

  //function/events/methods
  const showUpdateUserModal = () => {
    setShowModal(!showModal);
  };

  //main return
  return (
    <>
      <div className="p-2 border-bottom">
        <div
          className="row align-items-start m-2 justify-content-center align-items-center"
          onClick={() => showUpdateUserModal()}
        >
          <div className="col ml-5">
            <div className="email">
              {user.email}
              {/* ninadKatekar@gmail.com */}
            </div>
          </div>
          {/* <div className="col" >
          <p className="" style={{ width: "70%"}}>
            {user.adminUserId}
            {/* 12345 
            </p>
        </div> */}

          <div className="col">
            <div className="form-check form-switch ml-5" href="#">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={user.adminUserId}
                value={user.adminUserId}
                checked={user.isDisabled === false}
              />
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <UpdateUserPopUp
          user={user}
          onUserUpdate={onUserUpdate}
          setPopUpVisibility={(value) => setShowModal(value)}
        />
      )}
    </>
  );
};

export default UserCard;
