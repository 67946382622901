import {
  useFetchGetRequestWithToken,
  useFetchPostRequestWithToken,
} from "./api";

export const CreateNewUser = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/create-admin`;

  const reqBody = JSON.stringify({
    email: inputValue.email,
    password: inputValue.password,
    isDisabled: false,
  });

  return useFetchPostRequestWithToken(apiURL, reqBody, token);
};

export const GetAllUsers = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-admin`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const UpdateUser = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/disable-admin`;

  const reqBody = JSON.stringify({
    adminUserId: inputValue.adminUserId,
    email: inputValue.email,
    isDisabled: !inputValue.isDisabled,
  });
  return useFetchPostRequestWithToken(apiURL, reqBody, token);
};

export const LogIn = async (accessToken) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/login`;
  const result = await useFetchGetRequestWithToken(apiURL, accessToken);

  return result;
};
