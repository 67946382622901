import { combineReducers } from "redux";
import createEvent from "./createEvent";
import login from "./Login";

const rootReducer = combineReducers({
  createEvent,
  login,
});

export default rootReducer;
