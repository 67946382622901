import React, { useEffect, useState } from "react";
import SettingCard from "../../components/setting/SettingCard";
import { ADMIN_ROLE, LOCAL_STORAGE_USER } from "../../util/Constants";
import * as Common from "../../util/commonFun";

const settingCard = [
  {
    title: "Category",
    cardimg: "/images/category.jpg",
    url: "/setting/category",
  },
  {
    title: "City",
    cardimg: "/images/city.jpg",
    url: "/setting/city",
  },
  {
    title: "State",
    cardimg: "/images/state.jpg",
    url: "/setting/state",
  },
  {
    title: "Promotion Banner",
    cardimg: "/images/promotion.jpg",
    url: "/setting/promotionBanner",
  },
];

const addUser = {
  title: "Manage Admin",
  cardimg: "/images/user.jpg",
  url: "/setting/user",
};

//main function
const SettingPage = () => {
  const user = JSON.parse(Common.getLocalData(LOCAL_STORAGE_USER));
  const [loadSettingCard, setLoadSettingCard] = useState(settingCard);

  useEffect(() => {
    const ManageSetting = () => {
      if (user?.user?.role === ADMIN_ROLE) {
        setLoadSettingCard([...loadSettingCard, addUser]);
      }
    };

    ManageSetting();
  }, []);
  // let loadSettingAccordion = [
  //   {
  //     accordionTitle: "Create Sub Users",
  //     accordionDescription:
  //       " It is shown by default, until the collapse plugin adds the appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the ",
  //   },
  //   {
  //     accordionTitle: "City",
  //     accordionDescription:
  //       " It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the ",
  //   },
  //   {
  //     accordionTitle: "Accordion Item",
  //     accordionDescription:
  //       " It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the ",
  //   },
  // ];

  //main return
  return (
    <div className="col-12 main-contian">
      {/* <!-- Page Name --> */}
      <h3 className="">Settings</h3>
      <div className="row mb-3 settings">
        {loadSettingCard &&
          loadSettingCard.map((item, i) => {
            return <SettingCard key={item.title} card={item} />; //new line after received warning
          })}
      </div>
      {/* <div className="accordion" id="accordionExample">
        {loadSettingAccordion &&
          loadSettingAccordion.map((item, i) => {
            return <SettingAccordion key={item.accordionTitle} index={i} accordion={item} />; //new line after received warning
            // return <SettingAccordion index={i} accordion={item} />;  old  //Warning: Each child in a list should have a unique "key" prop.
          })}
      </div> */}
    </div>
  );
};

export default SettingPage;
