import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import Button from "../buttons/Button";

//
const Modal = ({
  onSuccess,
  onDelete,
  onClose,
  onReset,
  children,
  ...rest
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");

    //optionally cleanup fun
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  //
  return ReactDOM.createPortal(
    <>
      <div
        class="modal fade show "
        id="modalPopUp"
        style={{ display: "block", paddingRight: "15px" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalPopUpTitle">
                {rest?.title}
              </h5>
              <Button class="btn btn-close " type="button" close onClick={onClose}>
                <span>&times;</span>
              </Button>
            </div>
            <div class="modal-body">{children}</div>
            <div class="modal-footer ">
              <div className="row w-100">
                {onDelete && (
                  <div className="col-sm-3">
                    <Button type="button" danger outline onClick={onDelete}>
                      Delete
                    </Button>
                  </div>
                )}
                {onReset && (
                  <div className="col-sm-3">
                    <Button type="button" primary onClick={onReset}>
                      ReSet
                    </Button>
                  </div>
                )}

                <div className="col-sm-3">
                  <Button type="button" secondary onClick={onClose}>
                    {rest?.cancleText ? rest?.cancleText : "Close"}
                  </Button>
                </div>
                <div className="col-sm-6">
                  {onSuccess && (
                    <Button
                      type="button"
                      newClassName="btn btn-upload "
                      onClick={onSuccess}
                    >
                      {rest?.successText ? rest?.successText : "Save changes"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show " onClick={onClose}></div>
    </>,
    document.querySelector(".modal-popup-container")
  );
};

export default Modal;
