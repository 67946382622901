import React, { useState } from "react";
import UpdateCategoryPopup from "./UpdateCategoryPopup";

//main function
const CategoryCard = ({ category, onCategoryUpdate }) => {
  let ImagePath = process.env.REACT_APP_CATEGORYS;
  let ImagePullPath =
    ImagePath + category?.categoryId + "/" + category?.categoryPic;

  //declare start
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(category?.isActive);
  //declare end

  //function/events/methods
  const showUpdateCategoryModal = () => {
    setShowModal(true);
    // onCategoryUpdate(event);
  };

  const toggleIsActive = () => {
    const updatedIsActive = !isActive;
    setIsActive(updatedIsActive);
    onCategoryUpdate({ ...category, isActive: updatedIsActive });
  };

  //main return
  return (
    <>
      <div className="manage-category p-2 border-bottom">
        <div className="row align-items-start m-2 justify-content-center align-items-center">
          <div className="col">
            <img
              src={ImagePullPath}
              alt=""
              className="img-fluid categoryImageHeight"
            />
          </div>

          <div className="col" onClick={() => showUpdateCategoryModal()}>
            <h1>{category?.categoryName}</h1>
            <div className="form-check form-switch  float-end" href="#">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                value={category.categoryName}
                checked={category.isActive === true}
                // onChange={toggleIsActive} // Add onChange event handler
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <UpdateCategoryPopup
          category={category}
          onCategoryUpdate={onCategoryUpdate}
          setPopUpVisibility={(value) => setShowModal(value)}
        />
      )}
    </>
  );
};

export default CategoryCard;

//main return
