import React, { useState } from "react";

//main function
const StateCard = ({index,state,handleStateClick,check,handleCheckbox,}) => {
  //declare start
  const [enable, setEnable] = useState(check);
  //declare end

  //function/events/methods
  const handleCheckbox1 = (event) => {
    setEnable(check);
    handleCheckbox(event, check);
  };
  //main return
  return (
    <>
      <div className="p-2 border-bottom">
        <div
          className="row align-items-start m-2 justify-content-center align-items-center"
          onClick={(event) => handleStateClick(event, state)}
        >
          <div className="col">
            {/* {index} */}
            <div
              className="image-box"
              style={{
                backgroundImage:
                  'url("https://www.holidaymonk.com/wp-content/uploads/2022/04/Gateway-Of-India-MUMBAI.jpg")',
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: 100,
              }}
            />
          </div>
          <div className="col">
            <h1>{state.stateName}</h1>
          </div>

          <div className="col">
            <div className="form-check form-switch  float-end" href="#">
              <input
                className="form-check-input"
                checked={state.isActive}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={(event) => handleCheckbox1(event)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StateCard;
