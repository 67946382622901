import React, { useState } from "react";
import UpdateCityPopUp from "./UpdateCityPopUp";

//main function
const CityCard = ({ city, stateId, onStateChange, onCityUpdate }) => {
  let ImagePath = process.env.REACT_APP_CITYS;
  let ImagePullPath = ImagePath + city.cityId + "/" + city.cityPic;

  //declare start
  const [showModal, setShowModal] = useState(false);
  // const [isActive, setIsActive] = useState(city.isActive);
  //declare end

  //function/events/methods
  const showUpdateCityModal = (event, city) => {
    setShowModal(!showModal);
    onCityUpdate(event);
  };

  //main return
  return (
    <>
      <div className="p-2  border-bottom ">
        <div
          className="row align-items-start m-2 justify-content-center align-items-center"
          onClick={(event) => showUpdateCityModal(event, city)}
        >
          <div className="col">
            <div
              className="image-box"
              style={{
                backgroundImage: `url('${ImagePullPath}')`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: 100,
              }}
            ></div>
          </div>

          <div className="col">
            <h1>{city.cityName}</h1>
            {/* <div className="form-check form-switch  float-end" href="#">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={isActive}
                // onChange={() => setIsActive(!isActive)}
              />
            </div> */}
          </div>
        </div>
      </div>

      {showModal && (
        <UpdateCityPopUp
          city={city}
          onCityUpdate={onCityUpdate}
          stateId={stateId}
          onStateChange={onStateChange}
        />
      )}
    </>
  );
};

export default CityCard;
