import React from "react";
import { Routes, Route } from "react-router-dom";
import DashboardPage from "../../pages/dashboard/DashboardPage";
import SettingPage from "../../pages/setting/SettingPage";
import CategoryPage from "../../pages/setting/CategoryPage";
import StatePage from "../../pages/setting/StatePage";
import CityPage from "../../pages/setting/CityPage";
import PromotionBannerPage from "../../pages/setting/PromotionBannerPage";
import NotiFicationPage from "../../pages/notification/NotificationPage";
import KycPage from "../../pages/kyc/KycPage";
import NoPage from "../../pages/NoPage";
import Logout from "../../pages/logout/Logout";
import AnnouncementPage from "../../pages/announcement/AnnouncementPage";
import KycDetails from "../kyc/KycDetails";
import ReconcillationPage from "../../pages/reconcillation/ReconcillationPage";
import OrganizerDetails from "../dashboard/organizerDetails/OrganizerDetails";
import EventDetailsPage from "../dashboard/eventDetails/EventDetailsPage";
import MoatConfigurationPage from "../../pages/moat/MoatConfigurationPage";
import TotalTicketsSales from "../reconcillation/sales_totalTickets/TotalTicketsSales";
import PaymentBreakup from "../reconcillation/sales_totalTickets/PaymentBreakup";
import PaymentDueOrganizer from "../reconcillation/paymentDueToOrganizer/PaymentDueOrganizer";
import PaymentBreakupOrganizer from "../reconcillation/paymentDueToOrganizer/PaymentBreakupOrganizer";
import UserPage from "../../pages/setting/UserPage";
import ViewSaleDetails from "../dashboard/eventDetails/ViewSaleDetails";
import ViewSalesDetails from "../dashboard/eventTable/ViewSalesDetails";
import AnnouncementModel from "../announcement/AnnouncementModel";
import CreateEvent from "../../pages/createEvent/CreateEvent";

//main fun
const MasterRouter = () => {
  //main retrun
  return (
    <>
      <Routes>
        <Route path="*" element={<NoPage />} />
        {/* Dashboard */}
        <Route path="/" element={<DashboardPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route
          path="/organizer-details/:userId/*"
          element={<OrganizerDetails />}
        />
        <Route path="/create-event" element={<CreateEvent />} />

        <Route
          path="/event-details/:eventId/*"
          element={<EventDetailsPage />}
        />
        <Route path="/view-sales-details" element={<ViewSaleDetails />} />
        {/* Announcement */}
        <Route path="/announcement" element={<AnnouncementPage />} />
        <Route path="/send-announcement" element={<AnnouncementModel />} />
        <Route path="/notification" element={<NotiFicationPage />} />
        <Route
          path="/event-sales-details/:eventId/:eventName/:refUserId"
          element={<ViewSalesDetails />}
        />
        {/* Reconcillation */}
        <Route path="/reconcillation" element={<ReconcillationPage />} />
        <Route path="/totalTicketsSales" element={<TotalTicketsSales />} />
        <Route
          path="/payment-breakup/:eventId/*"
          element={<PaymentBreakup />}
        />
        <Route
          path="/paymentDueToOrganizer"
          element={<PaymentDueOrganizer />}
        />
        <Route
          path="/payment-breakup-organizer/:orgId/*"
          element={<PaymentBreakupOrganizer />}
        />
        {/* KYC */}
        <Route path="/kyc" element={<KycPage />} />
        <Route path="/kyc-details/:userId/*" element={<KycDetails />} />
        {/* Setting */}
        <Route path="/setting" element={<SettingPage />} />
        <Route path="/setting/category" element={<CategoryPage />} />
        <Route path="/setting/state" element={<StatePage />} />
        <Route path="/setting/city" element={<CityPage />} />
        <Route
          path="/setting/promotionBanner"
          element={<PromotionBannerPage />}
        />
        <Route path="/setting/user" element={<UserPage />} />
        <Route path="/moat" element={<MoatConfigurationPage />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
};

export default MasterRouter;
