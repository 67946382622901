import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {

  //main retrun
  return (
    <>
    <Link 
    to={"/"}>
    <div className="logo">
       <div className="logo" style={{width:"150px", marginLeft:"32px"}}>
        <img src="/VrattantaLogo.svg"/>        
        </div>
    </div>
    </Link>
    </>
  );
};

export default Logo;