import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetAllOrganizer } from "../../service/dashboardAPI";
import Table from "../../components/controls/table/Table";
import { FOLDER_ORGANIZER_LOGO } from "../../util/Constants";
import Spinner from "../controls/spinner/Spinner";

//main function
const TotalOrganizer = () => {
  //declare start
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true); // loading state
  const userData = useSelector((state) => state.login.login.login);
  //declare end

  //function/event/methods
  // Fetch organizers from API
  useEffect(() => {
    let cleanUp = true;
    const fetchOrganizers = async () => {
      try {
        if (cleanUp && userData.token) {
          const response = await GetAllOrganizer(userData.token);
          if (response && response.success) {
            setOrganizers(response.data);
            setLoading(false);
          }
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching organizers:", error);
      }
    };

    fetchOrganizers();
    // cleanup function
    return () => {
      cleanUp = false;
    };
  }, []);

  //table
  const config = {
    tableTitle: "Total Organizers",
    data: organizers,
    displayRecord: 10,
    defaultSortBy: (data) => data?.businessName,
    columns: [
      {
        title: "Photo",
        titleClass: "",
        dataClass: "py-1 images ",
        isImage: true,
        render: (item) => (
          <img
            src={`${
              process.env.REACT_APP_USERS +
              item.userId +
              FOLDER_ORGANIZER_LOGO +
              item.businessLogo
            }`}
            className="img-fluid rounded"
            style={{ maxWidth: "55px", maxHeight: "45px" }}
            alt="Logo"
          />
        ),
      },
      {
        title: "Organizer Name ",
        titleClass: " ",
        dataClass: "",
        dataField: "businessName",
        isSort: false,
        sortByValue: (data) => data.businessName,
      },
      {
        title: "Business Email",
        titleClass: "",
        dataClass: "",
        dataField: "businessEmail",
        isSort: false,
        sortByValue: (data) => data.businessEmail,
      },
      {
        title: "Mobile",
        titleClass: "",
        dataClass: "",
        dataField: "businessMobile",
        isSort: false,
        sortByValue: (data) => data.businessMobile,
      },
      // {
      //   title: "Website",
      //   titleClass: "",
      //   dataClass: "text-center align-middle",
      //   dataField: "businessWebsite",
      //   isSort: false,
      //   sortByValue: (data) => data.businessWebsite,
      // },
      // {
      //   title: "Location",
      //   titleClass: "",
      //   dataClass: "text-center align-middle text-success-btn",
      //   dataField: "businessAddress",
      //   isSort: false,
      //   sortByValue: (data) => data.businessAddress,
      // },
      {
        title: "Action",
        isLink: true,
        render: (item) => (
          <div className="">
            <Link
              className="btn-link btn align-middle p-0"
              to={`/organizer-details/${item.userId}`}
            >
              View More
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "",
      },
    ],
  };
  // const [configData, setConfigData] =useState(config);

  // const hanedleViewMore = (item) => {
  //   alert("hanedleViewMore");
  // };

  return (
    <>
      <div className="d-flex flex-column mb-3">
        {loading ? <Spinner /> : <Table config={config} />}
      </div>
    </>
  );
};

export default TotalOrganizer;
