import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetAllEventData } from "../../../service/reconcillationAPI";
import Table from "../../controls/table/Table";

//main function
const TotalTicketsSales = () => {
  //declare start

  const [eventData, setEventData] = useState([]);
  //declare end

  //function/events/methods
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await GetAllEventData();
        if (response.success) {
          setEventData(response.data);
        }
      } catch (error) {
        console.error("Error fetching all events data", error);
      }
    };
    fetchEventData();
  }, []);

  //table
  const config = {
    tableTitle: "Sales - Total Tickets Sales",
    data: eventData,
    displayRecord: 10,
    columns: [
      {
        title: "Event Name ",
        titleClass: " ",
        dataClass: "",
        dataField: "eventName",
        isSort: false,
        sortByValue: (data) => data.eventName,
      },
      {
        title: "ID",
        titleClass: "text-center",
        // dataClass: "text-center id",
        dataField: "eventId",
        isSort: false,
        sortByValue: (data) => data.eventId,
      },
      {
        title: "Total Sales",
        titleClass: "text-center sale",
        dataClass: "",
        dataField: "address", // After obtaining the total sales, the current field will be replaced with the total sales field.
        isSort: false,
        sortByValue: (data) => data.address,
      },
      {
        title: "Event Date",
        titleClass: "",
        dataClass: "text-center e-date",
        dataField: "eventStartDate",
        isSort: false,
        sortByValue: (data) => data.eventStartDate,
      },
      {
        title: "Action",
        isLink: true,
        render: (item) => (
          <div className="text-center align-middle">
            <Link
              className="btn-outline-dark btn align-middle p-1 "
              to={`/payment-breakup/${item.eventId}`}
            >
              View More
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "text-center",
      },
    ],
  };

  // main return
  return (
    <>
      <div className="row mb-3 my-3">
        <div className=" col-4 ml">
          <Link
            to="/reconcillation"
            // onClick={() => handleClick(index)}
            // key={index}
            style={{ textDecoration: "none", color: "black" }}
          >
            <h3 className="my-3 ml-3 d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 -960 960 960"
                width="20"
              >
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
              </svg>
              <span className="font-weight-semibold">
                Sales - Total Tickets Sales
              </span>
              {/* <Icon iconName={item.icon} />  */}
            </h3>
          </Link>
        </div>
        <div className="col">
          <div className="  input-group year-select float-end">
            <select className="form-select" id="inputGroupSelect01">
              <option>Month</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <Table config={config} />
      </div>
    </>
  );
};

export default TotalTicketsSales;
