import React, { useEffect } from "react";
import { connect } from "react-redux";
import Logo from "../admin/Logo";
import Sidebar from "../admin/Sidebar";
import TopNavbar from "../admin/TopNavbar";
import MasterRouter from "./MasterRouter";
import { fetchLoginRequest } from "../../redux/actions/Login";
import { ADMIN_ROLE, LOCAL_STORAGE_USER } from "../../util/Constants";
import * as Common from "../../util/commonFun";

const itemsSuperAdmin = [
  { title: "Dashboard", url: "/dashboard", icon: "dashboard" },
  { title: "Create Event", url: "/create-event", icon: "events" },
  { title: "Announcement", url: "/announcement", icon: "accouncement" },
  // { title: "Reconcillation", url: "/reconcillation", icon: "reconcillation" },
  { title: "Notification", url: "/notification", icon: "notification" },
  { title: "KYC", url: "/kyc", icon: "kyc" },
  { title: "Setting", url: "/setting", icon: "setting" },
  { title: "MOAT", url: "/moat", icon: "moat" },
  { title: "Logout", url: "/logout", icon: "logout" },
];

const itemsAdmin = [
  { title: "Dashboard", url: "/dashboard", icon: "dashboard" },
  { title: "Create Event", url: "/create-event", icon: "events" },
  { title: "Announcement", url: "/announcement", icon: "accouncement" },
  { title: "Notification", url: "/notification", icon: "notification" },
  { title: "KYC", url: "/kyc", icon: "kyc" },
  { title: "Setting", url: "/setting", icon: "setting" },
  { title: "Logout", url: "/logout", icon: "logout" },
];

//main fun
const Master = ({ login, fetchLogin }) => {
  const user = JSON.parse(Common.getLocalData(LOCAL_STORAGE_USER));

  useEffect(() => {
    login = fetchLogin();
  }, []);

  //main retrun
  return (
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar Component */}
        <div className="rightside-bar col-xxl-2 col-md-3">
          <Logo />
          {user && user?.user?.role && user?.user?.role === ADMIN_ROLE ? (
            <Sidebar items={itemsSuperAdmin} />
          ) : (
            <Sidebar items={itemsAdmin} />
          )}
        </div>

        {/* Main Content */}
        <div className="col-xxl-10 col-md-9 maincontain-width">
          <div className="row">
            {/* Top Navbar Component */}
            <TopNavbar />
          </div>
          {/* Router Component */}
          <MasterRouter />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  fetchLogin: (login) => dispatch(fetchLoginRequest(login)),
});

export default connect(null, mapDispatchToProps)(Master);
//export default Master;
