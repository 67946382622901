import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import ReconcillationCard from "../../components/reconcillation/ReconcillationCard";

//main function
const ReconcillationPage = () => {
  //declare start
  const [selectedTitle, setSelectedTitle] = useState("");
  //declare end

  let LoadReconcillationcard = [
    {
      title: "Sales - Total Tickets Sales",
      count: "₹1234.55",
      icon: "0",
    },
    {
      title: "Collection Receipt from PG",
      count: "₹1234.55",
      icon: "1",
    },
    {
      title: "Payment Due to Oraganizer and Payment completed",
      count: "₹1234.55",
      icon: "2",
    },
    {
      title: "Commission Earned - Total",
      count: "₹1234.55",
      icon: "3",
    },
    {
      title: "Tax Due to Govt By FitFunda",
      count: "₹1234.55",
      icon: "4",
    },
  ];

  //function/events/methods
  const handleCardClick = (title) => {
    setSelectedTitle(title);
  };

  //main return
  return (
    <>
      <div className="col-12 main-contain">
        <div className="row mb-3 my-3">
          <h3 className=" col-3 my-3">
            <i className="bi bi-chevron-left me-2 fw-bold"></i>Reconcillation
          </h3>
          <div className="col">
            <div className="  input-group year-select float-end">
              <select className="form-select" id="inputGroupSelect01">
                <option>Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between my-3 ">
          {LoadReconcillationcard &&
            LoadReconcillationcard.map((item, i) => {
              return (
                <ReconcillationCard
                  key={i}
                  card={item}
                  onCardClick={handleCardClick}
                />
              );
            })}
            
          {selectedTitle === "Sales - Total Tickets Sales" && (<Navigate to="/totalTicketsSales" />)}
          {selectedTitle === "Collection Receipt from PG" && (<Navigate to="/totalTicketsSales" /> )}
          {selectedTitle ==="Payment Due to Oraganizer and Payment completed" && (<Navigate to="/paymentDueToOrganizer" />)}
          {selectedTitle === "Commission Earned - Total" && (<Navigate to="/totalTicketsSales" />)}
          {selectedTitle === "Tax Due to Govt By FitFunda" && (<Navigate to="/totalTicketsSales" />)}

        </div>
      </div>
    </>
  );
};

export default ReconcillationPage;
