import React from "react";
import { Link } from "react-router-dom";

//main function
const SettingCard = ({ card }) => {
  //main return
  return (
    <>
      <div className="col-4">
      <Link to={card.url} className="" style={{ textDecoration: "none", color: "black" }}>
        <div className="card mb-4">
          <img
            src={card.cardimg}
            className="card-img-top img-fluid"
            alt="..."
            style={{height:"250px"}}
          />
        
            <div className="card-body">
              <div className="d-flex">
                <div className="p-2 w-100">
                  <h1>{card.title}</h1>
                </div>
                <div className="p-2 flex-shrink-1">
                  <img src={"./images/left-arrow.png"} alt="" />
                </div>
              </div>
            </div>          
        </div>
        </Link>
      </div>
    </>
  );
};

export default SettingCard;
