import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "../controls/popup/Modal";
import { GetOragnizerList } from "../../service/eventAPI.jsx";
import { encryptData } from "../../util/cryptoJs.jsx";
//main function
const CreateEventPopup = ({ onCategoryCreated }) => {
  //declare start
  const [showModal, setShowModal] = useState(false);
  const [oragnizerList, setOragnizerList] = useState([]);
  const [refUserId, setRefUserId] = useState("0");
  const [loading, setLoading] = useState(true); //spinner
  const [responseMsg, setResponseMsg] = useState("");
  const userData = useSelector((state) => state.login.login.login);

  //declare end

  //function/events/methods
  useEffect(() => {
    const oragList = async () => {
      try {
        const allEventsResponse = await GetOragnizerList(userData?.token);
        if (allEventsResponse?.success === true) {
          setLoading(false);
          setOragnizerList(allEventsResponse?.data);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    oragList();
  }, []);

  const handleOragnizer = (event) => {
    const { value } = event.target;
    setRefUserId(() => value);
  };
  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const submitCreateEvent = () => {
    let redirectURL =
      process.env.REACT_APP_FRONT_END_USER_URL + "create-event-ad/";

    //alert("ok" + refUserId);
    if (refUserId !== "0" && userData?.token !== "") {
      const date = new Date().toLocaleDateString("en-IN");
      let loggedInUserId = userData?.user.userId;
      let param =
        date + "~" + loggedInUserId + "~" + refUserId + "~" + userData?.token;
      param = encryptData(param);
      redirectURL = redirectURL + "?q=" + param;
      handleClose();
      window.open(redirectURL, "_blank");
    }
  };
  //main return
  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          class="btn create-even btn-sm text-center"
          onClick={handleClick}
        >
          <span class="p-2">+</span>Create Event
        </button>
      </div>

      {showModal && (
        <Modal
          title="Create Event"
          onClose={handleClose}
          onSuccess={submitCreateEvent}
          cancleText="Cancel"
          successText="Submit"
        >
          {responseMsg && (
            <div className="alert alert-danger" role="alert">
              <h6>{responseMsg}</h6>
            </div>
          )}
          <div className="modal-body manage-category-modal">
            <div className="row">
              <div className="col m-2 mt-2">
                <select
                  class="form-select form-select-lg mb-3 custom-select-font"
                  onChange={(event) => handleOragnizer(event)}
                  value={refUserId}
                >
                  <option value="0" className="custom-placeholder">
                    Please Select Organizer
                  </option>
                  {oragnizerList &&
                    oragnizerList?.map((item, index) => {
                      return (
                        <option value={item?.userId}>
                          {item?.businessName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreateEventPopup;
