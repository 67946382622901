import React, { useState } from "react";
import { useSelector } from "react-redux";
import StateDropdown from "./StateDropdown";
import { UpdateCity } from "../../../service/cityAPI";
import * as validator from "../../../util/validations";
import InputTextBox from "../../controls/input/InputTextBox";
import Modal from "../../controls/popup/Modal";

//main function
const UpdateCityPopUp = ({
  city,
  onCityUpdate,
  stateId,
  onStateChange,
  onDelete,
}) => {
  let ImagePath = process.env.REACT_APP_CITYS;

  //declare start
  const [showModal, setShowModal] = useState(true);
  const [updatedCityName, setUpdatedCityName] = useState("");
  const [updateImageFileObject, setUpdateImageFileObject] = useState("");
  const userData = useSelector((state) => state.login.login.login);

  //validition and input value
  let initialData = {
    cityName: "abcd123",
    isActive: true,
    cityPic: "",
    stateId: stateId,
    errors: {},
    isValid: {},
  };
  if (city) {
    initialData.cityName = city.cityName;
    initialData.isValid.cityName = true;
    initialData.isActive = city.isActive;
    let ImagePullPath = ImagePath + city.cityId + "/" + city.cityPic;
    initialData.cityPic = ImagePullPath;
  }
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid, cityPic, isActive } = inputValue;
  //declare end

  //function/events/methods
  const handleUpdate = async (event) => {
    if (event) event.preventDefault();
    if (inputValue.isValid?.cityName && inputValue.stateId > 0) {
      const UpdateCityResponse = await UpdateCity(
        city,
        inputValue,
        updateImageFileObject,
        userData.token
      );
      if (UpdateCityResponse?.success) {
        onCityUpdate({
          ...city,
          cityName: updatedCityName,
          cityPic: updateImageFileObject,
          isActive: inputValue.isActive,
        });
        onCityUpdate();
        setShowModal(false);
        alert(UpdateCityResponse.message);
      }
    } else {
      alert("Please Enter valid input");
    }
  };

  const handleStateChange = (selectedStateId) => {
    //setValue
    setInputValue((prev) => ({
      ...prev,
      ["stateId"]: selectedStateId,
    }));
  };

  //checking validation
  const handleChange = (event) => {
    let { name, value, type } = event.target;
    //validation
    let result = IsValid(name, value);
    if (type === "checkbox") {
      value = event.target.checked;
    }
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };
  const IsValid = (name, value) => {
    let status = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "cityName":
          status = validator.IsValidCharWithSpace(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
      }
    }

    return { status: status, message: msg };
  };

  const handleUpdatedImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setUpdateImageFileObject(() => event.target.files[0]);

    //setValue
    setInputValue((prev) => ({
      ...prev,
      cityPic: imageUrl,
      errors: { ...errors, [cityPic]: "" },
      isValid: { ...isValid, [cityPic]: true },
    }));
  };

  //delete function
  // const handleDelete = (event) => {
  //   event.preventDefault();
  //   alert(`Do you want to delete "${city.cityName}"?`);
  //   if (alert) {
  //     onDelete(city.cityId);
  //   }
  //   setShowModal(false); // Close the modal after delete
  // };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setShowModal(() => false);
  };

  //main return
  return (
    <>
      {/* <div className="modal fade show" 
        style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">Update City</h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(event)=>handleCloseModal(event)}
                />
              </div> */}
      {showModal && (
        <>
          <Modal
            title="Update Category"
            onClose={handleCloseModal}
            // onDelete={handleDelete}
            onSuccess={handleUpdate}
            cancleText="Cancel"
            successText="Update"
          >
            <div className="modal-body manage-category-modal">
              <form action="" method="post">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  State Name
                </label>
                <StateDropdown
                  stateId={stateId}
                  onStateChange={handleStateChange}
                />

                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    City Name
                  </label>
                  <InputTextBox
                    id="text"
                    name="cityName"
                    // placeholder="Please Enter City Name"
                    value={inputValue.cityName}
                    isValid={inputValue?.isValid?.cityName}
                    errorMsg={inputValue?.errors?.cityName}
                    onChange={handleChange}
                  />
                  {/* <label htmlFor="exampleInputEmail1" className="form-label">
                    Disable or Enable City:
                  </label>
                  <div className="form-check form-switch" href="#">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      // value={inputValue.isActive}
                      defaultChecked={inputValue.isActive}
                      name="isActive"
                      onChange={(event) => handleChange(event)}
                    />
                  </div> */}
                </div>
              </form>
              <div className="form-group file-area">
                <div className="my-2 message">
                  Upload City Image (size: 60px X 60px)
                </div>
                <input
                  type="file"
                  name="images"
                  id="images"
                  required="required"
                  multiple="multiple"
                  onChange={(event) => handleUpdatedImage(event)}
                />
                <div className="upload-event-photo">
                  <div className="">
                    {inputValue.cityPic && (
                      <img
                        src={inputValue.cityPic}
                        alt="Uploaded"
                        style={{ maxWidth: "380px" }}
                      />
                    )}
                  </div>
                  <div className="default">
                    <p>
                      <i className="bi bi-image" />
                    </p>
                    {/* Upload Image */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
              <Button
                  // type="button"
                  className=" ml-auto"
                  // data-bs-dismiss="modal"
                  danger outline
                  onClick={(event)=>handleDelete(event)}
                >
                  Delete
                </Button>
                <Button
                  // type="button"
                  // className="btn btn-cancel"
                  // data-bs-dismiss="modal"
                  cancel
                  onClick={(event)=>handleCloseModal(event)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  // className="btn btn-submit"
                  // success outline
                  onClick={(event)=>handleUpdate(event)}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div> */}
          </Modal>
        </>
      )}
    </>
  );
};

export default UpdateCityPopUp;
