import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import * as Common from "../../util/commonFun";
import { deleteFromLoginRequest } from "../../redux/actions/Login";

//main function
const Logout = ({ login, deleteLogin }) => {
  //declare start
  const [showLogout, setShowLogout] = useState(true);
  const navigate = useNavigate();
  //declare end

  //function/events/methods
  const logoutUser = (e) => {
    setShowLogout(false);
    // const result = handleLogout();
    // if (result) {
    //   Common.clearLocalData();
    //   navigate("/");
    // } else {
    //   alert("Please try again.");
    // }
    Common.clearLocalData();
    deleteLogin(null);
    navigate("/");
  };

  const cancelLogout = (e) => {
    setShowLogout(false);
    navigate("/dashboard");
  };

  //main return
  return (
    <div>
      <div
        className="modal fade show"
        id="mobileNumberPopup"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{
          paddingRight: "15px",
          display: `${showLogout ? "block" : "none"}`,
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h5 className="modal-title">Modal title</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
              <div className="modal-body">
                <p>Are you sure you want to logout?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => logoutUser()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => cancelLogout()}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  deleteLogin: (login) => dispatch(deleteFromLoginRequest(login)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
//export default Logout;
