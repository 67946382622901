import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import InputTextArea from "../controls/input/InputTextArea";
import Button from "../controls/buttons/Button";
import {
  RejectOraganizer,
  GetOrganizerDetail,
  ApproveOrganizer,
} from "../../service/kycAPI";
import * as validator from "../../util/validations";
import OrganizerBasicDetails from "./OrganizerBasicDetails";
import OrganizerBusinessProfile from "./OrganizerBusinessProfile";
import OrganizerBankAccountDetails from "./OrganizerBankAccountDetails.jsx";
import Modal from "../controls/popup/Modal.jsx";
import { FOLDER_ORGANIZER_LOGO } from "../../util/Constants.jsx";
import AccordionCard from "../controls/accordion/AccordionCard.jsx";
import InputTextBox from "../controls/input/InputTextBox.jsx";
import Spinner from "../controls/spinner/Spinner.jsx";

//main function
const KycDetails = () => {
  // declare start
  let ImagePath = process.env.REACT_APP_USERS;
  const { orgId, userId } = useParams();
  const navigate = useNavigate(); // add useNavigate
  
  const [organizerData, setOrganizerData] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModalReject, setShowSuccessModalReject] = useState(false);
  const [showApprovalSuccessModal, setShowApprovalSuccessModal] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(true); // loading spinner
  const userData = useSelector((state) => state.login.login.login);

  //validition and input value
  let initialData = {
    tdsPercent: "",
    rejectionReason: "",
    errors: {},
    isValid: {},
  };
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid } = inputValue;
  //declare end

  //function/event/methods

  useEffect(() => {
    const fetchOrganizerData = async () => {
      if (userData?.token) {
        const response = await GetOrganizerDetail(userId, userData?.token);

        if (response?.success) {
          setOrganizerData(response?.data);
          setLoading(false);
        } else {
          setLoading(false);
          console.error("Failed to fetch organizer data");
        }
      }
    };

    fetchOrganizerData();
  }, [userData]);

  const handleCloseModal = (event) => {
    event.preventDefault();
    setShowModal(() => false);
    setShowConfirmationModal(() => false);
  };

  const handleReject = async () => {
    if (!inputValue || !inputValue.rejectionReason.trim()) {
      alert("Please enter valid input");
    } else {
      if (userData.token) {
        setLoading(true);
        const response = await RejectOraganizer(
          organizerData,
          inputValue,
          userData.token
        );
        setLoading(false);
        if (response?.success) {
          setShowModal(false);
          handleSuccessModalReject();
        } else {
          alert("Failed to reject organizer");
        }
      }
    }
  };

  const handleApprove = async () => {
    if (userData?.token) {
      setLoading(true);
      const response = await ApproveOrganizer(
        organizerData.userId,
        inputValue.tdsPercent,
        userData?.token
      );
      setLoading(false);
      if (response?.success) {
        setShowConfirmationModal(false);
        setShowApprovalSuccessModal(true);
      } else {
        alert(response?.message);
      }
    }
  };

  //checking validation
  const handleChange = (event) => {
    const { name, value } = event.target;
    //validation
    let result = IsValid(name, value);
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));

    if (name === "rejectionReason") {
      // Calculate word count
      // setWordCount(value.trim().split(/\s+/).filter(Boolean).length);
      setWordCount(value.length);
    }
  };

  const IsValid = (name, value) => {
    let status = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "rejectionReason":
          status = validator.IsValidCharWithSpace(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;

        case "tdsPercent":
          status = !validator.IsEmpty(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
      }
    }

    return { status: status, message: msg };
  };

  const handleClick = () => {
    setShowModal(true);
  };

  const handleSuccessModalReject = () => {
    setShowSuccessModalReject(true);
  };

  const handleCloseSuccessModalReject = () => {
    setShowSuccessModalReject(false);
  };

  const handleCloseApprovalSuccessModal = () => {
    setShowApprovalSuccessModal(false);
  };

  //accordion
  const [activeTabNo, setActiveTabNo] = useState(-1);

  // const handleSave = (tabNo) => {
  //   setActiveTabNo(() => tabNo + 1);
  // };
  // const handleSkip = (tabNo) => {
  //   setActiveTabNo(() => tabNo + 1);
  // };

  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Basic Profile</h1>
          </div>
        ),

        content: <OrganizerBasicDetails organizer={organizerData} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Business Profile</h1>
          </div>
        ),

        content: <OrganizerBusinessProfile organizer={organizerData} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Bank Account Details</h1>
          </div>
        ),

        content: <OrganizerBankAccountDetails organizer={organizerData} />,
      },
    ],
  };

  // main return
  return (
    <>
      {" "}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="ml-3 col-5">
            <Link
              to="#"
              onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
              // onClick={() => handleClick(index)}
              style={{ textDecoration: "none", color: "black" }}
            >
              <h3 className="my-3 ml-3 d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 -960 960 960"
                  width="20"
                >
                  <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
                </svg>
                <span className="font-weight-semibold">
                  {organizerData.businessName}
                </span>
                {/* <Icon iconName={item.icon} />  */}
              </h3>
            </Link>
          </div>

          <div className="col-12 main-contian org-kyc">
            {/* Page Name */}
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="my-3">
                <i className="bi bi-chevron-left me-2 fw-bold" />
                {/* {organizerData.businessName} {orgId} */}
              </h3>

              <div className="modal-footer border-0 col-3">
                <button
                  type="button"
                  className="btn btn-outline-dark me-2"
                  onClick={handleClick}
                  // data-bs-dismiss="modal"
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal01"
                >
                  Reject
                </button>
                <Button
                  type="button"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Approve
                </Button>
              </div>
            </div>
            <div className="col-sm-12 col-md-8 col-xl-12 org-main-contain my-3 card mb-2">
              <div className="card p-0 rounded">
                <div className="card-body p-0 rounded">
                  <div className="my-profile-banner rounded">
                    <div className="d-flex">
                      <div className="p-2">
                        <img
                          loading="lazy"
                          src={
                            ImagePath +
                            organizerData.userId +
                            FOLDER_ORGANIZER_LOGO +
                            organizerData.businessLogo
                          }
                          width={100}
                          height={100}
                          alt=""
                        />
                      </div>
                      <div className="p-2 flex-grow-1 mt-3">
                        <h1>{organizerData.businessName}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AccordionCard config={config} />
            {showConfirmationModal && (
              <Modal
                title={`Are you sure you want to approve "${organizerData.businessName}" ?`}
                onClose={handleCloseModal}
                onSuccess={handleApprove}
                cancleText="Cancel"
                successText="Approve"
              >
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Set TDS %
                  </label>
                  <InputTextBox
                    id="tdsPercent"
                    name="tdsPercent"
                    type="number"
                    maxLength={2}
                    value={inputValue.tdsPercent}
                    onChange={handleChange}
                    isValid={inputValue?.isValid?.tdsPercent}
                    errorMsg={inputValue?.errors?.tdsPercent}
                  />
                </div>
              </Modal>
            )}
            {showModal && (
              <Modal
                title="Reason of Rejection"
                onClose={handleCloseModal}
                onSuccess={handleReject}
                cancleText="Cancel"
                successText="Send"
              >
                <div className="modal-body">
                  <div className="d-flex align-items-center justify-content-between justify-content-between anno mb-3">
                    <div>
                      Reason
                      <span className="text-danger">*</span>
                    </div>
                    <div>
                      {wordCount} / 2000{" "}
                     
                      <img src="\images\info-icon.png" alt="" />
                    </div>
                  </div>

                  <InputTextArea
                    id="rejectionReason"
                    name="rejectionReason"
                    placeholder="Reason for rejection"
                    isValid={inputValue?.isValid?.rejectionReason}
                    errorMsg={inputValue?.errors?.rejectionReason}
                    onChange={handleChange}
                    rows="4"
                  />
                </div>
              </Modal>
            )}

            {showSuccessModalReject && (
              <Modal onClose={handleCloseSuccessModalReject}>
                <div className="modal-bodytext-center p-4 text-center my-4">
                  <div className="my-4">
                    <img src="/images/thank-you.svg" alt="" className="" />
                  </div>
                  <div className="my-4">
                    Organizer Profile Rejected Successfully
                  </div>
                </div>
              </Modal>
            )}

            {showApprovalSuccessModal && (
              <Modal onClose={handleCloseApprovalSuccessModal}>
                <div className="modal-bodytext-center p-4 text-center my-4">
                  <div className="my-4">
                    <img src="/images/thank-you.svg" alt="" className="" />
                  </div>
                  <div className="my-4">
                    Organizer Profile Approved Successfully
                  </div>
                </div>
              </Modal>
            )}

            {/* <div className="text-center">
          <a href="#"> Download </a>
        </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default KycDetails;
