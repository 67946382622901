import React from "react";
import MoatConfigCard from "../../components/moat/MoatConfigCard";

//main function
const MoatConfigurationPage = () => {
  //main return
  return (
    <div>
      <MoatConfigCard />
    </div>
  );
};

export default MoatConfigurationPage;
