import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../controls/table/Table";
import { GetAllOrganizerData } from "../../../service/reconcillationAPI";
import { FOLDER_ORGANIZER_LOGO } from "../../../util/Constants";

//main function
const PaymentDueOrganizer = () => {
  //declare start
  const [orgData, setOrgData] = useState([]);
  //declare end

  //function/events/methods
  useEffect(() => {
    const fetchOrganizerData = async () => {
      try {
        const response = await GetAllOrganizerData();
        if (response.success) {
          setOrgData(response.data);
        }
      } catch (error) {
        console.error("Error fetching all organizers data", error);
      }
    };
    fetchOrganizerData();
  }, []);

  //table
  const config = {
    tableTitle: "Payment Due to Oraganizer and Payment",
    data: orgData,
    displayRecord: 10,
    columns: [
      {
        title: "Logo",
        titleClass: "text-center",
        dataClass: "py-1 images align-middle",
        isImage: true,
        render: (item) => (
          <img
            src={`${
              process.env.REACT_APP_USERS +
              item.userId +
              FOLDER_ORGANIZER_LOGO +
              item.businessLogo
            }`}
            className="img-fluid rounded"
            style={{ maxWidth: "100px" }}
            alt=""
          />
        ),
      },
      {
        title: "Oraganizer Name ",
        titleClass: " ",
        dataClass: "",
        dataField: "businessName",
        isSort: false,
        sortByValue: (data) => data.businessName,
      },

      {
        title: "Joining Date",
        titleClass: "text-center sale",
        dataClass: "",
        dataField: "organizerId", // After obtaining the total sales, the current field will be replaced with the total sales field.
        isSort: false,
        sortByValue: (data) => data.organizerId,
      },
      {
        title: "Payment Due",
        titleClass: "text-center sale",
        dataClass: "",
        dataField: "businessMobile", // After obtaining the total sales, the current field will be replaced with the total sales field.
        isSort: false,
        sortByValue: (data) => data.businessMobile,
      },
      {
        title: "Action",
        isLink: true,
        render: (item) => (
          <div className="text-center align-middle">
            <Link
              className="btn-outline-dark btn align-middle p-1 "
              to={`/payment-breakup-organizer/${item.organizerId}`}
            >
              View More
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "text-center",
      },
    ],
  };

  // main return
  return (
    <>
      <div className="row mb-3 my-3">
        <div className=" col-6  ml">
          <Link
            to="/reconcillation"
            // onClick={() => handleClick(index)}
            // key={index}
            style={{ textDecoration: "none", color: "black" }}
          >
            <h3 className="my-3 ml-3 d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 -960 960 960"
                width="20"
              >
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
              </svg>
              <span className="font-weight-semibold">
                Payment Due to Oraganizer and Payment completed
              </span>
              {/* <Icon iconName={item.icon} />  */}
            </h3>
          </Link>
        </div>
        <div className="col">
          <div className="  input-group year-select float-end">
            <select className="form-select" id="inputGroupSelect01">
              <option>Month</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <Table config={config} />
      </div>
    </>
  );
};

export default PaymentDueOrganizer;
