import React, { useState } from "react";

/* const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
}); */

const FileUploadSingle = ({ getFileDetail, title, fileType }) => {
  const MAX_FILE_SIZE_MB = 1;
  const ALLOWED_FILE_TYPES = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "application/pdf",
  ];

  const ALLOWED_FILE_TYPES_IMAGE = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
  ];

  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    // File type validation
    if (fileType === "image") {
      if (!ALLOWED_FILE_TYPES_IMAGE.includes(file?.type?.toLowerCase())) {
        setError("Invalid file type. Please upload a JPEG, PNG, or GIF image.");         
        alert(
          "Invalid file type. Please upload a JPEG, PNG, or GIF image.  " +
            file?.name
        );
        setSelectedFile(null);
        return;
      }
    } else {
      if (!ALLOWED_FILE_TYPES.includes(file?.type?.toLowerCase())) {
        setError(
          "Invalid file type. Please upload a JPEG, PNG, GIF or PDF file type."
        );       
        alert(
          "Invalid file type. Please upload a JPEG, PNG, GIF or PDF file type. " +
            file?.name
        );
        setSelectedFile(null); 
        return;
      }
    }

    // File size validation
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(
        `File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`
      );
      setSelectedFile(null);
      return;
    }

    setSelectedFile(file);
    setError(null);
    getFileDetail(file);
  };

  const getAcceptType = () => {
    if (fileType === "image") {
      return "image/*";
    } else if (fileType === "document") {
      return ".pdf,.doc,.docx";
    } else {
      return "image/*,.pdf";
    }

    // return "*/*"; // Fallback to all file types
  };

  return (
    <>
      <div class="mb-3 input-group has-validation">
        <label for="formFile" class="form-label w-100">
          {title}
          {/*  <span class="text-danger">*</span> */}
        </label>

        <input
          class="form-control rounded-start-2 "
          type="file"
          id="formFile"
          accept={getAcceptType()}
          placeholder={title} //"No file"
          onChange={handleFileChange}          
        />
        {/* <label class="input-group-text" for="inputGroupFile02">
          <i class="bi bi-cloud-arrow-up mx-1 select-upload mt-1"></i>Upload
        </label> */}
      </div>
      
      <div className="text-danger">
        {selectedFile !== null ? "" : error}
      </div>
      {/* <div className="text-red-600 text-sm font-medium w-full">
        Upload Document<span className="text-red-600">* </span>
      </div> */}
      {/*  <span className="rounded border bg-white flex w-full items-right justify-between gap-5 mt-2.5 pl-3 pr-px py-px border-solid border-gray-400">
        {title} {selectedFile !== null ? selectedFile.name : error}
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </Button>
      </span> */}
    </>
  );
};

export default FileUploadSingle;
