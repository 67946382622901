import {
  useFetchGetRequestWithToken,
  useFetchPostRequestWithToken,
  useFetchMultipartWithToken,
} from "./api";

export const SendAnnouncement = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/create-announcement`;

  const reqBody = JSON.stringify({
    audience: inputValue.audienceName,
    subject: inputValue.subject,
    emailBody: inputValue.emailBody,
  });

  return useFetchPostRequestWithToken(apiURL, reqBody, token);
};

export const GetAllAnnouncement = async (token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/get-all-announcement`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const SendNewsLetter = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/send-newsletter`;
  const formData = new FormData();
  formData.append("subject", inputValue.subject);
  formData.append("emailBody", inputValue.emailBody);
  formData.append("newsletter", inputValue.newsletter);

  const result = await useFetchMultipartWithToken(apiURL, formData, token);
  return result;
};

//for individual annpuncement
export const SendAnnouncementIndividual  = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/create-personal-announcement`;

  const reqBody = JSON.stringify({
    // audience: inputValue.audienceName,
    subject: inputValue.subject,
    emailBody: inputValue.emailBody,
    emails: [inputValue.individualEmails]
  });
  
  return useFetchPostRequestWithToken(apiURL, reqBody, token);
};