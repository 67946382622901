import React from 'react';
import InputTextBox from '../../controls/input/InputTextBox';
import { convertDateTimeToISTForUI } from '../../../util/DateTimeUtility';

const DiscountDetails = ({event}) => {
    if (!event || !event.discountCoupons) return <div className="card-body p-1">Discount Coupons Details are not available for {event.eventName}!!</div>;
    
    //main return
    return (
      <>
      <div className="card-body p-1">
      <div className="accordion accordion-flush m-0" id="accordionFlushExample">
      {event && 
          event?.discountCoupons?.map(discount => (                
               
        <div className="accordion-item">
          {/* <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#participant-collapseThree"
              aria-expanded="false"
              aria-controls="participant-collapseThree"
            >
              <div className="d-flex justify-content-center align-items-center accordion-detail">
                <h1>Discount Details</h1>
              </div>
            </button>
          </h2> */}
          <h2 className="accordion-header">
            {/* <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#participant-collapseTwo"
              aria-expanded="false"
              aria-controls="participant-collapseTwo"
            > */}
              <div className="alert alert-info">
                <h1 >Discount Details Id: {discount.discountCouponId}</h1>
              </div>
            {/* </button> */}
          </h2>
          <div
            id="participant-collapseThree"
            className="accordion-collapse collapse accordion-detail px-4 show"
            data-bs-parent="#accordionFlushExample"
          >
            <form className="row g-3 pb-3">
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
               Discount Type
                </label>
                  <InputTextBox
                    id="discountType"
                    name="discountType"
                    type="text"
                    value={discount.discountType}
                    disabled
                  />
                </div> 
                <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
               Discount Name
                </label>
                  <InputTextBox
                    id="discountName"
                    name="discountName"
                    type="text"
                    value={discount.discountName}
                    disabled
                  />
                </div> 
                <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
               Discount %
                </label>
                  <InputTextBox
                    id="discountType"
                    name="discountType"
                    type="text"
                    value={discount.discountPercent}
                    disabled
                  />
                </div>     
                <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
               Discount Amount
                </label>
                  <InputTextBox
                    id="discountAmount"
                    name="discountAmount"
                    type="text"
                    value={discount.discountAmount}
                    disabled
                  />
                </div>  
                <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Number of Discount

                </label>
                  <InputTextBox
                    id="numOfDiscount"
                    name="numOfDiscount"
                    type="text"
                    value={discount.numOfDiscount}
                    disabled
                  />
                </div> 
                <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Discount Start Date
                </label>
                  <InputTextBox
                    id="discountStartDate"
                    name="discountStartDate"
                    type="text"
                    value={convertDateTimeToISTForUI(discount.discountStartDate)}
                    disabled
                  />
                </div> 
                <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                Discount End Date
                </label>
                  <InputTextBox
                    id="discountEndDate"
                    name="discountEndDate"
                    type="text"
                    value={convertDateTimeToISTForUI(discount.discountEndDate)}
                    disabled
                  />
                </div>         
              </form>
            </div>
          </div>
           ))}
        </div>       
      </div>       
    </>
    );
};

export default DiscountDetails;
