//add to cart
export const FETCH_LOGIN_REQUEST = "FETCH_LOGINS_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGINS_SUCCESS";
export const ADD_LOGIN_REQUEST = "ADD_LOGIN_REQUEST";
export const ADD_LOGIN_SUCCESS = "ADD_LOGIN_SUCCESS";
export const DELETE_LOGIN_REQUEST = "DELETE_LOGIN_REQUEST";
export const DELETE_LOGIN_SUCCESS = "DELETE_LOGIN_SUCCESS";

export const fetchLoginRequest = () => ({
  type: FETCH_LOGIN_REQUEST,
});
export const fetchLoginSuccess = (login) => ({
  type: FETCH_LOGIN_SUCCESS,
  payload: login,
});
export const addToLoginRequest = (login) => ({
  type: ADD_LOGIN_REQUEST,
  payload: login,
});
export const addToLoginSuccess = (login) => ({
  type: ADD_LOGIN_SUCCESS,
  payload: login,
});
export const deleteFromLoginRequest = (login) => ({
  type: DELETE_LOGIN_REQUEST,
  payload: login,
});
export const deleteFromLoginSuccess = (login) => ({
  type: DELETE_LOGIN_SUCCESS,
  payload: login,
});
