import { useState, useEffect } from "react";

const useFetch = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [url]);

  return [data];
};

export async function useFetchPostRequest(url, reqBody) {
  try {
    console.log("urlIs", url);
    console.log("reqBodyIs", reqBody);
    const request = await fetch(url, {
      method: "POST",
      body: reqBody,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
      },
    });

    console.log("useFetchPostRequest Request body :-", request);
    console.log("useFetchPostRequest Request url :-", url);
    const response = await request.json();
    console.log("Response :- ", response);
    return response;
  } catch (error) {
    console.log("API Failure :- ", error);
  }
}

export async function useFetchPostRequestWithToken(url, reqBody, token) {
  try {
    const request = await fetch(url, {
      method: "POST",
      body: reqBody,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    console.log("useFetchPostRequestWithToken Request body :-", request);
    console.log("useFetchPostRequestWithToken Request url :-", url);

    const response = await request.json();
    console.log("Response :- ", response);
    return response;
  } catch (error) {
    console.log("API Failure :- ", error);
  }
}

export async function useFetchGetRequest(url) {
  try {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "*",
      },
    };

    console.log("useFetchGetRequest Request body :-", requestOptions);
    console.log("useFetchGetRequest Request url :-", url);
    let response = await fetch(url, requestOptions);

    let responseData = await response.json();
    console.log("useFetchGetRequest Response :- ", responseData);
    return responseData;
  } catch (error) {
    console.log("API Failure useFetchGetRequest :-", error);
  }
}

export async function useFetchGetRequestWithToken(url, token) {
  try {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${token}`,
      },
    };

    console.log("useFetchGetRequestWithToken Request body :-", requestOptions);
    console.log("useFetchGetRequestWithToken Request url :-", url);

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("useFetchGetRequestWithToken Response :- ", responseData);
    return responseData;
  } catch (error) {
    console.log("API Failure useFetchGetRequest :-", error);
  }
}

export async function useFetchMultipartWithToken(url, reqBody, token) {
  try {
    const request = await fetch(url, {
      method: "POST",
      body: reqBody,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Credentials": true,
      },
    });

    console.log("useFetchMultipartWithToken Request body :-", request);
    console.log("useFetchMultipartWithToken Request url :-", url);

    const response = await request.json();
    console.log("Response :- ", response);
    return response;
  } catch (error) {
    console.log("API Failure :- ", error);
  }
}

export async function useFetchMultipart(url, reqBody) {
  try {
    const request = await fetch(url, {
      method: "POST",
      body: reqBody,
      headers: {
        // "Content-Type": "multipart/form Data",
        "Access-Control-Allow-Credentials": true,
      },
    });

    console.log("useFetchMultipartWithToken Request body :-", request);
    console.log("useFetchMultipartWithToken Request url :-", url);

    const response = await request.json();
    console.log("Response :- ", response);
    return response;
  } catch (error) {
    console.log("API Failure :- ", error);
  }
}

export async function downloadFile(filePath, fileName) {
  const request = await fetch(filePath, {
    method: "GET",
    headers: {
      "Content-Type": "application/jpg",
    },
  });

  const blob = await request.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export function consoleLogFormData(formData) {
  for (var pair of formData?.entries()) {
    console.log("formData-" + pair[0] + "=" + pair[1]);
  }
}
export default useFetch;
