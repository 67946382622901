import {
  useFetchGetRequestWithToken,
  useFetchPostRequestWithToken,
} from "./api";

export const GetAllOrganizerName = async (token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-organizers`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetAllEventOfOrganizer = async (userId, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-published-events/${userId}`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const SetupDefaultMOATConfig = async (inputValues, token) => { 
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/create-default-moat`;

  const reqBody = JSON.stringify({
    configType: "Default",
    // gstOrgPercent: parseFloat(18),
    gstPgPercent: parseFloat(inputValues.gstPgPercent),
    gstHostPercent: parseFloat(inputValues.gstHostPercent),
    pgFeesPercent: parseFloat(inputValues.pgFeesPercent),
    hostCommission: parseFloat(inputValues.hostCommission),
    platformFeesType:inputValues.platformFeesType,
    platformFees: parseFloat(inputValues.platformFees),
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const SetupMOATConfigOfEvent = async (inputValues, token) => {  
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/create-custom-moat`;
  const reqBody = JSON.stringify({
    configType: "Custom",
    eventId: inputValues.eventId,
    userId: inputValues.userId,
    // gstOrgPercent: parseFloat(18),
    // gstPgPercent: parseFloat(inputValues.gstPgPercent),
    // pgFeesPercent: parseFloat(inputValues.pgFeesPercent),
    gstHostPercent: parseFloat(inputValues.gstHostPercent),
    hostCommission: parseFloat(inputValues.hostCommission),
    platformFeesType: inputValues.platformFeesType,
    platformFees: parseFloat(inputValues.platformFees),
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const GetCustomMOATConfig = async (eventId, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-moat-config?configType=Custom&eventId=${eventId}`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const GetDefaultMOATConfig = async (token) => {  
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-moat-config?configType=Default`;
  return useFetchGetRequestWithToken(apiURL, token);
};
