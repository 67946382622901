import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import InputTextArea from "../controls/input/InputTextArea";
import InputTextBox from "../controls/input/InputTextBox";
import {
  SendAnnouncement,
  SendAnnouncementIndividual,
  SendNewsLetter,
} from "../../service/annoucementAPI";
import * as validator from "../../util/validations";
import TinyEditor from "../controls/editor/TinyEditor";
import Button from "../controls/buttons/Button";
import FileUploadSingle from "../controls/fileUploder/FileUploadSingle";
import Spinner from "../controls/spinner/Spinner";

let initialData = {
  audienceName: "",
  subject: "",
  emailBody: "",
  newsletter: "",
  individualEmails: "",
  errors: {},
  isValid: {},
};

const AnnouncementModel = () => {
  // declare start
  const navigate = useNavigate(); // add useNavigate
  //validition and input value
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid } = inputValue;
  const userData = useSelector((state) => state.login.login.login);
  const [loading, setLoading] = useState(false); // Spinner state

  // declare end

  // functions/events/methods
  // Email validation function
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const makeAnnouncement = async () => {
    if (
      !inputValue.audienceName ||
      !inputValue.subject ||
      !inputValue.isValid?.emailBody
    ) {
      alert("Please enter valid input");
      return;
    }

    if (inputValue?.audienceName === "individual") {
      
      if (inputValue?.individualEmails?.length === 0) {
        alert("Please enter atleast one email");
        return;
      }

      let emailSeparatedByComma = inputValue.individualEmails.trim().split(",");

      // Validate each email
      let invalidEmails = [];
      emailSeparatedByComma = emailSeparatedByComma.map((email) =>
        email.trim()
      );

      emailSeparatedByComma.forEach((email) => {
        if (!validateEmail(email)) {
          invalidEmails.push(email);
        }
      });

      if (invalidEmails.length > 0) {
        alert(`Invalid email(s) found: ${invalidEmails.join(", ")}`);
        return;
      }

      inputValue.individualEmails = emailSeparatedByComma;
      setLoading(true);

      const individualResponse = await SendAnnouncementIndividual(
        inputValue,
        userData?.token
      );
      setLoading(false);

      if (individualResponse?.success) {
        alert(individualResponse?.message);
        navigate(-1);
        resetFields();
      } else if (individualResponse?.error) {
        alert(individualResponse?.message);
      }
    } else if (inputValue.audienceName === "subcriber") {
      setLoading(true);
      const newsLetterResponse = await SendNewsLetter(
        inputValue,
        userData?.token
      );
      setLoading(false);
      if (newsLetterResponse?.success) {
        alert(newsLetterResponse?.message);
        navigate(-1);
        resetFields();
      } else if (newsLetterResponse?.error) {
        alert(newsLetterResponse?.message);
      }
    } else {
      setLoading(true);
      const response = await SendAnnouncement(inputValue, userData?.token);
      setLoading(false);
      if (response?.success) {
        alert(response?.message);
        navigate(-1);
        resetFields();
      } else if (response?.error) {
        alert(response?.message);
      }
    }
  };

  //checking validation
  const handleChange = (event) => {
    const { name, value } = event.target;
    //validation
    let result = IsValid(name, value);
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };

  const IsValid = (name, value) => {
    let status = false;
    let val = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "subject":
          status = validator.IsEmpty(value);
          if (!status) {
            msg = "Invalid " + value;
          }
        case "emailBody":
          status = validator.IsEmpty(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
        // case "individualEmails":
        //   status = validator.IsValidEmail(value);
        //   if (!status) {
        //     val = validator.IsValidEmail(value);
        //     if (!val) msg = "Please enter valid email. " + value;
        //   }
        //   break;
      }
    }

    return { status: status, message: msg };
  };

  const handleInputChange = (name, data) => {
    switch (name) {
      case "emailBody":
        setInputValue((prev) => ({
          ...prev,
          emailBody: data,
          ["isValid"]: { ...prev.isValid, emailBody: true },
        }));
        break;

      default:
        setInputValue((prev) => ({
          ...prev,
          [name]: data,
        }));
        break;
    }
  };

  const resetFields = () => {
    setInputValue({
      ...inputValue,
      audienceName: "",
      subject: "",
      emailBody: "",
      individualEmails: "",
      errors: {},
      isValid: {},
    });
  };

  const handleNewsLetterFile = (file) => {
    inputValue.newsletter = file;
  };

  //main return
  return (
    <>
      <Link
        to="#"
        onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
        style={{ textDecoration: "none", color: "black" }}
      >
        <h4 className=" font-weight-semibold mx-2 my-3">
          <i className="bi bi-chevron-left me-2 fw-bold" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 -960 960 960"
            width="20"
          >
            <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
          </svg>
          Send Announcement
        </h4>
      </Link>
      <div className="m-2 modal-body mt-5">
        <label htmlFor="exampleFormControlInput1" className="form-label">
          Select Recipient<span className="text-danger">*</span>
        </label>
        <select
          className="form-select"
          aria-label="Default select example"
          value={inputValue.audienceName}
          onChange={(e) =>
            setInputValue((prev) => ({
              ...prev,
              audienceName: e.target.value,
            }))
          }
        >
          <option value="">Send Announcement to</option>
          <option value="all">All</option>
          <option value="participants">Participants</option>
          <option value="organizers">Organizers</option>
          <option value="subcriber">Subscribers</option>
          <option value="individual">Send Individual </option>
        </select>

        {inputValue.audienceName === "individual" && (
          <div className="mb-3 mt-5">
            <label htmlFor="individualEmails" className="form-label">
              Individual Emails (Comma Separated)
              <span className="text-danger">*</span>
            </label>
            <InputTextBox
              id="individualEmails"
              name="individualEmails"
              placeholder="Enter individual email addresses"
              value={inputValue?.individualEmails}
              // isValid={inputValue?.isValid?.individualEmails}
              // errorMsg={inputValue?.errors?.individualEmails}
              onChange={handleChange}
            />
          </div>
        )}

        <div className="mb-3 mt-5">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Subject<span className="text-danger">*</span>
          </label>
          <InputTextBox
            id="subject"
            name="subject"
            placeholder="Subject for an email"
            value={inputValue?.subject}
            errorMsg={inputValue?.subject}
            onChange={handleChange}
          />
        </div>
        {/* <div className="d-flex align-items-center justify-content-between justify-content-between anno mb-3">
              <div>
                Announcement Description <span className="text-danger">*</span>
              </div>
              <div>
                {wordCount} / 2000 <img src="images/info-icon.png" alt="" />
              </div>
            </div> */}

        <div className="my-5">
          <div className="form-floating">
            <div>
              Email Body
              <span className="text-danger">*</span>
            </div>
            <TinyEditor
              scriptLoading={{ async: true }}
              name="emailBody"
              initialValue={inputValue.emailBody}
              value={inputValue.emailBody}
              handleInput={(data) => handleInputChange("emailBody", data)}
              className="text-gray-400 text-sm whitespace-nowrap ml-3 max-md:ml-2.5"
              titleEditor={" "}
              isMandatory={true}
            />
          </div>
        </div>

        {inputValue.audienceName === "subcriber" && (
          <div className="my-1 mt-5 mb-4">
            <div className="form-floating">
              <FileUploadSingle
                getFileDetail={handleNewsLetterFile}
                title={"Upload Newsletter File"}
              >
                fileData
              </FileUploadSingle>
            </div>
          </div>
        )}
        <div class="mb-3 text-center w-100">
          <Button onClick={makeAnnouncement} style={{ padding: "10px" }}>
            Send Announcement
          </Button>
        </div>

        {/* <InputTextArea
              id="emailBody"
              name="emailBody"
              placeholder="Description"
              isValid={inputValue?.isValid?.emailBody}
              errorMsg={inputValue?.errors?.emailBody}
              onChange={handleChange}
              rows="4"
            /> */}
      </div>

      {loading && ( <Spinner /> )}
    </>
  );
};

export default AnnouncementModel;
