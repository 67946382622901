import React, { useState } from "react";
import InputTextBox from "../../controls/input/InputTextBox";
import Modal from "../../controls/popup/Modal";
import { GetFileExtension } from "../../../util/commonFun";

//main function
const OrganizerBusinessProfile = ({ organizer }) => {
  // for showing Img in modal
  let ImagePath = process.env.REACT_APP_USERS;

  //declare start
  const [showModal, setShowModal] = useState(false);
  const [docPath, setDocPath] = useState(null);
  const [title, setTitle] = useState("");
  //declare end

  //function/event/methods
  const renderDoc = (type, title, imagePath) => {
    if (imagePath) {
      const fileExtension = GetFileExtension(imagePath);
      const fullPath =
        ImagePath + organizer.userId + "/" + type + "/" + imagePath;
      if (
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "jpg" ||
        fileExtension === "gif"
      ) {
        setTitle(title);
        setDocPath(fullPath);
        setShowModal(true);
      } else {
        window.open(fullPath, "_blank");
      }
    }
  };

  //main return
  return (
    <>
      <div className="card-body p-1">
        <div
          className="accordion accordion-flush m-0"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            {/* <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#participant-collapseTwo"
              aria-expanded="false"
              aria-controls="participant-collapseTwo"
            >
              <div className="d-flex justify-content-center align-items-center accordion-detail">
                <h1>Business Profile</h1>
              </div>
            </button>
          </h2> */}
            <div
              id="participant-collapseTwo"
              className="accordion-collapse collapse accordion-detail px-4 show"
              data-bs-parent="#accordionFlushExample"
            >
              <form className="row g-3 pb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Entity Type
                  </label>
                  <InputTextBox
                    id="businessEntityType"
                    name="businessEntityType"
                    type="text"
                    value={organizer.businessEntityType}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business Pan Number
                  </label>
                  <InputTextBox
                    id="businessEntityType"
                    name="businessEntityType"
                    type="text"
                    value={organizer.businessPanNo}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business Pan Card Document
                  </label>
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white border-end-0"
                      id="basic-addon1"
                    >
                      <img
                        src="/images/attachment-icon.svg"
                        alt=""
                        onClick={() =>
                          renderDoc(
                            "businessPanDoc",
                            "Business Pan Card Document",
                            organizer.businessPanDoc
                          )
                        }
                      />
                    </span>
                    <input
                      type="text"
                      className="form-control border-start-0"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={organizer.businessPanDoc}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business Address Proof
                  </label>
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white border-end-0"
                      id="basic-addon1"
                    >
                      <img
                        src="/images/attachment-icon.svg"
                        alt=""
                        onClick={() =>
                          renderDoc(
                            "businessAddressProofDoc",
                            "Business Address Proof",
                            organizer.businessAddressProofDoc
                          )
                        }
                      />
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      value={organizer.businessAddressProofDoc}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Authorised Signatory Name
                  </label>
                  <InputTextBox
                    id="signatoryName"
                    name="signatoryName"
                    type="text"
                    value={organizer.signatoryName}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Authorised Signatory PAN Number
                  </label>
                  <InputTextBox
                    id="signatoryName"
                    name="signatoryName"
                    type="text"
                    value={organizer.signatoryPanNo}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Authorised Signatory PAN Doc
                  </label>
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white border-end-0"
                      id="basic-addon1"
                    >
                      <img
                        src="/images/attachment-icon.svg"
                        alt=""
                        onClick={() =>
                          renderDoc(
                            "signatoryPanDoc",
                            " Authorised Signatory PAN Doc",
                            organizer.signatoryPanDoc
                          )
                        }
                      />
                    </span>
                    <InputTextBox
                      id="signatoryName"
                      name="signatoryName"
                      type="text"
                      value={organizer.signatoryPanDoc}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Authorised Signatory Aadhar Number
                  </label>
                  <InputTextBox
                    id="signatoryName"
                    name="signatoryName"
                    type="text"
                    value={organizer.signatoryAadharNo}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Authorised Signatory Aadhar Doc
                  </label>
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white border-end-0"
                      id="basic-addon1"
                    >
                      <img
                        src="/images/attachment-icon.svg"
                        alt=""
                        onClick={() =>
                          renderDoc(
                            "signatoryAadharDoc",
                            "Authorised Signatory Aadhar Doc",
                            organizer.signatoryAadharDoc
                          )
                        }
                      />
                    </span>
                    <InputTextBox
                      id="signatoryAadharDoc"
                      name="signatoryAadharDoc"
                      type="text"
                      value={organizer.signatoryAadharDoc}
                      disabled
                    />
                  </div>
                </div>

                {organizer.isTicketInvolveGst ? (
                  <>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="inputEmail4" className="form-label">
                        GST Number
                      </label>
                      <InputTextBox
                        id="businessGstNo"
                        name="businessGstNo"
                        type="text"
                        value={organizer.businessGstNo}
                        disabled
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="inputEmail4" className="form-label">
                        GST State Number
                      </label>
                      <InputTextBox
                        id="businessGstStateNo"
                        name="businessGstStateNo"
                        type="text"
                        value={organizer.businessGstStateNo}
                        disabled
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="inputEmail4" className="form-label">
                        HSN Code
                      </label>
                      <InputTextBox
                        id="businessHsnCode"
                        name="businessHsnCode"
                        type="text"
                        value={organizer.businessHsnCode}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="inputEmail4" className="form-label">
                        GST Percentage
                      </label>
                      <InputTextBox
                        id="businessGstPercent"
                        name="businessGstPercent"
                        type="text"
                        value={organizer.businessGstPercent}
                        disabled
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="inputEmail4" className="form-label">
                        GST Doc
                      </label>
                      <div className="input-group">
                        <span
                          className="input-group-text bg-white border-end-0"
                          id="basic-addon1"
                        >
                          <img
                            src="/images/attachment-icon.svg"
                            alt=""
                            onClick={() =>
                              renderDoc(
                                "businessGstDoc",
                                " GST Doc",
                                organizer.businessGstDoc
                              )
                            }
                          />
                        </span>
                        <InputTextBox
                          id="businessGstDoc"
                          name="businessGstDoc"
                          type="text"
                          value={organizer.businessGstDoc}
                          disabled
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>No</>
                )}
              </form>
              {/* <div className="my-4 text-center">
              <button type="submit" className="btn btn-select w-100 mb-2">
                Save &amp; Proceed
              </button>
            </div> */}
            </div>
          </div>
        </div>
        {showModal && (
          <Modal onClose={() => setShowModal(false)} title={title}>
            <div>
              <img src={docPath} className="d-block w-100" alt=""></img>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default OrganizerBusinessProfile;
