import React from "react";
import myloader2 from "./myloader2.svg"; // Adjust the path as needed

// CSS for the spinner
// const spinnerStyle = {
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   height: "75vh",
// };

const Spinner = () => (
  // <div style={spinnerStyle}>      
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex={-1}
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border-0" style={{ background: "transparent", boxShadow: "none", color:"white" }}>
              <div className="modal-body d-flex justify-content-center align-items-center">
              <img src={myloader2} alt="Loading..." />
              Getting things ready...
              </div>
            </div>
          </div>
        </div>
    
    
  // </div>
);
export default Spinner;
