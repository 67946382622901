import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CreateCategory } from "../../../service/categoryAPI";
import * as validator from "../../../util/validations";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import Modal from "../../controls/popup/Modal";
import FileUploadSingle from "../../controls/fileUploder/FileUploadSingle";

//main function
const AddNewCategory = ({ onCategoryCreated }) => {
  //declare start
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [uploadImageUrl, setUploadImageUrl] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const userData = useSelector((state) => state.login.login.login);

  //validition and input value
  let initialData = { categoryName: "", errors: {}, isValid: {} };
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid } = inputValue;
  //declare end

  //function/events/methods
  const submitCategoryForm = async (event) => {
    event.preventDefault();
    if (
      (inputValue.isValid?.categoryName && !inputValue.categoryName) ||
      !uploadImageUrl
    ) {
      setResponseMsg("Please Enter required fields !!");
      removeErrorMsg();
      return;
    }
    try {
      if (userData?.token) {
        let createCategoryResponse = await CreateCategory(
          inputValue,
          uploadImageUrl,
          userData?.token
        );

        if (inputValue?.categoryName === inputValue?.categoryName)
          setResponseMsg(createCategoryResponse.error);
        removeErrorMsg();

        if (createCategoryResponse?.success) {
          onCategoryCreated();
          setShowModal(false);
          // Reset the form fields
          resetCategoryFields();
          alert(createCategoryResponse?.message);
        } else if (createCategoryResponse?.error) {
          resetCategoryFields();
          setResponseMsg(createCategoryResponse?.error);
          removeErrorMsg();
        } else {
          setResponseMsg(createCategoryResponse?.message);
          removeErrorMsg();
        }
      }
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  const resetCategoryFields = () => {
    setInputValue({ ...inputValue, categoryName: "", errors: {}, isValid: {} });
    setUploadImageUrl("");
  };

  const removeErrorMsg = () => {
    setTimeout(() => {
      setResponseMsg("");
    }, 3000);
  };

  //checking validation
  const handleChange = (event) => {
    const { name, value } = event.target;
    //validation
    let result = IsValid(name, value);
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };

  const IsValid = (name, value) => {
    let status = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "categoryName":
          status = validator.IsValidCharWithSpace(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
        default:
      }
    }

    return { status: status, message: msg };
  };

  const handleSelectImage = (event) => {
     event.preventDefault();
    const file = event.target.files[0];
    const validImageTypes = ["image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",];
    
    if (!validImageTypes.includes(file?.type)) {
      setResponseMsg("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      removeErrorMsg();
      resetCategoryFields();
      return;
    }

    if (file.size > 1048576) {   // 1 MB = 1,024 × 1,024 B = 1,048,576 B
      setResponseMsg("File size exceeds 1 MB. Please choose a smaller file.");
      removeErrorMsg();
      resetCategoryFields();
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setUploadImageUrl(file);
    setImageUrl(imageUrl);
  
  };

   //for FileUploadSingle
  // const handleFileDetail = (file) => {
  //   const imageUrl = URL.createObjectURL(file);
  //   setUploadImageUrl(file);
  //   setImageUrl(imageUrl);
  // };

  // if(onCategoryUpdate){
  //   setShowModal(() => true);
  // }
  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  //main return
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          dark
          onClick={handleClick}
          style={{
            marginTop: "-25px",
          }}
        >
          Add New Category
        </Button>
      </div>
      {/* <div className="manage-category-modal">
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add Category
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
      {showModal && (
        <Modal
          title="Add Category"
          onClose={handleClose}
          onSuccess={submitCategoryForm}
          cancleText="Cancel"
          successText="Submit"
        >
          {responseMsg && (
            <div className="alert alert-danger" role="alert">
              <h6>{responseMsg}</h6>
            </div>
          )}
          <div className="modal-body manage-category-modal">
            <form action method="post">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Category Name
                </label>
                <InputTextBox
                  id="categoryName"
                  name="categoryName"
                  placeholder="Please Enter Category Name"
                  isValid={inputValue?.isValid?.categoryName}
                  errorMsg={inputValue?.errors?.categoryName}
                  onChange={(event) => handleChange(event)}
                />
                {/* <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={inputValue.categoryName}
                      name="categoryName"
                      onChange={(event) => handleChange(event)}
                      // required
                    /> */}
                {/* <div
                      id="emailHelp"
                      className="form-text danger text-danger"
                    >
                      {inputValue.errors?.categoryName}
                    </div> */}
              </div>
            </form>
            <div className="form-group file-area">
              <div className="my-2 message">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Upload Category Image (size: 207px X 73px)
                </label>
              </div>
              <input
                type="file"
                name="images"
                id="images"
                required="required"
                multiple="multiple"
                onChange={(event) => handleSelectImage(event)}
              />
                {/* <FileUploadSingle
                getFileDetail={handleSelectImage}
                title={""}
              >
                fileData
              </FileUploadSingle> */}
              <div className="upload-event-photo" style={{ marginTop: "10px" }}>
                <div className="success">
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Uploaded"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  )}
                </div>
                <div className="default">
                  <p>
                    <i className="bi bi-image"></i>
                  </p>
                  Upload Image
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* <div className="modal-footer">
                <Button
                cancel
                  // type="button"
                  // className="btn btn-cancel"
                  // data-bs-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  // type="button"
                  // className="btn btn-upload"
                  // data-bs-dismiss={modalDismiss}
                  data-bs-dismiss="modal"
                  onClick={submitCategoryForm}    
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AddNewCategory;


//for FileUploadSingle
{/* <div className="form-group file-area">
<FileUploadSingle
  getFileDetail={handleFileDetail}
  title="Upload Category Image (size: 207px X 73px)"
  fileType="image"
/>
{imageUrl && (
  <div className="upload-event-photo" style={{ marginTop: "10px" }}>
    <img
      src={imageUrl}
      alt="Uploaded"
      style={{ maxWidth: "100%", maxHeight: "150px" }}
    />
  </div> */}
