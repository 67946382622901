import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import OrganizerBasicDetails from "./OrganizerBasicDetails.jsx";
import OrganizerBusinessProfile from "./OrganizerBusinessProfile.jsx";
import OrganizerBankAccountDetails from "./OrganizerBankAccountDetails.jsx.js";
import { GetOrganizerDetail } from "../../../service/dashboardAPI.js";
import { FOLDER_ORGANIZER_LOGO } from "../../../util/Constants.jsx";
import AccordionCard from "../../controls/accordion/AccordionCard.jsx";
import Spinner from "../../controls/spinner/Spinner.jsx";

const items = [{ title: "Dashboard", url: "/dashboard", icon: "backArrow" }];

//main function
const OrganizerDetails = () => {
  //declare start
  const { orgId, userId } = useParams();
  const navigate = useNavigate(); // add useNavigate

  const [organizerData, setOrganizerData] = useState("");
  let ImagePath = process.env.REACT_APP_USERS;
  const [docPath, setDocPath] = useState(null);
  const userData = useSelector((state) => state.login.login.login);
  const [loading, setLoading] = useState(true); // loading spinner
  //declare end

  //function/event/methods
  const renderDoc = (type, title, imagePath) => {
    if (imagePath) {
      setDocPath(
        ImagePath + organizerData.userId + "/" + type + "/" + imagePath
      );
    }
  };

  useEffect(() => {
    const fetchOrganizerData = async () => {
      if (userData.token) {
        setLoading(true);
        const response = await GetOrganizerDetail(userId, userData.token);
        setLoading(false);
        if (response && response?.success) {
          setOrganizerData(response.data);
        } else {
          console.error("Failed to fetch organizer data");
        }
      }
    };

    fetchOrganizerData();
  }, [userId]); // Re-fetch organizer data when orgId changes

  const [activeTabNo, setActiveTabNo] = useState(-1);

  //accordion
  const handleSave = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };
  const handleSkip = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };

  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Basic Profile</h1>
          </div>
        ),

        content: <OrganizerBasicDetails organizer={organizerData} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Business Profile</h1>
          </div>
        ),

        content: <OrganizerBusinessProfile organizer={organizerData} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Bank Account Details</h1>
          </div>
        ),

        content: <OrganizerBankAccountDetails organizer={organizerData} />,
      },
    ],
  };

  const renderItems = items.map((item, index) => {
    return (
      <>
        <div className="col-4">
          <Link
            to="#"
            onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
            // onClick={() => handleClick(index)}
            key={index}
            style={{ textDecoration: "none", color: "black" }}
          >
            <h3 className="my-3 ml-3 d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 -960 960 960"
                width="20"
              >
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
              </svg>
              <span className="font-weight-semibold">
                {organizerData.businessName}
              </span>
              {/* <Icon iconName={item.icon} />  */}
            </h3>
          </Link>
        </div>
      </>
    );
  });

  // main return
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
      <div className="">{renderItems}</div>

      <div className="col-12 main-contian org-kyc">
        {/* Page Name */}
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="my-3">
            <i className="bi bi-chevron-left fw-bold" />
            {/* {organizerData.businessName} {orgId} */}
          </h3>
          <div className="col-sm-12 col-md-8 col-xl-12 org-main-contain my-3 card mb-2">
            <div className="card p-0 rounded">
              <div className="card-body p-0 rounded">
                <div className="my-profile-banner rounded">
                  <div className="d-flex">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src={
                          ImagePath +
                          organizerData.userId +
                          FOLDER_ORGANIZER_LOGO +
                          organizerData.businessLogo
                        }
                        width={100}
                        height={100}
                        alt="Logo"
                      />
                    </div>
                    <div className="p-2 flex-grow-1 mt-3">
                      <h1>{organizerData.businessName}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AccordionCard config={config} />
      </div>
      </>
      )}
    </>
  );
};

export default OrganizerDetails;
