import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardCard from "../../components/dashboard/DashboardCard";
import { IsEmpty } from "../../util/validations";
import * as Common from "../../util/commonFun";
import { LOCAL_STORAGE_USER } from "../../util/Constants";
import { GetAllCount } from "../../service/dashboardAPI";
import TotalOrganizer from "../../components/dashboard/TotalOrganizer";
import EventTable from "../../components/dashboard/eventTable/EventTable";
import Spinner from "../../components/controls/spinner/Spinner";
import TotalEvents from "../../components/dashboard/TotalEvents";

//main function
const DashboardPage = () => {
  //declare start
  const navigate = useNavigate();
  const [counts, setCounts] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState("Total Events");
  const [loading, setLoading] = useState(true); // loading spinner
  const userData = useSelector((state) => state.login.login.login);
  //declare end

  //function/events/methods
  useEffect(() => {
    if (IsEmpty(Common.getLocalData(LOCAL_STORAGE_USER))) {
      navigate("/login");
    } else {
      fetchCounts();
    }
  }, [userData]);

  const fetchCounts = async () => {
    try {
      if (userData.token) {
        const response = await GetAllCount(userData.token);
        if (response && response?.success) {
          setCounts(response?.data);
          setLoading(false);
        } else {
          // Handle error
        }
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching counts:", error);
      setLoading(false);
    }
  };

  const handleCardClick = (title) => {
    setSelectedTitle(title);
  };

  let loadDashboardCard = [
    { title: "Total Events", count: counts?.totalEvents || "0", icon: "1" },
    {
      title: "Total Organizers",
      count: counts?.totalOrganizers || "0",
      icon: "3",
    },
    {
      title: "Total Participants",
      count: counts?.totalRegisteredParticipants || "0",
      icon: "2",
    },
    // {
    //   title: "Total Past Events",
    //   count: counts?.totalPastEvents || "0",
    //   icon: "6",
    // },
    { title: "Total Revenue", count: counts?.totalRevenue || "0", icon: "5" },
  ];

  //main return
  return (
    <>
      <div className="col-12 main-contain">
        <div className="row mb-3 my-3">
          <div className="col ml-3 ">
            <h3 className="font-weight-semibold">Dashboard</h3>
          </div>
          {/* <div className="col">
            <div className="input-group year-select float-end">
              <select
                className="form-select"
                id="inputGroupSelect01"
                defaultValue="Year"
              >
                <option value="Year">Year</option>
                <option value="1">2023</option>
                <option value="2">2024</option>
                <option value="3">2025</option>
              </select>
            </div>
          </div> */}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex justify-content-between">
              {loadDashboardCard &&
                loadDashboardCard.map((item, i) => {
                  return (
                    <DashboardCard
                      key={i}
                      card={item}
                      onCardClick={handleCardClick}
                      isSelected={selectedTitle === item.title}
                    />
                  );
                })}
            </div>

            {selectedTitle === "Total Events" && <TotalEvents />}
            {selectedTitle === "Total Organizers" && <TotalOrganizer />}
            {/* {selectedTitle === "Total Events" && <TotalEvents />} */}
            {/* {selectedTitle === "Total Participants" && (
              <TotalRegisteredParticipants />
            )} */}
            {/* {selectedTitle === "Total Past Events" && <TotalPastEvents />} */}
            {selectedTitle === "Total Revenue" && <EventTable />}
          </>
        )}
      </div>
    </>
  );
};

export default DashboardPage;
