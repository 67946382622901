import React from 'react';

//main function
const NoPage = ()=>{
//main return
    return (
        <>
        <div>404 Page Not Found</div>
        </>
    );
};
export default NoPage;