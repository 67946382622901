import {useFetchGetRequest} from "./api";

export const GetAllEventData =async (reqBody) => {
    const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-events`;
   return useFetchGetRequest(apiURL,reqBody);
  };

  export const GetAllOrganizerData =async (reqBody) => {
    const apiURL = `${process.env.REACT_APP_USER_MS}admin/get-all-organizers`;
   return useFetchGetRequest(apiURL,reqBody);
  };