import { React, useState } from "react";
import InputTextBox from "../../components/controls/input/InputTextBox";
import * as Call from "../../util/validations";
import { SendPasswordResetLink } from "../../service/firebaseLogin";
import { SUCCESS } from "../../util/Constants";

const initialData = {
  recoveryEmail: "",
  errors: {},
  IsValid: {},
  isRequired: {
    recoveryEmail: true,
  },
};

const ForgotPassword = () => {
  const [inputValue, setInputValue] = useState(initialData);
  let { errors, IsValid } = inputValue;
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;
    //check validation
    let result = isValid(name, value, e);
    if (result.name2 !== "") {
      setInputValue((prev) => ({
        ...prev,
        [result.name]: result.value === "" ? "" : result.value,
        [result.name2]: result.value2 === "" ? "" : result.value2,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    } else {
      //setInputValue
      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    let name2 = "";
    let value2 = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter valid " + name;
    } else {
      switch (name) {
        case "recoveryEmail":
          {
            if (Call.IsEmpty(value)) {
              msg = "Please enter email.";
            } else {
              val = Call.IsValidEmail(value);
              if (!val) msg = "Please enter valid email. " + value;
            }
          }
          break;

        default:
          break;
      }
    }

    return {
      status: val,
      message: msg,
      name: name,
      value: value,
      name2: name2,
      value2: value2,
    };
  };

  const sendResetLink = async (e) => {
    e.preventDefault();
    const result = await SendPasswordResetLink(inputValue.recoveryEmail);
    if (result == SUCCESS) {
      alert("Reset password link has been shared to your email.");
    } else {
      alert(result?.message);
    }
  };

  return (
    <div class="row justify-content-center align-items-center">
      <div class="col p-0">
        <div class="login-hero">
          <div class="title-line">
            {/* <div class="logo">Forgot Password</div> */}
            {/* <p class="mb-5">Lorem Ipsum is simply dummy <span class="pink">text</span> of the <span class="yellow">printing</span> and typesetting industry.</p> */}
            {/* <p>Lorem <span class="cyan">Ipsum</span> has been the industry's standard</p> */}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="login-form">
            <h1 class="text-center">Forgot your password?</h1>
            <p class="text-center">
              We will send a link on your registration email to reset your
              password.
            </p>
            <form class="row g-3 mt-5">
              <div class="col-12">
                <label for="validationServer01" class="form-label">
                  Email ID
                </label>
                <InputTextBox
                  id="recoveryEmail"
                  name="recoveryEmail"
                  placeholder="Email"
                  type="text"
                  isValid={inputValue?.IsValid?.recoveryEmail}
                  errorMsg={inputValue?.errors?.recoveryEmail}
                  value={inputValue.recoveryEmail}
                  onChange={handleChange}
                />
                {/* <div class="valid-feedback">Looks good!</div> */}
              </div>
              <div class="col-12">
                <button
                  class="btn btn-primary login-btn w-100"
                  type="submit"
                  onClick={sendResetLink}
                >
                  Request a Reset Link
                </button>

                {error ? (
                  <p>Password reset email sent. Please check your inbox.</p>
                ) : (
                  <></>
                )}
                {/* {error && <p>{error}</p>} */}
                <div class="d-flex justify-content-center my-3 login-social">
                  <div class="p-2">
                    <a href="#">
                      <i class="bi bi-facebook"></i>
                    </a>
                  </div>
                  <div class="p-2">
                    <a href="#">
                      <i class="bi bi-twitter-x"></i>
                    </a>
                  </div>
                  <div class="p-2">
                    <a href="#">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </div>
                  <div class="p-2">
                    <a href="#">
                      <i class="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="copy-right w-50">
          Copyright © 2010-2024 company All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
