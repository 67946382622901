import React from "react";
import { useParams } from "react-router";
import { Link, useNavigate} from "react-router-dom";
import AccordionCard from "../../controls/accordion/AccordionCard";
// import Table from "../../controls/table/Table";
// import Button from "../../controls/buttons/Button";
// import EventBasicDetails from "./EventBasicDetails";
// import ParticipantDetails from "./ParticipantDetails";
// import DiscountDetails from "./DiscountDetails";
// import TicketDetails from "./TicketDetails";

const ViewSaleDetails = () => {

  const navigate = useNavigate(); // add useNavigate

  //configration
  const config = {
    activeTabNo: 0,
    data: [
      {
        title: "Event Details",
        // content: <EventBasicDetails />,
      },
      {
        title: "Ticket Details",
        // content: <TicketDetails />,
      },
      {
        title: "Discount Details",
        // content: <DiscountDetails />,
      },
      {
        title: "Participant Details",
        // content: <ParticipantDetails />,
      },
    ],
  };

  //main return
  return (
    <>
      <div>
        <Link
          to="#"
          style={{ textDecoration: "none", color: "black" }}
          onClick={() => navigate(-1)} // navigate back which will navigate to the previous page in the browser history
        >
          <h3 className="font-weight-semibold mt-4 mb-4 ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 -960 960 960"
              width="20"
            >
              <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"></path>
            </svg>
            Event Name
          </h3>
        </Link>{" "}
      </div>
      <AccordionCard config={config} />
    </>
  );
};

//

export default ViewSaleDetails;
